// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';
import {Tooltip} from '@material-ui/core'

// Assets
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import stub_article from '@assets/stub_article.png';
import stub_smi from '@assets/stub_smi.svg';
import arrow_gray from '@assets/arrow_gray.svg';
import OndemandVideo from '@material-ui/icons/OndemandVideo';

// Styles
import styles from './styles.sass';

// Api
import { bookmarkingArticle, removeFromBookmarks } from '../../api/articleApi';
import { useDispatch, useSelector } from 'react-redux';
import Share from './Share';
import { SET_EVENT } from '../../store/event/types';

type NewsCardPropTypes = {
  setAllArticles: any;
  newsId: string;
  isFavoritePage?: boolean;
  preview: string;
  title: string;
  edition: string;
  bigSize: string;
  rowView: string;
  source: {
    name: string;
    logo: string;
    url: string;
    is_source_external: string;
  };
  withVideo: boolean;
  inCategory: boolean;
  childrenCount: number;
  additionalEventsOnClick?: any;
}

const NewsCard = ({
  setAllArticles,
  newsId,
  preview,
  title,
  edition,
  bigSize,
  rowView,
  source,
  withVideo,
  isFavoritePage,
  inCategory,
  additionalEventsOnClick,
  childrenCount = 0,
}: NewsCardPropTypes) => {
  const dispatch = useDispatch();
  const role = useSelector(({ user }) => user.role);
  const CAN_ADD_TO_BOOKMARKS_ROLES = ['user'];

  const canAddToBookmarks = CAN_ADD_TO_BOOKMARKS_ROLES.includes(role);

  const setEvent = payload =>
    dispatch({
      type: SET_EVENT,
      payload
    });

  const changeFavoriteArticles = (is_favorite) => {
    setAllArticles(articles => inCategory
      ? articles.map(article => article.id === newsId ? { ...article, is_favorite } : article)
      : Object.fromEntries(Object.entries(articles).map((data => [
        data[0],
        data[1].map(item => item.id === newsId
          ? {
            ...item,
            is_favorite
          }
          : item
        )
      ])))
    )
  }

  const toBookmarkHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    bookmarkingArticle(
      newsId,
      () => {
        changeFavoriteArticles(true);
        setEvent({ open: true, message: 'Новость добавлена в закладки', status: 'success' });
      },
      () => {
        setEvent({ open: true, message: 'Ошибка при добавлении новости в закладки', status: 'error' });
      }
    )
  };

  const removeHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromBookmarks(
      newsId,
      () => {
        changeFavoriteArticles(false);
        setEvent({ open: true, message: 'Новость удалена из закладок', status: 'success' });
      },
      () => {
        setEvent({ open: true, message: 'Ошибка при удалении из закладок', status: 'error' });
      }
    )
  };

  const Bookmarks = () => childrenCount === 0 && canAddToBookmarks ? (
    <button
      className={styles.Action}
      onClick={
        (e) => isFavoritePage
          ? removeHandler(e)
          : toBookmarkHandler(e)
      }
    >
      {isFavoritePage
        ? <BookmarkIcon style={{fill: '#731982'}} />
        : <BookmarkBorderIcon style={{fill: '#731982'}} />
      }
    </button>
  ) : <></>;

  const Group = () => childrenCount > 0 ? (
    <Link className={styles.ChildrenCount} to={`/articles/group/${newsId}`}>
      <span className={styles.ChildrenCount__Counter}>
        {childrenCount + 1}
      </span>
      <img src={arrow_gray} alt="" />
    </Link>
  ) : <></>;

  const Edition = () => edition ? (
    <>
      {!source.is_source_external ? (
        <div style={{ display: 'flex', alignItems: "center" }}>
          <div className={styles.Icon}>
            <img alt="" src={source.logo || stub_smi} />
          </div>
          <div className={styles.Edition}>
            {source.name}
          </div>
        </div>
      ) : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={source.url}
          style={{
            display: 'flex',
            zIndex: '666',
            alignItems: 'center'
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.Icon}>
            <img alt="" src={source.logo || stub_smi} />
          </div>
          <div className={styles.Edition}>
            {source.name}
          </div>
        </a>
      )}
    </>
  ) : <></>;

  const Panel = () => (
    <div className={styles.Panel}>
      <div className={styles.Row}>
        <Edition />
      </div>
      <div className={styles.Row}>
        <Share
          source={source}
          title={title}
        />
        <Group />
        <Bookmarks />
      </div>
    </div>
  );


  const Content = () => (
    <div className={`
      ${styles.Content} 
      ${rowView && styles.Content_rowView} 
      ${childrenCount > 0 && styles.GroupContent}
    `}
    >
      <div className={styles.Title}>
        {withVideo && (
          <Tooltip title="Материал содержит видео">
            <OndemandVideo style={{ margin: '4px 16px 0 0'}} />
          </Tooltip>
        )}
        {title}
      </div>
      <Panel />
    </div>
  );

  const Card = () => (
    <Link
      to={`/article/${newsId}`}
      onClick={() => {
        window.dataLayer.push({
          "ecommerce": {
            "news_item_click" : {
              id: newsId
            }
          }
        });
      }}
    >
      {bigSize && !rowView
        ? (
          <div
            className={`${styles.Card} ${styles.Card_big} ${childrenCount > 0 && styles.CardGroupContent}`}
            style={{
              background: preview ? `url(${preview})` : `url(${stub_article}), #ebebeb`,
              backgroundSize: preview ? 'cover' : 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '4px'
            }}
          >
            <div className={styles.Shadow} />
            <Content />
          </div>
        )
        : (
          <div className={`${styles.Card} ${rowView && styles.Card_rowView}`}>
            {!rowView && (
              <>
                <div
                  className={styles.Image}
                  style={{
                    backgroundImage: preview ? `url(${preview})` : `url(${stub_article})`,
                    backgroundSize: preview ? 'cover' : 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
              </>
            )}
            <Content />
          </div>
        )
      }
    </Link>
  );

  return (
    <div onClick={() => additionalEventsOnClick ? additionalEventsOnClick() : null}>
      <Card />
    </div>
  )
}

export default NewsCard;
