// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';

// Component
import { Button, Input } from '@material-ui/core';
import Page from '@components/Page';

// Styles
import styles from './styles.sass';

const Remind = () => {
  const [setFormData] = React.useState({
    login: '',
    password: '',
    position: ''
  });
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = () => {
    setSuccess(true);
  };

  return (
    <Page withoutFooter withoutLogin>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          {!success ? (
            <>
              <div className={styles.Title}>Восстановление пароля</div>
              <Input
                type='text'
                className={styles.Input}
                placeholder='Логин или Email'
                onChange={(e) => setFormData(prev => ({ ...prev, login: e.target.value }))}
                fullWidth
              />
              <Button className={styles.Button} onClick={handleSubmit}>Восстановить</Button>
            </>
          ) : (
            <div style={{height: '100%'}}>
              <Link to='/login'>
                <div className={styles.Back} onClick={() => setSuccess(false)}>Назад</div>
              </Link>
              <div className={styles.Successful}>Сообщение на вашу почту успешно отправлено!</div>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
};

export default Remind;
