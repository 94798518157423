// @flow

// Libraries
import React from 'react';
import moment from 'moment';

// Components
import { Button } from '@material-ui/core';
import { DateRangePicker } from '@material-ui/pickers';
import { StyledIcon } from '../../pages/Main/Components';
import ProccesedMenu from '../../pages/Main/Components/ProccesedMenu';
import SearchBlock from '../../pages/Main/Components/SearchBlock';
import FiltersModal from '../../pages/Main/Components/FiltersModal';
import SortDirection from '../../pages/Main/Components/SortDirection';
import FavoritesSort from '../../pages/Main/Components/FavoritesSort';

// Assets
import RefreshIcon from '@material-ui/icons/Refresh';
import filter_icon from '@assets/filter.png';
import { CalendarToday } from '@material-ui/icons';

// Styles
import styles from './styles.sass';
import AddCSVNewList from "../AddCSVNewList";
import UploadPublications from "../UploadPublications";

const EditorManipulator = ({
  filters,
  setFilters,
  refetchArticles,
  selectHandler,
  setEvent
}: any) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const handleChangeFilter = changes =>
    setFilters(prev => ({
      ...prev,
      ...changes
    }));

  const handleChangeFilters = React.useCallback(
    changedFilters => {
      setFilters(prev => ({ ...prev, ...changedFilters }))
    },
    [filters, setFilters]
  );

  return (
    <>
      <div className={styles.SearchBlock}>
        <SearchBlock
          onSubmitHandler={text =>
            handleChangeFilters({
              keyWords: [...filters.keyWords, text]
            })
          }
          renderSearchButton={({ onClick, disabled }) => (
            <Button
              variant="contained"
              color="primary"
              className={styles.SearchButton}
              onClick={onClick}
              disabled={disabled}
            >
              Найти
            </Button>
          )}
        />
      </div>
      <div className={styles.Container}>
        <div className={styles.LeftCol}>
          <StyledIcon onClick={() => setOpenModal(true)}>
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                marginRight: 8
              }}
            >
              <img
                alt="Фильтр"
                src={filter_icon}
                style={{ width: 20, height: 20, marginLeft: 8 }}
              />
            </div>
          </StyledIcon>
          <SortDirection
            sort={filters.sort}
            onChange={({ type, direction }) =>
              handleChangeFilters({
                sort: {
                  type,
                  direction
                }
              })
            }
          />
          <StyledIcon>
            <RefreshIcon onClick={refetchArticles} />
          </StyledIcon>
          <DateRangePicker
            open={showDatePicker}
            onOpen={() => setShowDatePicker(true)}
            onClose={() => setShowDatePicker(false)}
            startText=""
            endText=""
            mask="__.__.____"
            theme="secondary"
            value={[filters.dateFrom, filters.dateTo]}
            onChange={([dateFrom, dateTo]) =>
              handleChangeFilter({
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD')
              })
            }
            maxDate={moment()
              .utc()
              .format('YYYY-MM-DD')}
            disableToolbar
            renderInput={({
              ref,
              inputProps,
              disabled,
              onChange,
              value,
              ...other
            }) => (
              <div ref={ref} {...other}>
                <input
                  style={{ display: 'none' }}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  {...inputProps}
                />
                <StyledIcon>
                  <CalendarToday
                    style={{ width: 20 }}
                    onClick={() => setShowDatePicker(prev => !prev)}
                  />
                </StyledIcon>
              </div>
            )}
            autoOk
          />
          <FavoritesSort
            sort={filters.isFavorite}
            onChange={value =>
              handleChangeFilters({
                isFavorite: value
              })
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              selectHandler();
            }}
          >
            Выделить всё
          </Button>
          <AddCSVNewList setEvent={(event) => setEvent(event)} />
          {/* <UploadPublications /> */}
        </div>
        <ProccesedMenu
          type={filters.processed}
          onChangeTypeHandler={type => handleChangeFilter({ processed: type })}
        />
      </div>
      <FiltersModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
      />
    </>
  );
};

export default EditorManipulator;
