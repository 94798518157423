import _ from "lodash";


export const sortByPriority = (articles, firstElIsBlocked) => {
  const orderBy = arr => _.orderBy(
    arr,
    ["custom_priority", "audition", "monitoring_date"],
    ["asc", "desc", "desc"]
  );

  if (firstElIsBlocked) {
    const dividedArticles = articles.slice(1);
    const sortedArticles = orderBy(dividedArticles);

    return [
      articles[0],
      ...sortedArticles
    ];
  }

  return orderBy(articles);
}