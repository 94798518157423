// @flow

// Libraries
import * as React from 'react';
import 'moment/locale/ru';
import { NavLink } from 'react-router-dom';

// Styles
import styles from './styles.sass';


const MobileMenu = ({ tabs }: any) => {
  return (
    <div className={styles.MobileMenu}>
      {tabs.map(tab => !(['/monitoring/prepared', '/monitoring/released'].includes(tab.to)) ? (
        <NavLink
          className={styles.Link}
          exact={tab.exact || false}
          to={tab.to}
          activeStyle={{
            borderBottom: "2px solid #00A84E",
            color: "#00A84E"
          }}
        >
          {tab.label}
        </NavLink>
      ) : (
        <>
          <NavLink
            className={styles.Link}
            to={`${tab.to}/federal`}
            style={{ color: window.location.pathname.includes(tab.to) && window.location.pathname.includes('federal') ? '#00A84E' : '#000'}}
          >
            {tab.label}
            {' - федеральные'}
          </NavLink>
          <NavLink
            className={styles.Link}
            to={`${tab.to}/regional`}
            style={{ color: window.location.pathname.includes(tab.to) && window.location.pathname.includes('regional') ? '#00A84E' : '#000'}}
          >
            {tab.label}
            {' - региональные'}
          </NavLink>
        </>
      ))}
    </div>
  )
}

export default MobileMenu;
