// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, InputAdornment, TextField } from '@material-ui/core';

// Components
import { Search } from '@material-ui/icons';
import Page from '@components/Page';
import Table from '../../../components/Table';

// Constants
import { server } from '@constants/servers';

// Styles
import styles from './styles.sass';

// Assets
import arrowLeft from '@assets/arrowLeft.svg';
import { GET_TABLE_CONTENT_FROM_OBJECT } from '../../../methods/tableMethods';
import useAuth from '../../../hooks/useAuth';
import { getToken } from '../../../api/articleApi';


const HandbookRegions = () => {
  const { user: { token } } = useAuth();
  const [foundName, setFoundName] = React.useState('');
  const [defaultRows, setDefaultRows] = React.useState([]);
  const [rows, setRows] = React.useState(defaultRows);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => setRows(() =>
    foundName === ''
      ? defaultRows
      : defaultRows.filter(row => row.name.toLowerCase().includes(foundName.toLowerCase()) || row.region_name.toLowerCase().includes(foundName.toLowerCase()))
  ),[foundName, defaultRows]);

  const tableHead = {
    cells: [
      { id: 'region_name', disablePadding: false, label: 'Регион', sort: true, style: { textAlign: 'left' } },
      { id: 'name', disablePadding: false, label: 'Макрорегион', sort: true, style: { textAlign: 'left' } },
    ]
  };

  const tableRows = React.useMemo(() => rows.map(row => ({
    content: GET_TABLE_CONTENT_FROM_OBJECT(
      row,
      ['region_name', 'name'],
    )
  })), [rows]);

  const fetchRegions = async () => {
    await fetch(`${server}/api/v1/monitoring/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    })
      .then(response => response.json())
      .then(res => {
        res && setDefaultRows(res);
        setLoading(false);
      })
      .catch(err => setLoading(false))
  };

  React.useEffect(() => {
    fetchRegions();
  }, []);

  return (
    <Page>
      <Grid
        container
        direction="column"
      >
        <Container>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            className={styles.SearchBlock}
          >
            <Link to='/handbooks'>
              <div className={styles.Back}>
                <img alt="" src={arrowLeft} />
                <span className={styles.Back__text}>Назад</span>
              </div>
            </Link>
          </Grid>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            alignItems="center"
            className={styles.SearchBlock}
          >
            <Grid item xs={5}>
              <div className={styles.MainTitle}>Справочник — Макрорегионы и регионы</div>
            </Grid>
            <Grid item xs={7}>
              <TextField
                placeholder="Поиск"
                style={{
                  width: '100%',
                }}
                variant="outlined"
                icon
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setFoundName(e.target.value)
                  }
                }}
              />
            </Grid>
          </Grid>
          <Table
            header={tableHead}
            rows={tableRows}
            loading={loading}
            settings={{
              emptyMessage: 'Регионов не найдено',
              defaultOrderBy: 'region_name',
              defaultOrderDirection: 'asc'
            }}
          />
        </Container>
      </Grid>
    </Page>
  )
};

export default HandbookRegions;
