// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';

// Components
import { Avatar, Box, Container, Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OpenBurgerMenu from '@material-ui/icons/Menu';
import CloseBurgerMenu from '@material-ui/icons/Close';
import MobileMenu from '../MobileMenu';

// Constants
import { analystTabMenu, editorTabMenu } from '@constants/menus';

// Methods
import { clearAllData } from '../../utils/methods';

// Assets
import logo from '../../assets/Logo.svg';
import {Select, InputLabel, FormControl} from "@mui/material";

// Styles
import styles from './styles.sass';
import useAuth from '../../hooks/useAuth';
import useUsers from "../../hooks/useUsers";
import {loadState, saveState} from "../../store/localStorage";


const Header = () => {
  const state = loadState();
  const { dispatchUserRole } = useUsers();
  const { hasAuth, user } = useAuth();
  const [selectvalue] = React.useState(state?.user?.role || '');
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const open = Boolean(anchorEl);

  const roleConverter = (role) => {
    switch (role) {
      case 'user':
        return 'Пользователь';
      case 'editor':
        return 'Редактор';
      case 'analyst':
        return 'Аналитик';
      case 'admin':
        return 'Администратор';
      case 'pr_manager':
        return 'PR менеджер';
      default:
        return 'Пользователь';
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasBurgerMenu = user?.role === 'editor';

  const handleChangeRoleForSuperUser = (v) => {
    const state = loadState();
    const user = state.user;
    const newState = {...user, role: v};
    dispatchUserRole(newState);
  };

  return (
    <Grid container className={styles.Root}>
      <Container className={styles.MainNav}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={styles.Container}
        >
          <Box
            xs={11}
            md={6}
            display="flex"
            alignItems="center"
          >
            {hasBurgerMenu && (
              <div className={styles.BurgerMenu} onClick={() => setShowMobileMenu(prev => !prev)}>
                {!showMobileMenu ? <OpenBurgerMenu /> : <CloseBurgerMenu />}
              </div>
            )}
            <Link to='/' className={styles.Logo}>
              <img alt="logotype" src={logo} />
            </Link>
            <span className={styles.Line} />
            <span className={styles.Time}>
              {`${moment().locale('ru').format('DD.MM.YYYY')}, ${moment().locale('ru').format('HH:mm')}`}
            </span>
          </Box>

          { hasAuth && (

            <Box
              xs={1}
              md={6}
              display="flex"
              alignItems="center"
              justify="flex-end"
              styles={{root: styles.AboutUser}}
            >
              <div className={styles.SuperUserWrapper}>
                {user.isSuper && (
                  <Select
                    value={selectvalue}
                    fullWidth
                    variant="standard"
                    onChange={({ target: { value }}) => handleChangeRoleForSuperUser(value)}
                  >
                    <MenuItem value="user">Пользователь</MenuItem>
                    <MenuItem value="editor">Редактор</MenuItem>
                    <MenuItem value="analyst">Аналитик</MenuItem>
                    <MenuItem value="admin">Администратор</MenuItem>
                    <MenuItem value="pr_manager">PR менеджер</MenuItem>
                  </Select>
                )}
              </div>
              <div className={styles.AboutUser__Item}>
                <div className={styles.AboutUser__PC}>
                  <span className={styles.User} onClick={() => {setIsOpen(prev => !prev)}}>
                    {`${user?.first_name || ''} ${user?.last_name || ''}`}
                    <ArrowDropDownIcon />
                    {isOpen && (
                    <div className={styles.Popover} style={{ width: 'max-content'}}>
                      <Link to='/mailingSettings' className={styles.Popover__Link}>
                        <div>Настройки</div>
                      </Link>
                      <div
                        onClick={() => clearAllData()}
                        className={styles.Popover__Link}
                      >
                        Выйти из аккаунта
                      </div>
                    </div>
                  )}
                  </span>
                  <span className={styles.Role}>{roleConverter(user?.role)}</span>
                </div>
              </div>
              <div className={styles.AboutUser__Item}>
                <div className={styles.AboutUser__PC}>
                  <Avatar
                    alt="user"
                    src=""
                    className={styles.Avatar}
                  />
                </div>
              </div>
              <div className={styles.AboutUser__Tablet}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  classes={{paper: styles.AboutMenu}}
                >
                  <MenuItem classes={{root: styles.AboutMenu__Container}}>
                    <div className={styles.AboutUser__Item}>
                      <div>
                        <span className={styles.User}>
                          {`${user?.first_name || ''} ${user?.last_name || ''}`}
                        </span>
                        <span className={styles.Role}>{roleConverter(user?.role)}</span>
                      </div>
                    </div>
                    <div className={styles.AboutUser__Item} style={{marginRight: 10}}>
                      <Avatar
                        alt="user"
                        src=""
                        className={styles.Avatar}
                      />
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <Link to='/mailingSettings'>
                      <div>Настройки</div>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <div onClick={() => clearAllData()}>Выйти из аккаунта</div>
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          )}
        </Grid>
      </Container>
      {showMobileMenu && (
        <MobileMenu
          tabs={user?.role === 'editor' ? editorTabMenu : analystTabMenu}
          role={user?.role}
        />
      )}
    </Grid>
  )
};

// Exports
export default Header;
