// @flow

// Libraries
import * as React from 'react';
import { Button, FormControl, RadioGroup } from '@material-ui/core';

// Components
import CustomPopover from '../../CustomPopover';
import { StyledFormControlLabel, StyledRadio } from '../../index';
import { BODY2 } from '@components/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

// Constants

// Styles
import styles from './styles.sass';
import { useSelector } from 'react-redux';
import { BRANCHES_QUEUE } from '../../../../Directory/Charts';

// Types
type MonitoringOptionsTypes = {
  id: string;
  closeHandler: () => void;
  submitHandler: () => void;
  values: {
    type: string,
    section: string,
    region: string,
    inMonitoring: boolean,
  };
  styles: any;
};

const   MonitoringOptions = ({
  closeHandler,
  id,
  submitHandler,
  values,
  styles: additionalStyles,
}: MonitoringOptionsTypes) => {
  const {
    monitoring_sections: sections,
    monitoring_types: types,
    monitoring_regions: regions,
  } = useSelector(store => store.monitoring_constants);

  const [monitoringType, setMonitoringType] = React.useState(values.type);

  // иногда на фронт приходят регионы новостей, которых нет в mr. Поэтому, оставляем только те, которые есть
  const [monitoringRegion, setMonitoringRegion] = React.useState(
    monitoringType === 3
      ? null
      : regions.map(({ id }) => id).includes(values.region)
        ? values.region
        : regions[0].id
  );

  const [monitoringSection, setMonitoringSection] = React.useState(values.section || sections[0].key);

  const disabled = values.type === 3 && monitoringType === 2 && !monitoringRegion;

  return (
    <CustomPopover
      id={id}
      closeHandler={() => {closeHandler()}}
      classes={{ root: styles.CheckBoxFiltersRoot }}
      styles={{...additionalStyles}}
    >
      <BODY2 style={{ fontWeight: 'bold' }}>
        Выберите тип мониторинга:
      </BODY2>
      <FormControl
        component="fieldset"
        key="monitoringType"
        className={styles.FormControl}
      >
        <RadioGroup
          aria-label="monitoringType"
          name="monitoringType"
          value={monitoringType}
          onChange={(e) => setMonitoringType(Number(e.target.value))}
        >
          {types.map(({ id, name }) => {
            if (id === 2) {
              return (
                <div className={styles.RegionLabel}>
                  <StyledFormControlLabel
                    active={monitoringType === id}
                    value={id}
                    control={<StyledRadio />}
                    label={name}
                  />
                  {monitoringType === id
                    ? (<ArrowDropUpIcon style={{ color: '#00B956', margin: '-4px 0 0 0' }} />)
                    : (<ArrowDropDownIcon style={{ color: '#00B956', margin: '-4px 0 0 0' }} />)
                  }
                </div>
              )
            } else {
              return (
                <StyledFormControlLabel
                  active={monitoringType === id}
                  value={id}
                  control={<StyledRadio />}
                  label={name}
                />
              )
            }
          })}
        </RadioGroup>
      </FormControl>
      {monitoringType === types.find(item => item.name === 'Региональный').id && (
        <div className={styles.CheckBoxGroup}>
          <RadioGroup
            aria-label="monitoringRegion"
            name="monitoringRegion"
            value={monitoringRegion}
            onChange={(e) => setMonitoringRegion(Number(e.target.value))}
          >
            {regions
              .sort((a, b) => BRANCHES_QUEUE.findIndex(name => name === a.name) - BRANCHES_QUEUE.findIndex(name => name === b.name))
              .map(region => (
                <StyledFormControlLabel
                  active={monitoringRegion === region.id}
                  value={region.id}
                  control={<StyledRadio />}
                  label={region.name}
                />
              ))}
          </RadioGroup>
        </div>
      )}
      <BODY2 style={{ fontWeight: 'bold' }}>
        Выберите раздел мониторинга:
      </BODY2>
      <FormControl component="fieldset" key="monitoringSection" className={styles.FormControl}>
        <RadioGroup
          aria-label="monitoringSection"
          name="monitoringSection"
          value={monitoringSection}
          onChange={(e) => setMonitoringSection(Number(e.target.value))}
        >
          {sections.map(section => (
            <StyledFormControlLabel
              active={monitoringSection === section.id}
              key={section.key}
              value={section.key}
              control={<StyledRadio />}
              label={section.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Button
        variant="contained"
        color="access"
        classes={{root: styles.Button}}
        onClick={() => {
          submitHandler(
            monitoringType,
            monitoringRegion,
            monitoringSection
          )
        }}
        disabled={disabled}
      >
        {!values.inMonitoring ? 'ОТПРАВИТЬ' : 'ИЗМЕНИТЬ'}
      </Button>
    </CustomPopover>
  )
};

// Exports
export default MonitoringOptions;
