// Libraries
import React from 'react';


export const useGroupArticles = articles => {
  const [groups, setGroups] = React.useState(articles || null);

  React.useEffect(() => {
    setGroups(() => articles ? Object.fromEntries(Object.entries(articles).map(([id, arrOfArticlesBySection]) => {
      const [parents, children] = arrOfArticlesBySection.reduce(
        (reducer, article) => {
          const updatedReducer = [...reducer];

          if (article.post_id === null && arrOfArticlesBySection.find(item => item.post_id === article.id)) {
            updatedReducer[0] = [...reducer[0], article];
          } else {
            updatedReducer[1] = [...reducer[1], article];
          }

          return updatedReducer;
        }, [[], []]
      );

      const mergedClassicFolder = parents.map(parent => ({
        ...parent,
        children: children.filter(({ post_id }) => post_id === parent.id)
      }));

      const cleanedChildren = children.filter(child => !parents.find(({ id }) => id === child.post_id));

      if (cleanedChildren.length > 0) {
        const anotherElems = cleanedChildren.reduce((reducer, article) => {
          const parentIndex = reducer.findIndex(({ post_id }) => post_id === article.post_id && post_id !== null);

          if (parentIndex > -1) {
            const updatedReducer = [...reducer];
            const children = reducer[parentIndex].hasOwnProperty('children') ? [...reducer[parentIndex]?.children, article] : [article]

            updatedReducer[parentIndex] = {
              ...reducer[parentIndex],
              children
            };

            return updatedReducer;
          }

          return [...reducer, article]
        }, []);

        return [id, [...mergedClassicFolder, ...anotherElems]]
      }

      return [id, mergedClassicFolder];
    })) : null);
  }, [articles]);

  return { groups, setGroups }
};
