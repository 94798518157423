// @flow

import * as React from 'react';
import QueryWrapper from "../../api/QueryWrapper";
import {getToken} from "../../api/articleApi";
import cn from 'classnames';
import 'moment/locale/ru';

import {
	Backdrop,
	Button,
	Modal,
} from '@material-ui/core';

import { server } from '@constants/servers';

import styles from './styles.scss';

type AddCSVNewListTypes = {
	setEvent: any
};

const AddCSVNewList = ({ setEvent }: AddCSVNewListTypes) => {
	const fileRef = React.useRef(null);
	const [status, setStatus] = React.useState('completed'); // "completed \ running \ failed",
	const [errors, setErrors] = React.useState([]);
	const [isOpenReportModal, setOpenReportModal] = React.useState(false);

	const getJobStatus = async () => {
		await QueryWrapper(
			fetch(`${server}/api/v1/import/posts_latest`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${getToken()}`,
				},
			}),
			(res) => {
				setStatus(res.state);
				if (res.state === 'failed') {
					setErrors(res.message)
				}
			},
			() => {}
		)
	};

	const sendCSVWithNews = async (csvFile) => {
		const formData = new FormData();
		formData.append("file", csvFile);

		await QueryWrapper(
			fetch(
				`${server}/api/v1/import/posts`,
				{
					method: 'POST',
					headers: {
						Authorization: `Bearer ${getToken()}`
					},
					body: formData
				}
			),
			() => {
				setStatus('running');
				setEvent({ open: true, message: 'Файл успешно отправлен!', status: 'success' })
			},
			() => {
				setEvent({ open: true, message: 'Не удалось загрузить файл!', status: 'error' });
			}
		);
	};

	React.useEffect(() => {
		getJobStatus();
	}, []);

	return (
		<div className={styles.AddCSVNewList_root}>
			<Modal
				open={isOpenReportModal}
				onClose={() => setOpenReportModal(false)}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<div className={styles.AddCSVNewList_modalContent}>
					{errors.map((error) => (
						<p key={error} className={styles.SingleError}>{error}</p>
					))}
				</div>
			</Modal>
			<Button
				variant="outlined"
				color={status === 'completed' ? "primary" : status === 'running' ? "secondary" : 'error'}
				className={cn({
					[styles.AddCSVNewListButton_failed]: status === 'failed',
				})}
				onClick={() => {
					if (status === 'completed' || status === 'failed') { fileRef.current.click() }
				}}
			>
				{status === 'completed' && 'Загрузить файл с новостями'}
				{status === 'running' && 'Новости в обработке'}
				{status === 'failed' && 'Ошибка. Загрузить заново'}
			</Button>
			{status === 'failed' && (
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => setOpenReportModal(true)}
				>
					Просмотреть отчет
				</Button>
			)}
			<input
				ref={fileRef}
				name="newsCSV"
				type="file"
				onChange={(e) => {
					sendCSVWithNews(e.target.files[0]);
				}}
				style={{ display: "none" }}
			/>
		</div>
	)
};

export default AddCSVNewList;

