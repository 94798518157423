// @flow

// Libraries
import React from 'react';
import {
  Chart,
  CommonAxisSettings,
  CommonSeriesSettings,
  Crosshair,
  Format,
  Grid,
  Label,
  Legend,
  Margin,
  Series,
  ValueAxis
} from 'devextreme-react/chart';
import moment from 'moment';

// Methods
import { divider } from '../../../../../methods/chartsMethods';

// Types
import { RowChart as Types } from '../../types';
import { divideGraphsValue } from '../../../../../utils/methods';

const RowChart = ({ dataSource, argumentField, series, animation }: Types) => {
  return (
    <Chart
      palette={series.map(({ color }) => color)}
      dataSource={dataSource.sort((a, b) => moment(a.date, 'DD.MM.YYYY') - moment(b.date, 'DD.MM.YYYY'))}
      animation={animation}
    >
      <CommonSeriesSettings
        argumentField={argumentField}
        type="spline"
      />
      <ValueAxis
        label={{
          customizeText: ({ value }) => divideGraphsValue(value)
        }}
      />
      <CommonAxisSettings>
        <Grid visible />
      </CommonAxisSettings>
      {series.map(({ key, name }) =>  <Series key={key} valueField={key} name={name} /> )}
      <Margin bottom={20} />
      <Crosshair
        enabled
        color="#949494"
        width={3}
        dashStyle="dot"
      >
        <Label visible>
          <Format
            precision={0}
            formatter={text => `${Number(text).toLocaleString('ru')}`}
          />
        </Label>
      </Crosshair>
      <Legend
        verticalAlignment="top"
        horizontalAlignment="right"
      />
    </Chart>
  );
}

export default React.memo(RowChart);
