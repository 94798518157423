import React from 'react';

import DynamicPRValue from './DynamicPRValue';
import PRValueSum from './PRValueSum';
import Top3Company from './Top3Company';
import PRCompanyTable from "./PRCompanyTable";

const PRValueTab = ({
	settings,
	CHART_VARIANTS,
	formattedDateTo,
	formattedDateFrom,
	range,
	regions
}: any) => {
	return (
		<>
			<DynamicPRValue
				settings={settings}
				CHART_VARIANTS={CHART_VARIANTS}
				formattedDateTo={formattedDateTo}
				formattedDateFrom={formattedDateFrom}
				range={range}
			/>
			<PRValueSum
				settings={settings}
				formattedDateTo={formattedDateTo}
				formattedDateFrom={formattedDateFrom}
			/>
			<PRCompanyTable
				type="federal"
				settings={settings}
				formattedDateTo={formattedDateTo}
				formattedDateFrom={formattedDateFrom}
			/>
			<Top3Company
				type="federal"
				regions={regions}
				settings={settings}
				formattedDateTo={formattedDateTo}
				formattedDateFrom={formattedDateFrom}
			/>
			<Top3Company
				type="regional"
				regions={regions}
				settings={settings}
				formattedDateTo={formattedDateTo}
				formattedDateFrom={formattedDateFrom}
			/>
		</>
	)
};

export default PRValueTab;
