// @flow

// Libraries
import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import fileDownload from 'js-file-download'
import axios from 'axios';

// Components
import Page from '@components/Page';
import Table from '@components/Table';
import Loader from '@components/Loader';
import DownloadIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/DeleteForever';

// Constants
import { server } from '../../constants/servers';

// Hooks
import useAuth from "../../hooks/useAuth";

// API
import { deleteFromHistory, getFullHistory } from '../../api/chartsApi';

// Styles
import styles from './styles.sass';
import QueryWrapper from '../../api/QueryWrapper';
import { getToken } from '../../api/articleApi';


const ReportsHistory = () => {
  const { user: { token } } = useAuth();
  const browserHistory = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState(null);

  const dateToRu = (date, withoutMMSS) => moment(date, 'YYYY-MM-DD mm:ss').format(withoutMMSS ? 'DD.MM.YYYY' : `DD.MM.YYYY mm:ss`);

  const handleDelete = id => {
    setLoading(true);
    deleteFromHistory(
      id,
      () => {
        setRows(prev => prev.filter(row => row.id !== id));
        setLoading(false);
      }
    );
  };

  const handleDownloadFile = (url, filename) => {
    QueryWrapper(
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'blob',
          'Authorization': `Bearer ${getToken()}`
        },
      }),
      res => {
        fileDownload(res, filename)
      },
    )
  };

  React.useEffect(() => {
    setLoading(true);
    getFullHistory(
      ({ data }) => {
        const preparedData = data.map(({ id, params }) => ({

          id: id,
          content: [
            {
              id: 'createdAt',
              label: dateToRu(params.createdAt)
            },
            {
              id: 'name',
              label: params.name
            },
            {
              id: 'period',
              label: `С ${dateToRu(params.dateFrom, true)} по ${dateToRu(params.dateTo, true)}`
            },
            {
              id: 'regions',
              label: `${params?.regions ? Object.values(params.regions).map(region => ` ${region.label}`).join() : '—'}`
            },
            {
              id: 'operators',
              label: `${params?.operators ? Object.values(params.operators).map(operator => ` ${operator.label.split(' ')[0]}`).join() : '—'}`
            },
            {
              id: 'settings',
              customContent: (
                <div className={styles.Controls} key={id}>
                  <VisibilityIcon
                    className={styles.Control}
                    onClick={() => browserHistory.push(`/report/${id}`)}
                  />
                  <DownloadIcon
                    className={styles.Control}
                    onClick={() => handleDownloadFile(`${server}/api/v1/reporting/reports/${id}/download`)}
                  />
                  <DeleteIcon
                    className={styles.Control}
                    onClick={() => {
                      handleDelete(id)
                    }}
                  />
                </div>
              )
            }
          ]
        }));
        setRows(preparedData);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, []);

  const tableHead = {
    cells: [
      {
        id: 'createdAt',
        label: 'Дата создания',
        sort: true,
      },
      {
        id: 'name',
        label: 'Название отчета',
        sort: true,
        style: {
          width: '250px'
        }
      },
      {
        id: 'period',
        label: 'Период отчета',
        sort: true,
      },
      {
        id: 'regions',
        label: 'Регионы',
        sort: true,
        style: {
          width: '320px'
        }
      },
      {
        id: 'withMagistralTheme',
        label: 'С напр. бизнеса',
        sort: true
      },
      {
        id: 'settings',
        label: '',
      },
    ]
  };

  return (
    <Page
      isDefault
      title='История отчетов'
    >
      {loading
        ? <Loader text='Загрузка' />
        : (
          <Table
            header={tableHead}
            rows={rows}
            loading={loading}
            settings={{
              emptyMessage: 'Отчетов не найдено',
              defaultOrderBy: 'createdAt',
              defaultOrderDirection: 'desc',
            }}
          />
        )
      }
    </Page>
  )
}

export default ReportsHistory;
