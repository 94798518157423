// @flow

// Libraries

// Components
import QueryWrapper from './QueryWrapper';

// Constants
import { server } from '@constants/servers';
import { getToken } from './articleApi';

export const fetchNote = async (noteId, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/notes/${noteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export const fetchNotes = async (resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/notes`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export const createNote = async (note, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/notes`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({note: note})
    }),
    resolveHandler,
    rejectHandler
  )
};

export const deleteNote = async (nodeId, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/notes/${nodeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
    }),
    resolveHandler,
    rejectHandler
  )
};
