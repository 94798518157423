// @flow

// Libraries
import 'moment/locale/ru';

// Constants
import { server } from '@constants/servers';
import QueryWrapper from './QueryWrapper';
import store from '../store/store';
import { getToken } from './articleApi';

// Токен пользователя
const {
  user: {
    token
  },
} = store.getState();

// Получение PR values
export const getPRValues = (resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/pr_values`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

// добавление PR values из файла
export const uploadPRValues = (file, resolveHandler, rejectHandler) => {
  const formData = new FormData();
  formData.append("file", file);

  QueryWrapper(
    fetch(`${server}/api/v1/pr_values/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getToken()}`,
      },
      body: formData
    }),
    resolveHandler,
    rejectHandler
  )
};

// изменение PR value (одного)
// eslint-disable-next-line max-params
export const changePRValue = (id, values, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/pr_values/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(values)
    }),
    resolveHandler,
    rejectHandler
  )
};
