const defaultRegions = [
	{
		"value": 50,
		"label": "Рязанская область",
		"region": "Столица"
	},
	{
		"value": 26,
		"label": "Калужская область",
		"region": "Столица",
	},
	{
		"label": "Смоленская область",
		"region": "Столица",
		"value": 54,
	},
	{
		"label": "Тверская область",
		"region": "Столица",
		"value": 25,
	},
	{
		"value": 64,
		"label": "Ярославская область",
		"region": "Столица"
	},
	{
		"region": "Кавказ",
		"value": 65,
		"label": "Республика Адыгея",
	},
	{
		"region": "Урал",
		"value": 61,
		"label": "Челябинская область",
	},
	{
		"region": "Урал",
		"value": 9,
		"label": "Ханты-Мансийский Автономный округ",
	},
	{
		"region": "Кавказ",
		"value": 7,
		"label": "Ставропольский край",
	},
	{
		"region": "Центр",
		"value": 20,
		"label": "Нижегородская область",
	},
	{
		"region": "Кавказ",
		"value": 69,
		"label": "Республика Кабардино-Балкария",
	},
	{
		"region": "Урал",
		"value": 59,
		"label": "Ямало-Ненецкий Автономный округ",
	},
	{
		"region": "Центр",
		"value": 15,
		"label": "Брянская область",
	},
	{
		"region": "Урал",
		"value": 80,
		"label": "Удмуртская Республика",
	},
	{
		"region": "Центр",
		"value": 45,
		"label": "Орловская область",
	},
	{
		"region": "Кавказ",
		"value": 82,
		"label": "Республика Чечня",
	},
	{
		"region": "Кавказ",
		"value": 3,
		"label": "Краснодарский край",
	},
	{
		"region": "Урал",
		"value": 53,
		"label": "Свердловская область",
	},
	{
		"region": "Кавказ",
		"value": 77,
		"label": "Республика Карачаево-Черкессия",
	},
	{
		"region": "Кавказ",
		"value": 12,
		"label": "Астраханская область",
	},
	{
		"region": "Кавказ",
		"value": 76,
		"label": "Республика Северная Осетия-Алания",
	},
	{
		"region": "Кавказ",
		"value": 68,
		"label": "Республика Дагестан",
	},
	{
		"region": "Кавказ",
		"value": 17,
		"label": "Волгоградская область",
	},
	{
		"region": "Центр",
		"value": 33,
		"label": "Курская область",
	},
	{
		"region": "Кавказ",
		"value": 71,
		"label": "Республика Калмыкия",
	},
	{
		"region": "Центр",
		"value": 14,
		"label": "Белгородская область",
	},
	{
		"region": "Кавказ",
		"value": 23,
		"label": "Республика Ингушетия",
	},
	{
		"region": "Урал",
		"value": 58,
		"label": "Тюменская область",
	},
	{
		"region": "Столица",
		"value": 38,
		"label": "Москва",
	},
	{
		"region": "Столица",
		"value": 39,
		"label": "Московская область",
	},
	{
		"region": "Столица",
		"value": 21,
		"label": "Ивановская область",
	},
	{
		"region": "Столица",
		"value": 57,
		"label": "Тульская область",
	},
	{
		"region": "Столица",
		"value": 16,
		"label": "Владимирская область",
	},
	{
		"region": "Северо-Запад",
		"value": 72,
		"label": "Республика Карелия",
	},
	{
		"region": "Северо-Запад",
		"value": 48,
		"label": "Псковская область",
	},
	{
		"region": "Северо-Запад",
		"value": 18,
		"label": "Вологодская область",
	},
	{
		"region": "Северо-Запад",
		"value": 30,
		"label": "Костромская область",
	},
	{
		"region": "Северо-Запад",
		"value": 73,
		"label": "Республика Коми",
	},
	{
		"region": "Северо-Запад",
		"value": 35,
		"label": "Ленинградская область",
	},
	{
		"region": "Северо-Запад",
		"value": 13,
		"label": "Ненецкий Автономный округ",
	},
	{
		"region": "Северо-Запад",
		"value": 11,
		"label": "Архангельская область",
	},
	{
		"region": "Северо-Запад",
		"value": 41,
		"label": "Новгородская область",
	},
	{
		"region": "Северо-Запад",
		"value": 24,
		"label": "Калининградская область",
	},
	{
		"region": "Северо-Запад",
		"value": 40,
		"label": "Мурманская область",
	},
	{
		"region": "Центр",
		"value": 19,
		"label": "Воронежская область",
	},
	{
		"region": "Северо-Запад",
		"value": 34,
		"label": "Санкт-Петербург",
	},
	{
		"region": "Поволжье",
		"value": 78,
		"label": "Республика Татарстан",
	},
	{
		"region": "Поволжье",
		"value": 31,
		"label": "Самарская область",
	},
	{
		"region": "Поволжье",
		"value": 46,
		"label": "Пензенская область",
	},
	{
		"region": "Поволжье",
		"value": 60,
		"label": "Ульяновская область",
	},
	{
		"region": "Поволжье",
		"value": 66,
		"label": "Республика Башкортостан",
	},
	{
		"region": "Поволжье",
		"value": 44,
		"label": "Оренбургская область",
	},
	{
		"region": "Поволжье",
		"value": 74,
		"label": "Республика Марий Эл",
	},
	{
		"region": "Поволжье",
		"value": 83,
		"label": "Республика Чувашия",
	},
	{
		"region": "Урал",
		"value": 32,
		"label": "Курганская область",
	},
	{
		"region": "Поволжье",
		"value": 51,
		"label": "Саратовская область",
	},
	{
		"region": "Поволжье",
		"value": 75,
		"label": "Республика Мордовия",
	},
	{
		"region": "Урал",
		"value": 29,
		"label": "Кировская область",
	},
	{
		"region": "Кавказ",
		"value": 49,
		"label": "Ростовская область",
	},
	{
		"region": "Дальний Восток",
		"value": 22,
		"label": "Иркутская область",
	},
	{
		"region": "Урал",
		"value": 47,
		"label": "Пермский край",
	},
	{
		"region": "Сибирь",
		"value": 4,
		"label": "Красноярский край",
	},
	{
		"region": "Дальний Восток",
		"value": 27,
		"label": "Камчатский край",
	},
	{
		"region": "Сибирь",
		"value": 70,
		"label": "Республика Алтай",
	},
	{
		"region": "Сибирь",
		"value": 79,
		"label": "Республика Тыва",
	},
	{
		"region": "Дальний Восток",
		"value": 62,
		"label": "Забайкальский край",
	},
	{
		"region": "Дальний Восток",
		"value": 37,
		"label": "Магаданская область",
	},
	{
		"region": "Дальний Восток",
		"value": 67,
		"label": "Республика Бурятия",
	},
	{
		"region": "Дальний Восток",
		"value": 84,
		"label": "Республика Саха (Якутия)",
	},
	{
		"region": "Центр",
		"value": 36,
		"label": "Липецкая область",
	},
	{
		"region": "Дальний Восток",
		"value": 8,
		"label": "Хабаровский край",
	},
	{
		"region": "Дальний Восток",
		"value": 85,
		"label": "Еврейская Автономная область",
	},
	{
		"region": "Сибирь",
		"value": 42,
		"label": "Новосибирская область",
	},
	{
		"region": "Сибирь",
		"value": 28,
		"label": "Кемеровская область - Кузбасс",
	},
	{
		"region": "Дальний Восток",
		"value": 5,
		"label": "Приморский край",
	},
	{
		"region": "Центр",
		"value": 55,
		"label": "Тамбовская область",
	},
	{
		"region": "Дальний Восток",
		"value": 52,
		"label": "Сахалинская область",
	},
	{
		"region": "Сибирь",
		"value": 56,
		"label": "Томская область",
	},
	{
		"region": "Сибирь",
		"value": 81,
		"label": "Республика Хакасия",
	},
	{
		"region": "Дальний Восток",
		"value": 10,
		"label": "Амурская область",
	},
	{
		"region": "Сибирь",
		"value": 43,
		"label": "Омская область",
	},
	{
		"region": "Дальний Восток",
		"value": 63,
		"label": "Чукотский Автономный Округ",
	},
	{
		"region": "Сибирь",
		"value": 1,
		"label": "Алтайский Край",
	}
]

export default defaultRegions;