// @flow

// Libraries

// Constants
import { server } from '@constants/servers';
import QueryWrapper from './QueryWrapper';
import store from '../store/store';
import { joinedGetParameters } from '../methods/anyMethods';
import { getToken } from './articleApi';

// Токен пользователя
const {
  user: {
    token
  },
} = store.getState();

// Получение кастомных тегов
export const getCustomTags = (resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/tags/custom`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

// Получение тегов для статьи в редакторе
export const getCustomTagsForPost = (postID, resolveHandler, rejectHandler) => {
  const joinedParams = joinedGetParameters({
    post_id: postID
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_tags?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

// Создание нового тега
export const createTag = ({ title, type }, resolveHandler, rejectHandler) => {
  const root = type === 'theme' ? 'themes' : 'tags';

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/${root}`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        title: title,
        type: type
      })
    }),
    resolveHandler,
    rejectHandler
  )
};

// Добавление уже имеющегося тега из каталога к статье
export const addTagFromCatalog = (data, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_tags`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        title: data.item.title,
        tag_id: data.item.id,
        post_id: data.id,
        tag_type: 'custom'
      })
    }),
    resolveHandler,
    rejectHandler
  )
};

// Массовое добавление тегов из справочника к статье из модалки
export const addSomeTagsFromCatalog = (tags, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_tags`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({posts_tags: tags})
    }),
    resolveHandler,
    rejectHandler
  )
};

// Добавление тега(ов)
export const addTag = (tagsForAdding, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/tags`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({collection: 'tags', tags: tagsForAdding })
    }),
    resolveHandler,
    rejectHandler
  )
};

// Удаление тега(ов)
export const deleteTag = ({ type, tags }, resolveHandler, rejectHandler) => {
  const root = type === 'theme' ? 'themes' : 'tags';

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/${root}/${tags[0].id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
    }),
    resolveHandler,
    rejectHandler
  )
};

// Редактирование тега
export const editTag = ({ tag, type }, resolveHandler, rejectHandler) => {
  const root = type === 'theme' ? 'themes' : 'tags';

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/${root}/${tag[0].id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        id: tag[0].id,
        title: tag[0].title
      })
    }),
    resolveHandler,
    rejectHandler
  )
};

// Открепление тега от статьи
export const removeTagFromArticle = (tagConnectionID, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_tags/${tagConnectionID}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
    }),
    resolveHandler,
    rejectHandler
  )
};

// получение набора связей тега
export const getTagConnection = ({ post_id, tag_type, tag_id }, resolveHandler, rejectHandler) => {
  const joinedParams = joinedGetParameters({
    post_id,
    tag_type,
    tag_id
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_tags?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};


// Объединение тегов
export const combineTag = (tagsId, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/tags/combine`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        tags: tagsId.map(item => ({ id: item}))
      })
    }),
    resolveHandler,
    rejectHandler
  )
};


export default { getCustomTags };
