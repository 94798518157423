import React from 'react';
import Chart from '../Chart';
import _ from 'lodash';
import { BRANCHES_QUEUE } from '../../index';
import styles from '../RowChartWithZoom/styles.sass';
import { Button } from '@material-ui/core';
import classnames from "classnames";

const VIEWS = {
  period: 'period',
  year: 'year'
};

const CHARTS_NAMES = {
  chart1: 'chart1',
  chart2: 'chart2',
  chart3: 'chart3',
}


// В будущем, outerData изменить на контекст
const Stats = ({ data, titles, compact, outerData }: any) => {
  const [views, setViews] = React.useState({
    [CHARTS_NAMES.chart1]: VIEWS.year,
    [CHARTS_NAMES.chart2]: VIEWS.year,
    [CHARTS_NAMES.chart3]: VIEWS.year,
  });

  const handleChangeView = (view, chartName) => {
    setViews(prev => ({
      ...prev,
      [chartName]: view
    }));
  };

  const { regions, OPERATORS_QUEUE } = outerData;

  const chartData1 = React.useMemo(() => {
    const [federal, regional] = [
      data[views[CHARTS_NAMES.chart1]].federal,
      data[views[CHARTS_NAMES.chart1]].regional
    ];

    return [{
      label: '',
      beeline: federal.beeline + regional.beeline,
      megafon: federal.megafon + regional.megafon,
      mts: federal.mts + regional.mts,
      tele2: federal.tele2 + regional.tele2,
      yota: federal.yota + regional.yota,
    }]
  }, [data, views]);

  const chartData2 = React.useMemo(() => [
    {
      label: "Федеральные",
      ...data[views[CHARTS_NAMES.chart2]].federal
    },
    {
      label: "Региональные",
      ...data[views[CHARTS_NAMES.chart2]].regional
    },
  ], [data, views]);

  const chartData3 = React.useMemo(() => {
    return _.orderBy(
      Object.entries(data[views[CHARTS_NAMES.chart3]].regions).map(([region, stats]) => ({
        label: regions.find(({ id }) => Number(id) === Number(region))?.name,
        ...stats
      })),
      item => BRANCHES_QUEUE.map(name => name).indexOf(item.label),
      'asc'
    );
  }, [data, views]);

  const Controls = React.useCallback(({ chartName }: any) => (
    <div className={styles.Actions} style={{marginTop: 50, marginBottom: 0}}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleChangeView(VIEWS.period, chartName)}
        classes={{root: classnames(
          styles.Action,
          views[chartName] === VIEWS.period && styles.Action_selected
        )}}
      >
        За период
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleChangeView(VIEWS.year, chartName)}
        classes={{root: classnames(
          styles.Action,
          views[chartName] === VIEWS.year && styles.Action_selected
        )}}
      >
        С начала года
      </Button>
    </div>
  ), [views]);

  return (
    <>
      <Chart
        type="BarChart"
        dataSource={chartData1}
        series={OPERATORS_QUEUE}
        title={titles[views[CHARTS_NAMES.chart1]].all}
        sortDirection='none'
        Controls={<Controls chartName={CHARTS_NAMES.chart1} />}
      />
      <Chart
        type="BarChart"
        dataSource={chartData2}
        series={OPERATORS_QUEUE}
        title={titles[views[CHARTS_NAMES.chart2]].types}
        sortDirection='none'
        Controls={<Controls chartName={CHARTS_NAMES.chart2} />}
      />
      <Chart
        type="BarChart"
        dataSource={chartData3}
        series={OPERATORS_QUEUE}
        title={titles[views[CHARTS_NAMES.chart3]].regions}
        sortDirection='none'
        compact={compact}
        Controls={<Controls chartName={CHARTS_NAMES.chart3} />}
      />
    </>
  );
};

export default Stats;
