// @flow

// Libraries
import React from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid
} from '@material-ui/core';

// Components
import Page from '@components/Page';
import { StyledFormControlLabel } from '../Main/Components';
import QueryWrapper from '../../api/QueryWrapper';
import classnames from 'classnames';

// Constants
import { server } from '@constants/servers';

// Styles
import styles from './styles.sass';
import useAuth from '../../hooks/useAuth';
import { BRANCHES_QUEUE } from '../Directory/Charts';
import { getToken } from '../../api/articleApi';


const MailingSettings = () => {
  const [settings, setSettings] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [dialogs, setDialogs] = React.useState({
    hasDescribed: false,
    wantDescribe: false
  });
  const [error, setError] = React.useState(false);

  const handleChangeDialogs = values => setDialogs(prev => ({ ...prev, ...values }));

  const checkSubscription = data => {
    if (data && !data?.error) {
      const { subscribe } = data;

      // выключены все виды мониторингов
      const condition1 = subscribe.monitoring_types.every(({ value }) => !value);

      // выключены все регионы, если федеральные уже отключены
      const { value } = subscribe.monitoring_types.find(({ id }) => id === 3);
      const condition2 = !value && subscribe.monitoring_regions.every(({ value }) => !value);

      // выключены все операторы
      const condition3 = subscribe.monitoring_sections.every(({ value }) => !value);

      const conditions = [
        condition1,
        condition2,
        condition3
      ];

      return conditions.every(condition => !condition);
    }

    return true;
  };

  React.useEffect(() => {
    const handleError = (res = null) => {
      if (res?.error || !res) {
        setError(true);
      } else {
        setSettings(res);
      }
    };

    QueryWrapper(
      fetch(`${server}/api/v1/account/settings`, {
        method: 'GET',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${getToken()}`
        },
      }),
      (res) => {
        handleError(res);
        setLoading(false);
      },
      () => {
        handleError();
        setLoading(false);
      }
    )
  }, []);

  const handleSubmit = updatedSettings => {
    setLoading(true);

    QueryWrapper(
      fetch(`${server}/api/v1/account/settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify(updatedSettings)
      }),
      () => {
        setLoading(false);
        setSettings(updatedSettings);
      },
      () => {
        setLoading(false);
      },
    )
  };

  React.useEffect(() => {
    !checkSubscription(settings) && handleChangeDialogs({ hasDescribed: true });
  }, [settings]);

  const getUpdatedFields = React.useCallback((fields, updatedField) => [...fields].map(field =>
    field.id === updatedField.id
      ? {
        ...field,
        ...updatedField
      }
      : field
  ), []);

  const renderFormControl = (data, fieldName) => (
    <StyledFormControlLabel
      active={data.value}
      key={data.id}
      value={`${data.id}`}
      control={(
        <Checkbox
          checked={data.value}
          onChange={({ target: { checked } }) => {
            const updatedSettings = {
              ...settings,
              subscribe: {
                ...settings.subscribe,
                [fieldName]: getUpdatedFields(
                  settings.subscribe[fieldName],
                  {
                    ...data,
                    value: checked
                  }
                ),
              }
            }

            const regionalMonitoringHasBeenChanged = data.id === 2 && fieldName === 'monitoring_types';

            if (regionalMonitoringHasBeenChanged) {
              updatedSettings.subscribe.monitoring_regions = settings.subscribe.monitoring_regions.map(region => ({
                ...region,
                value: !data.value
              }))
            }

            const allRegionsHasBeenOff = updatedSettings.subscribe.monitoring_regions.every(({ value }) => !value);
            if (!regionalMonitoringHasBeenChanged && allRegionsHasBeenOff) {
              updatedSettings.subscribe.monitoring_types = updatedSettings.subscribe.monitoring_types.map(type => type.id === 2 ? ({
                ...type,
                value: false
              }) : type)
            }

            if (!allRegionsHasBeenOff) {
              updatedSettings.subscribe.monitoring_types = getUpdatedFields(
                updatedSettings.subscribe.monitoring_types,
                {
                  id: 2,
                  value: true
                }
              )
            }

            // подписаны ли мы прямо сейчас
            if (checkSubscription(settings)) {
              // останемся ли мы подписанными после того, как применим новые настройки
              if (checkSubscription(updatedSettings)) {
                handleSubmit(updatedSettings);
              } else {
                handleChangeDialogs({ wantDescribe: true });
              }
            } else {
              if (['monitoring_types', 'monitoring_regions'].includes(fieldName)) {
                updatedSettings.subscribe.monitoring_sections = updatedSettings.subscribe.monitoring_sections.map(operator => ({
                  ...operator,
                  value: true
                }))
              } else {
                updatedSettings.subscribe.monitoring_regions = updatedSettings.subscribe.monitoring_regions.map(region => ({
                  ...region,
                  value: true
                }));
                updatedSettings.subscribe.monitoring_types = updatedSettings.subscribe.monitoring_types.map(type => ({
                  ...type,
                  value: true
                }))
              }

              handleSubmit(updatedSettings);
            }
          }}
          name={data.label}
        />
      )}
      label={data.label}
    />
  );

  const handleSetAllValues = checked => {
    const updatedSettings = {
      ...settings,
      subscribe: Object.fromEntries(
        Object.entries(settings.subscribe).map(([key, values]) => [
          key,
          values.map(value => ({
            ...value,
            value: checked
          }))
        ])
      )
    };

    handleSubmit(updatedSettings);
  };

  const allValuesEqualTo = equalTo => settings
    && Object.values(settings.subscribe).flat().every(({ value }) => value === equalTo);

  return (
    <Page
      loading={loading}
      forUser
    >
      <Grid
        container
        direction="column"
      >
        <Container className={styles.Container}>
          {error ? (
            <div className={styles.Error}>Извините, настройки рассылки временно недоступны</div>
          ) : settings && (
            <>
              <div className={styles.TitleMain}>
                <span>Рассылка:</span>
                {checkSubscription(settings) ? (
                  <span className={classnames(styles.Subscription, styles.Subscription_on)}>
                    подключена
                  </span>
                ) : (
                  <span className={classnames(styles.Subscription, styles.Subscription_off)}>
                    отключена
                  </span>
                )}
              </div>
              <div className={styles.Item}>
                <div className={styles.TitleSecondary}>Источники новостей</div>
                <div className={styles.Select}>
                  <div className={styles.TitleMini}>Тип:</div>
                  <div>
                    <FormControl component="fieldset" key="monitoring_types" className={styles.FormControl}>
                      {settings.subscribe.monitoring_types
                        .sort((a, b) => b.id - a.id)
                        .map(type => {
                          if (type.label !== 'Зарубежный') {
                            return renderFormControl(type, 'monitoring_types')
                          }
                        })
                      }
                    </FormControl>
                  </div>
                </div>
                <div>
                  <div className={styles.TitleMini}>Макрорегион:</div>
                  <div>
                    <FormControl component="fieldset" key="monitoring_regions" className={styles.FormControl}>
                      {settings.subscribe.monitoring_regions
                        .sort((a, b) => BRANCHES_QUEUE.findIndex(label => label === a.label) - BRANCHES_QUEUE.findIndex(label => label === b.label))
                        .map(region => renderFormControl(region, 'monitoring_regions'))}
                    </FormControl>
                  </div>
                </div>
                <div>
                  <div className={styles.TitleMini}>Оператор:</div>
                  <div>
                    <FormControl component="fieldset" key="monitoring_sections" className={styles.FormControl}>
                      {settings.subscribe.monitoring_sections.map(section => renderFormControl(section, 'monitoring_sections'))}
                    </FormControl>
                  </div>
                </div>
              </div>
              <div style={{marginTop: 30}}>
                <Button
                  variant='outlined'
                  onClick={() => handleSetAllValues(true)}
                  style={{marginRight: 10}}
                  disabled={allValuesEqualTo(true)}
                >
                  Выделить все
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => handleChangeDialogs({ wantDescribe: true })}
                  disabled={allValuesEqualTo(false)}
                >
                  Отписаться от рассылки
                </Button>
              </div>
            </>
          )}
        </Container>
      </Grid>
      <Dialog
        open={dialogs.wantDescribe}
        onClose={() => handleChangeDialogs({ wantDescribe: false })}
      >
        <DialogTitle id="alert-dialog-title">
          Вы действительно хотите отписаться от всех рассылок?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            После нажатия на кнопку «Отписаться», вы перестанете получать рассылки новостей
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Возобновить подписку можно выбрав хотя бы один из источников мониторинга
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => handleChangeDialogs({ wantDescribe: false })}
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              handleChangeDialogs({ wantDescribe: false })
              handleSetAllValues(false);
            }}
            autoFocus
          >
            Отписаться
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogs.hasDescribed}
        onClose={() => handleChangeDialogs({ hasDescribed: false })}
      >
        <DialogTitle id="alert-dialog-title">
          Вы отписались от всех рассылок
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Чтобы возобновить подписку, выберите хотя бы один из источников новостей
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Page>
  )
}

export default MailingSettings;
