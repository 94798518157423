import React from 'react';
import YandexShare from 'react-yandex-share';
import { Popover } from '@material-ui/core';
import share from '@assets/share.svg';
import styles from '../styles.sass';
import CopyLinkButton from '../../CopyLinkButton';


const Share = ({ source, title }: any) => {
  const [showShareAnchor, setShowShareAnchor] = React.useState(null);

  const handleShare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareAnchor(e.currentTarget);
  };

  return source?.is_source_external ? (
    <>
      <button
        className={styles.Action}
        onClick={handleShare}
      >
        <img alt="" src={share} />
      </button>
      <Popover
        open={Boolean(showShareAnchor)}
        anchorEl={showShareAnchor}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowShareAnchor(null);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{paper: styles.PopoverShare}}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={styles.PopoverShare__Wrapper}
        >
          <div className={styles.PopoverShare__Title}>Поделиться</div>
          <CopyLinkButton
            link={source?.url}
            margin='10px 0'
          />
          <div className={styles.PopoverShare__YandexShare}>
            <YandexShare
              content={{
                title,
                url: source?.url
              }}
              theme={{ lang: 'en', services: 'vkontakte,facebook,telegram' }}
            />
          </div>
        </div>
      </Popover>
    </>
  ) : <></>;
};

export default Share;