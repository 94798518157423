// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  Button, CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Popover,
  Table as TableBefore,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from '@material-ui/core';
import { Add, DeleteForever, Search } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// Components
import { StyledAddButton, StyledGrid, StyledSubmitButton, StyledTableCell } from './CustomComponents';
import StyledPaper from '@components/StyledPaper';
import Page from '@components/Page';
import { BODY2, H6 } from '@components/Typography';

// Constants
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';

// Api
import { createManagers, fetchOperators, updateManagers } from '../../../api/operatorsApi';

// Assets
import arrowLeft from '@assets/arrowLeft.svg';
import megafonLogo from '@assets/operators/megafon.svg';
import beelineLogo from '@assets/operators/beline.svg';
import mtsLogo from '@assets/operators/mts.svg';
import { ReactComponent as TriangleIcon } from '@assets/triangle.svg';

// Styles
import styles from './styles.sass';
import Loader from '../../../components/Loader';

const headCells = [
  { id: 'operator_name', disablePadding: false, label: 'Название оператора' },
  { id: 'title', disablePadding: false, label: 'Должность' },
  { id: 'manager_fio', disablePadding: false, label: 'ФИО Менеджера' },
  { id: 'edit', disablePadding: false, label: '' },
];

const DEFAULT_MODAL_DATA = {
  isAddOperator: false,
  isEditOperator: false,
  operatorName: '',
  newOperatorName: null,
  data: [],
  title: ''
}

const HandbookOperators = () => {
  const [operators, setOperators] = React.useState([]);
  const [mergedOperators, setMergedOperators] = React.useState([]);
  const [modalData, setModalData] = React.useState(DEFAULT_MODAL_DATA);
  const [isOpenModal, setIsOpenModalState] = React.useState(false);
  const [foundName, setFoundName] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [addOperator, setAddOperator] = React.useState(false);
  const [anchorPopover, setAnchorPopover] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const logoBuilder = (operator) => {
    switch (operator) {
      case 'МегаФон':
        return megafonLogo;
      case 'билайн':
        return beelineLogo;
      case 'МТС':
        return mtsLogo;
      default:
        return
    }
  };

  const refetchOperators = async () => {
    setLoading(true);

    await fetchOperators(
      (res) => {
        setOperators(res.map(operator => ({ ...operator })))
        setMergedOperators(mergingOperators(res));
      },
      (e) => console.log('e', e)
    )

    setLoading(false);
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  React.useEffect(() => {
    if (foundName === '')
      refetchOperators();
    else {
      setMergedOperators(() => {
        const clearedFoundName = foundName.toLowerCase();
        const searchedRows = operators.filter(operator =>
          operator.manager_fio.toLowerCase().includes(clearedFoundName)
          || operator.title.toLowerCase().includes(clearedFoundName));

        if (searchedRows.length < 1) {
          return mergingOperators(operators.filter(operator => operator.operator_name.toLowerCase().includes(clearedFoundName)));
        }

        return mergingOperators(searchedRows);
      })
    }
  }, [foundName]);

  const handleChangeField = (value, fieldName, index) => {
    setModalData(prev => {
      const newModalData = [...prev.data];
      newModalData[index][fieldName] = value;
      return {
        ...prev,
        data: newModalData
      };
    });
  };

  const handleDeleteManager = (index) => {
    setModalData(prev => {
      const newModalData = [...prev.data];

      if (newModalData[index].isCustom) {
        newModalData.splice(index, 1);
        return {
          ...prev,
          data: newModalData
        };
      }

      newModalData[index] = {
        ...newModalData[index],
        deleted_at: new Date()
      }

      return {
        ...prev,
        data: newModalData
      };
    })
  };

  const handleSaveModalData = async () => {
    const [changeManagersData, createManagersData] = modalData.data.reduce((reducedData, data) => {
      const copiedReducer = [...reducedData];

      // если пользователь не создан, а изменен или удален:
      if (!data.isCustom) {
        copiedReducer[0].push({
          ...data,
          operator_name: modalData.newOperatorName || data.operator_name,
        });
        return copiedReducer;
      }

      copiedReducer[1].push({
        operator_name: modalData.newOperatorName || data.operator_name,
        manager_fio: data.manager_fio,
        title: data.title,
      });

      return copiedReducer;
    }, [[], []]);

    await updateManagers(changeManagersData);
    await createManagers(createManagersData);
    await refetchOperators();

    setIsOpenModalState(false);
  };

  const mergingOperators = (data) => {
    const defaultReducerValue = _.uniqBy(data,'operator_name').map(item => ({
      ...item,
      manager_fio: [],
      title: []
    }));

    return data.reduce((reducer, item) => {
      const foundIndex = reducer.findIndex(reducerItem => reducerItem.operator_name === item.operator_name);
      const copiedReducer = [...reducer];
      copiedReducer[foundIndex] = {
        ...copiedReducer[foundIndex],
        manager_fio: [...copiedReducer[foundIndex].manager_fio, item.manager_fio],
        title: [...copiedReducer[foundIndex].title, item.title],
      };

      return copiedReducer;
    }, defaultReducerValue);
  }

  React.useEffect(() => {
    refetchOperators();
  }, []);

  const handleAddOperator = () => {
    setModalData(prev => ({ ...prev, data: [...prev.data, addOperator] }));
    setAddOperator(false);
  };

  return (
    <Page>
      <Grid
        container
        direction="column"
      >
        <Modal
          open={isOpenModal}
          onClose={() => {
            setModalData(DEFAULT_MODAL_DATA);
            setIsOpenModalState(false);
          }}
        >
          <StyledPaper>
            <H6 style={{ margin: '0 0 24px 0'}}>{modalData.title}</H6>
            <Grid container xl={11} lg={11}>
              <TextField
                fullWidth
                variant="outlined"
                label="Имя оператора"
                type="text"
                value={modalData.newOperatorName || modalData?.data[0]?.operator_name}
                onChange={({ target: { value } }) => setModalData(prev => {
                  return {
                    ...prev,
                    newOperatorName: value
                  };
                })}
                style={{marginBottom: 20}}
              />
            </Grid>
            {modalData.data.map((data, index) => (
              <>
                {data.deleted_at === null && (
                  <Grid
                    key={data.id}
                    container
                    xl={12}
                    lg={12}
                    style={{marginBottom: 20}}
                  >
                    <StyledGrid
                      xl={11}
                      lg={11}
                    >
                      <Grid item xl lg spacing={2}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Должность"
                          type="text"
                          value={data.title}
                          onChange={(e) => handleChangeField(e.target.value, 'title', index)}
                        />
                      </Grid>
                      <Grid item xl lg>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Имя"
                          type="text"
                          value={data.manager_fio}
                          onChange={(e) => handleChangeField(e.target.value, 'manager_fio', index)}
                        />
                      </Grid>
                    </StyledGrid>
                    <Grid xl={1} lg={1} container justify="center">
                      <IconButton aria-label="delete" onClick={() => handleDeleteManager(index)}>
                        <DeleteForever />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </>
            ))}
            <Grid container xl={11} lg={11}>
              <StyledAddButton
                fullWidth
                disabled={addOperator}
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setAddOperator({
                  id: uuidv4(),
                  operator_name: modalData?.data[0]?.operator_name || modalData.newOperatorName,
                  manager_fio: '',
                  title: '',
                  deleted_at: null,
                  isCustom: true
                })}
              >
                ДОБАВИТЬ НОВУЮ ДОЛЖНОСТЬ
              </StyledAddButton>
              <Dialog open={addOperator} onClose={() => { setAddOperator(false) }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Добавление новой должности</DialogTitle>
                <DialogContent>
                  <StyledGrid
                    xl={12}
                    lg={12}
                  >
                    <Grid item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Должность"
                        type="text"
                        value={addOperator.title}
                        onChange={({ target: { value }}) => setAddOperator(prev => ({ ...prev, title: value }))}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Имя"
                        type="text"
                        value={addOperator.manager_fio}
                        onChange={({ target: { value }}) => setAddOperator(prev => ({ ...prev, manager_fio: value }))}
                      />
                    </Grid>
                  </StyledGrid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAddOperator(false)} color="primary">
                    Отменить
                  </Button>
                  <Button onClick={handleAddOperator} color="primary">
                    Добавить
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container xl={11} lg={11} justify="space-between">
              <Button
                size="large"
                onClick={() => setIsOpenModalState(false)}
              >
                Отменить
              </Button>
              <StyledSubmitButton
                variant="contained"
                size="large"
                onClick={() => handleSaveModalData()}
              >
                Сохранить
              </StyledSubmitButton>
            </Grid>
          </StyledPaper>
        </Modal>
        <Container>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            className={styles.SearchBlock}
          >
            <Link to='/handbooks'>
              <div className={styles.Back}>
                <img alt="" src={arrowLeft} />
                <span className={styles.Back__text}>Назад</span>
              </div>
            </Link>
          </Grid>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            alignItems="center"
            className={styles.SearchBlock}
          >
            <Grid item xs={4}>
              <div className={styles.MainTitle}>Справочник — Операторы связи</div>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={styles.AddButton}
                onClick={() => {
                  setModalData(prev => ({
                    ...prev,
                    isAddOperator: true,
                    title: 'Добавление нового оператора',
                    data: []
                  }));
                  setIsOpenModalState(true);
                }}
              >
                Добавить
              </Button>
              <Popover
                open={Boolean(anchorPopover)}
                anchorEl={anchorPopover}
                onClose={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAnchorPopover(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{paper: styles.PopoverAdd}}
              >
                <div>123</div>
              </Popover>
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Поиск"
                fullWidth
                variant="outlined"
                icon
                InputProps={{
                  startAdornment: (
                    <InputAdornment title="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setFoundName(e.target.value)
                  }
                }}
              />
            </Grid>
          </Grid>
          <div className={styles.Table}>
            <Paper>
              <TableContainer>
                <TableBefore
                  aria-labelledby="tableTitle"
                  size='medium'
                  aria-label="enhanced table"
                  theme='black'
                >
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align='left'
                          padding={headCell.disablePadding ? 'none' : 'default'}
                          sortDirection={orderBy === headCell.id ? order : false}
                          className={styles.Title}
                        >
                          {headCell.id === 'edit' ? (
                            headCell.label
                          ) : (
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={createSortHandler(headCell.id)}
                              IconComponent={() => <TriangleIcon className={styles.SortIcon} style={{transform: order === 'desc' && orderBy === headCell.id && 'rotate(180deg)'}} />}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? (
                        <TableRow
                          style={{
                            width: '100%',
                            height: 600
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            colSpan={3}
                          >
                            <Loader text='Загрузка операторов связи...' />
                          </TableCell>
                        </TableRow>
                      ) : stableSort(mergedOperators, getComparator(order, orderBy))
                        .map((mergedOperator) => (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={mergedOperator.operator_name}
                          >
                            <TableCell align="left" style={{width: 350}}>
                              <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                              >
                                <img src={logoBuilder(mergedOperator.operator_name)} alt="" />
                                <BODY2 style={{ fontWeight: 'bold', margin: '6px 0 0 0'}}>{mergedOperator.operator_name}</BODY2>
                              </Grid>
                            </TableCell>
                            <StyledTableCell align="left" style={{ padding: '0', margin: '0', maxWidth: 550 }}>
                              {mergedOperator.title.map((title, idx) => (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={title}
                                  style={{
                                    display: 'block',
                                    width: '100%'
                                  }}
                                >
                                  <StyledTableCell
                                    align="left"
                                    style={{
                                      display: 'block',
                                      borderBottom: (idx + 1) === mergedOperator.title.length
                                        ? 'none'
                                        : '1px solid rgba(224, 224, 224, 1)',
                                      paddingRight: 15,
                                    }}
                                  >
                                    <p className={styles.Cell}>{title}</p>
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ padding: '0', margin: '0'}}>
                              {mergedOperator.manager_fio.map((name, idx) => (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={name}
                                  style={{
                                    display: 'block',
                                    width: '100%'
                                  }}
                                >
                                  <StyledTableCell
                                    align="left"
                                    style={{
                                      display: 'block',
                                      borderBottom: (idx + 1) === mergedOperator.manager_fio.length
                                        ? 'none'
                                        : '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                  >
                                    <p className={styles.Cell}>{name}</p>
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            </StyledTableCell>
                            <TableCell align="left">
                              <div className={styles.EditIcon}>
                                <EditIcon
                                  fill='#00B956'
                                  onClick={() => {
                                    setModalData(prev => ({
                                      ...prev,
                                      isEditOperator: true,
                                      title: `Редактирование оператора ${mergedOperator.operator_name}`,
                                      data: operators.filter(operator => operator.operator_name === mergedOperator.operator_name).map(operator => ({ ...operator })),
                                    }));
                                    setIsOpenModalState(true);
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </TableBefore>
              </TableContainer>
            </Paper>
          </div>
        </Container>
      </Grid>
    </Page>
  )
};

export default HandbookOperators;
