// @flow

// Libraries
import { combineReducers, createStore } from 'redux';

/* eslint-disable */
import { reducer as form } from 'redux-form';

// Reducers
import monitoringConstantsReducer from './monitoringConstants';
import userReducer from './user';
import eventReducer from './event';
import settingsReducer from './settings';
import { loadState, saveState } from './localStorage';
import routingsReducer from './routings';


// настройки, которые у пользователя в фиолетовой шапке (все остальные)
const userMonitoringSettingsReducer = (
  state = {
    monitoring_type: 3
  },
  action
) => {
  switch (action.type) {
    case 'SET_USER_MONITORING_SETTINGS': {
      return { ...action.payload };
    }

    case 'CHANGE_USER_MONITORING_SETTINGS': {
      return { ...state, ...action.payload };
    }

    case 'CLEAR_USER_MONITORING_SETTINGS': {
      return {};
    }

    default:
      return state;
  }
};

// настройки отображения новостей
const globalMonitoringSettingsReducer = (
  state = {
    view: 'cells'
  },
  action
) => {
  switch (action.type) {
    case 'SET_GLOBAL_MONITORING_SETTINGS': {
      return { ...action.payload };
    }

    case 'UPDATE_GLOBAL_MONITORING_SETTINGS': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'CLEAR_GLOBAL_MONITORING_SETTINGS': {
      return {};
    }

    default: {
      return state;
    }
  }
};


const reducers: Object = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  event: eventReducer,
  monitoring_constants: monitoringConstantsReducer,
  routings: routingsReducer,
  form,

  // will be refactor or remove:
  user_monitoring_settings: userMonitoringSettingsReducer,
  global_monitoring_settings: globalMonitoringSettingsReducer,
});

const persistedState = loadState();
const store = createStore(
  reducers,
  persistedState
);

store.subscribe(() => {
  saveState({
    user: store.getState().user,
    settings: store.getState().settings,
    event: store.getState().event,
    monitoring_constants: store.getState().monitoring_constants,
    form: store.getState().form,
    user_monitoring_settings: store.getState().user_monitoring_settings,
    global_monitoring_settings: store.getState().global_monitoring_settings
  });
});

export default store;
