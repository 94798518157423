// Libraries
import React from 'react';
import * as classnames from 'classnames';
import moment from 'moment';

// Components
import {
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Button
} from '@material-ui/core';
import StyledPaper from '@components/StyledPaper';
import { CalendarToday, Close as CloseIcon } from '@material-ui/icons';
import DateRangePicker from '../../../../components/DateRangePicker';
import ProccesedMenu from '../ProccesedMenu';
import HandbookTagsModal from '../../../../components/HandbookTagsModal';
import SearchBlock from '../SearchBlock';
import SortDirection from '../SortDirection';
import FavoritesSort from '../FavoritesSort';
import MultiSelect from 'react-multi-select-component';

// Hooks
import { useSelector } from 'react-redux';

// Assets
import downArrowIcon from '../../../../assets/downArrow.svg';
import rubbishIcon from '../../../../assets/rubbish.svg';
import { sortByBranchesQueue } from '../../../Directory/Charts';
import { getToken } from '../../../../api/articleApi';
import { server } from '@constants/servers';
import { RUS_STRINGS } from '@constants/charts';
import { useDefaultEditorFilters } from '../../index';
import styles from './styles.sass';

const FiltersModal = ({
  openModal,
  setOpenModal,
  filters,
  handleChangeFilters
}: any) => {
  const {
    monitoring_sections,
    monitoring_regions,
    monitoring_types
  } = useSelector(({ monitoring_constants }) => monitoring_constants);
  const { defaultFilters } = useDefaultEditorFilters();
  const [bufferedFilters, setBufferedFilters] = React.useState(filters);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const [showRangePicker, setShowRangePicker] = React.useState(false);
  const [defaultRows, setDefaultRows] = React.useState([]);
  const [defaultSubRegions, setDefaultSubRegions] = React.useState([]);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [searchIsActive, setSearchIsActive] = React.useState(false);

  const fetchRegions = async () => {
    await fetch(`${server}/api/v1/monitoring/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    })
      .then(response => response.json())
      .then(res => {
        res && setDefaultRows(res);
        setSelectedRegions(
          res.map(option => ({
            label: option.region_name,
            value: option.region_id,
            region: option.name
          }))
        );
        setDefaultSubRegions(
          res.map(option => ({
            label: option.region_name,
            value: option.region_id,
            region: option.name
          }))
        );
      });
  };

  React.useEffect(() => {
    fetchRegions();
  }, []);

  const filterRegions = (data, searchString) => {
    setSearchIsActive(Boolean(searchString));

    const regionsSet = new Set(
      bufferedFilters?.macroRegions.map(region => region.name)
    );
    const lowerCaseSearchString = searchString
      ? searchString.toLowerCase()
      : '';

    return data.reduce((filtered, d) => {
      if (
        regionsSet.has(d.region) &&
        (!searchString || d.label.toLowerCase().includes(lowerCaseSearchString))
      ) {
        filtered.push(d);
      }

      return filtered;
    }, []);
  };

  const handleChangeBufferedFilters = React.useCallback(
    changedBufferedFilters => {
      setBufferedFilters(prev => ({ ...prev, ...changedBufferedFilters }));
    },
    [bufferedFilters]
  );

  React.useEffect(() => {
    setBufferedFilters(filters);
  }, [filters]);

  const CheckBox = React.useCallback(
    ({ checked, onChange, label, bold }: any) => (
      <span className={styles.CheckBox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={({target: {checked}}) => onChange(checked)}
            />
          }
          label={label}
          classes={{
            root: classnames(
              styles.CheckBox__Content,
              bold ? styles.CheckBox__Content_bold : ''
            )
          }}
        />
      </span>
    ),
    [bufferedFilters]
  );

  const CheckBoxes = React.useCallback(
    ({ data, field, bold }) => {
      return data.map(checkBoxData => {
        const { name, id } = checkBoxData;
        const checked = bufferedFilters[field]?.some(
          valueFrom => valueFrom.id === id
        );

        return (
          <CheckBox
            key={name}
            checked={checked}
            onChange={newCheckedValue => {
              const newValue = {
                [field]: newCheckedValue
                  ? [...bufferedFilters[field], checkBoxData]
                  : bufferedFilters[field]?.filter(bufItem => bufItem.id !== id)
              };

              if (field === 'macroRegions' && checked) {
                setSelectedRegions(
                  selectedRegions.filter(sr => sr.region !== checkBoxData.name)
                );
              }

              if (field === 'types' && id === 2 && checked) {
                // отключение "Региональный", за ним отключение селектов всех регионов
                handleChangeBufferedFilters({
                  macroRegions: [],
                  ...newValue
                });
                setSelectedRegions([]);

                return;
              }

              if (field === 'types' && id === 2 && !checked) {
                // выбор "Региональный", за ним селект всех регионов
                handleChangeBufferedFilters({
                  macroRegions: monitoring_regions,
                  ...newValue
                });
                setSelectedRegions(
                  defaultRows
                    .map(option => ({
                      label: option.region_name,
                      value: option.region_id,
                      region: option.name
                    }))
                    .sort((a, b) => (a.label > b.label ? 1 : -1))
                );

                return;
              }

              if (field === 'macroRegions' && !checked) {
                // выбор макрорегиона
                handleChangeBufferedFilters({
                  types: [
                    ...bufferedFilters.types,
                    { id: 2, name: 'Региональный' }
                  ],
                  ...newValue
                });
                setSelectedRegions(p => (p.concat(
                  defaultRows.filter(sr => sr.name === name)
                  .map(option => ({
                    label: option.region_name,
                    value: option.region_id,
                    region: option.name
                  }))
                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                )));

                return;
              }

              if (
                field === 'macroRegions' &&
                bufferedFilters.macroRegions.length === 1 &&
                checked
              ) {
                handleChangeBufferedFilters({
                  types: bufferedFilters.types.filter(t => t.id === 3),
                  ...newValue
                });

                return;
              }

              handleChangeBufferedFilters({ ...newValue });
            }}
            label={name}
            bold={bold}
          />
        );
      });
    },
    [bufferedFilters, selectedRegions]
  );

  const Area = React.useCallback(
    ({ data = [], onRemove }: any) => (
      <div className={styles.Area}>
        {data.map(item => (
          <div key={item} className={styles.Area__Item}>
            <span className={styles.Area__Item__Text}>{item}</span>
            <div
              className={styles.Area__Item__Delete}
              onClick={() => onRemove(item)}
            >
              <CloseIcon />
            </div>
          </div>
        ))}
      </div>
    ),
    []
  );

  const handleClose = React.useCallback(() => {
    setShowRangePicker(false);
    setOpenModal(false);
  }, []);

  const handleSetFilters = React.useCallback(() => {
    handleChangeFilters({ ...bufferedFilters, regions: selectedRegions });
    handleClose();
  }, [bufferedFilters, selectedRegions]);

  const hasErrors = React.useMemo(() => {
    const emptySections = bufferedFilters?.sections?.length === 0;
    const emptyTypes = bufferedFilters?.types?.length === 0;
    const emptyMacroRegion =
      bufferedFilters?.macroRegions?.length !== 0 &&
      selectedRegions?.length === 0;

    return emptySections || emptyTypes || emptyMacroRegion;
  }, [bufferedFilters, selectedRegions]);

  const FavoriteText = () => (
    <div className={styles.InFavorite__Text}>Избранное</div>
  );

  const renderItemSelect = ({ checked, option, onClick, disabled }: any) => {
    return (
      <div
        className={`item-renderer ${disabled && 'disabled'}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Checkbox
          color="secondary"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
          style={{ margin: 0, padding: 0, height: 20 }}
        />
        <span>{option.label}</span>
      </div>
    );
  };

  return (
    <>
      <Modal open={openModal} onClose={handleClose}>
        <StyledPaper width={1200}>
          <div className={styles.Content}>
            <div className={styles.Header}>
              <div className={styles.Title}>Поиск и сортировка новостей</div>
              <div className={styles.InFavorite}>
                <FavoritesSort
                  sort={bufferedFilters.isFavorite}
                  onChange={value =>
                    handleChangeBufferedFilters({
                      isFavorite: value
                    })
                  }
                  textComponent={<FavoriteText />}
                />
              </div>
            </div>
            <div className={styles.TopFilters}>
              <div className={styles.DatePicker}>
                <div className={styles.DatePicker__Icon}>
                  <CalendarToday />
                </div>
                <span className={styles.DatePicker__Text}>Дата:</span>
                <div className={styles.DatePicker__Range}>
                  <DateRangePicker
                    datePickerProps={{
                      open: showRangePicker,
                      onAccept: () => setShowRangePicker(false),
                      theme: 'secondary',
                      value: [
                        moment(bufferedFilters.dateFrom, 'YYYY-MM-DD').toDate(),
                        moment(bufferedFilters.dateTo, 'YYYY-MM-DD').toDate()
                      ],
                      onChange: ([dateFrom, dateTo]) =>
                        handleChangeBufferedFilters({
                          dateFrom,
                          dateTo
                        }),
                      maxDate: moment()
                        .utc()
                        .format('YYYY-MM-DD'),
                      renderInput: (startProps, endProps) => (
                        <>
                          <TextField
                            {...startProps}
                            theme="secondary"
                            variant="standard"
                            helperText=""
                            onClick={() => setShowRangePicker(prev => !prev)}
                          />
                          <div className={styles.DatePicker__Separator}>—</div>
                          <TextField
                            {...endProps}
                            theme="secondary"
                            variant="standard"
                            helperText=""
                            onClick={() => setShowRangePicker(prev => !prev)}
                          />
                          <div
                            className={styles.DatePicker__DownArrow}
                            onClick={() => setShowRangePicker(prev => !prev)}
                          >
                            <img src={downArrowIcon} alt="" />
                          </div>
                        </>
                      ),
                      autoOk: true
                    }}
                  />
                </div>
                <SortDirection
                  sort={bufferedFilters.sort}
                  onChange={({ type, direction }) =>
                    handleChangeBufferedFilters({
                      sort: {
                        type,
                        direction
                      }
                    })
                  }
                />
              </div>
              <div className={styles.Row}>
                <CheckBox
                  checked={bufferedFilters?.onlyTopSMI}
                  onChange={checked =>
                    handleChangeBufferedFilters({ onlyTopSMI: checked })
                  }
                  label="Только Топ-СМИ"
                />
                <ProccesedMenu
                  onChangeTypeHandler={processed =>
                    handleChangeBufferedFilters({ processed })
                  }
                  type={bufferedFilters.processed}
                />
              </div>
            </div>
            <div className={styles.Grid}>
              <div className={styles.Card}>
                <div className={styles.SubTitle}>Раздел мониторинга</div>
                <div className={styles.Card__Grid}>
                  <CheckBoxes data={monitoring_sections} field="sections" />
                </div>
              </div>
              <div className={styles.Card}>
                <div className={styles.SubTitle}>Тип и регионы мониторинга</div>
                <div className={styles.Card__Grid}>
                  <CheckBoxes data={monitoring_types} field="types" bold />
                  <CheckBoxes
                    data={sortByBranchesQueue(monitoring_regions).map(item => ({
                      ...item,
                      name: item.name.split('макрорегион')[0]
                    }))}
                    field="macroRegions"
                  />
                </div>
              </div>
              <div className={classnames(styles.Card, styles.Card_fullWidth)}>
                <MultiSelect
                  options={defaultRows
                    .map(option => ({
                      label: option.region_name,
                      value: option.region_id,
                      region: option.name
                    }))
                    .sort((a, b) => (a.label > b.label ? 1 : -1))}
                  value={selectedRegions}
                  hasSelectAll={!searchIsActive}
                  overrideStrings={{
                    allItemsAreSelected: 'Все регионы',
                    selectSomeItems: 'Выбор макрорегиона',
                    ...RUS_STRINGS
                  }}
                  onChange={regions => setSelectedRegions(regions)}
                  filterOptions={filterRegions}
                  ItemRenderer={renderItemSelect}
                  labelledBy="Select"
                  disabled={bufferedFilters?.macroRegions?.length === 0}
                />
              </div>
              <div className={classnames(styles.Card, styles.Card_fullWidth)}>
                <div className={styles.SubTitle}>Ключевые слова</div>
                <div className={styles.Search}>
                  <SearchBlock
                    onSubmitHandler={text =>
                      text &&
                      handleChangeBufferedFilters({
                        keyWords: [...bufferedFilters.keyWords, text]
                      })
                    }
                    renderSearchButton={({ onClick, disabled }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        className={styles.PlusButton}
                        onClick={onClick}
                        disabled={disabled}
                      >
                        +
                      </Button>
                    )}
                  />
                </div>
                <Area
                  data={bufferedFilters.keyWords}
                  onRemove={name =>
                    handleChangeBufferedFilters({
                      keyWords: bufferedFilters.keyWords.filter(
                        item => item !== name
                      )
                    })
                  }
                />
              </div>
              {/*
              <div className={styles.Card}>
                <div className={styles.SubTitle}>
                  Теги
                </div>
                <div
                  className={styles.AddTagButton}
                  onClick={() => setShowTagsModal(true)}
                >
                  Добавить теги из справочника
                </div>
                <Area
                  data={bufferedFilters.tags.map(({ title }) => title)}
                  onRemove={title => {
                    handleChangeBufferedFilters({
                      tags: bufferedFilters.tags.filter(tag => tag.title !== title)
                    })
                  }}
                />
              </div>
              */}
            </div>
            <div className={styles.Actions}>
              <Button
                className={classnames(
                  styles.Actions__Button,
                  styles.Actions__Button_removeFilters
                )}
                onClick={() => {
                  setBufferedFilters(defaultFilters);
                  setSelectedRegions(defaultSubRegions);
                }}
              >
                <img src={rubbishIcon} alt="Удалить" />
                <span>СБРОСИТЬ ФИЛЬТРЫ</span>
              </Button>
              <div>
                <Button
                  className={styles.Actions__Button}
                  onClick={handleClose}
                >
                  Отменить
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.Actions__Button}
                  onClick={handleSetFilters}
                  disabled={hasErrors}
                >
                  Найти
                </Button>
              </div>
            </div>
          </div>
        </StyledPaper>
      </Modal>
      <HandbookTagsModal
        isOpenModal={showTagsModal}
        onCloseHandler={() => setShowTagsModal(false)}
        addTagsHandler={tags => {
          handleChangeBufferedFilters({
            tags: [...bufferedFilters.tags, ...tags]
          });
        }}
        activeTags={bufferedFilters.tags}
      />
    </>
  );
};

export default FiltersModal;
