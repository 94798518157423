// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';

// Styles
import styles from './styles.sass';

// Types
type FilterPopoverTypes = {
  id: string;
  closeHandler: () => void;
  classes: CSSRuleList;
  children: Node;
};

const CustomPopover = ({
  closeHandler,
  id,
  classes,
  children
}: FilterPopoverTypes) => {
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    }
  }, []);

  const handleClickOutside = (e) =>  {
    const root = document.getElementById(id);
    const path = e?.path || (e?.composedPath && e.composedPath());

    if (!path.includes(root)) {
      closeHandler();
    }
  };

  return (
    <div
      id={id}
      className={classNames(classes?.root, styles.Root)}
    >
      {children}
    </div>
  )
};

// Exports
export default CustomPopover;
