import * as React from 'react';
import { Tab, Tabs, withStyles } from '@material-ui/core';

export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#00B956',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab =  withStyles((theme) => ({
  root: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '500',
    width: '100%',
    minWidth: 'fit-content',
    maxWidth: 'fit-content',
    '&$selected': {
      color: '#008F42',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
