// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';
import 'moment/locale/ru';

// Components
import Page from '@components/Page';
import { H6 } from '@components/Typography';
import { StyledSubmitButton } from '../Operators/CustomComponents';
import StyledPaper from '@components/StyledPaper';
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from '@material-ui/core';

// Api
import { changePRValue, getPRValues, uploadPRValues } from '../../../api/prValuesApi';

// Constants
import { server } from '@constants/servers';

// Assets
import { ReactComponent as TriangleIcon } from '@assets/triangle.svg';
import arrowLeft from '@assets/arrowLeft.svg';
import { ReactComponent as EditIcon } from '@assets/edit.svg';
import { ArrowDownward, ArrowUpward, Search } from '@material-ui/icons';

// Styles
import styles from './styles.sass';


const HandbookPRValue = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [dense] = React.useState(false);
  const [foundName, setFoundName] = React.useState('');
  const [isOpenModal, setIsOpenModalState] = React.useState(false);
  const [defaultModalData, setDefaultModalData] = React.useState({});
  const [modalData, setModalData] = React.useState({});
  const [defaultRows, setDefaultRows] = React.useState([]);
  const [rows, setRows] = React.useState(defaultRows);
  const [event, setEvent] = React.useState({
    open: false,
    message: null,
    status: null
  });
  const [loading, setLoading] = React.useState(true);

  const fileRef = React.useRef(null);

  const fetchPRValues = () => {
    setLoading(true);
    getPRValues(res => {
      setDefaultRows(res.data);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    fetchPRValues();
  }, [])

  React.useEffect(() => setRows(() =>
    foundName === ''
      ? defaultRows
      : defaultRows.filter(row => row.branch_name.toLowerCase().includes(foundName.toLowerCase()))
  ), [foundName, defaultRows])

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headCells = [
    { id: 'branch_name', disablePadding: false, label: 'Отделение' },
    { id: 'top_value', disablePadding: false, label: 'Топ значение' },
    { id: 'value', disablePadding: false, label: 'Значение' },
    { id: 'edit', disablePadding: false, label: '' },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleSaveModalData = () => {
    const changedFields = {};
    if (modalData.top_value !== defaultModalData.top_value)
      changedFields.top_value = modalData.top_value
    if (modalData.value !== defaultModalData.value)
      changedFields.value = modalData.value

    changePRValue(modalData.id, changedFields, (({ data }) => {
      setDefaultRows(prev => {
        const updatedRows = [...prev];
        const foundIndex = updatedRows.findIndex(row => row.id === data.id);
        updatedRows[foundIndex] = data;
        return updatedRows;
      })
    }));
    setIsOpenModalState(false);
  };

  const handleChangeModalField = (value, fieldName) => {
    setModalData(prev => {
      const newModalData = { ...prev };
      newModalData[fieldName] = value;
      return newModalData;
    });
  };

  return (
    <Page loading={loading} event={event}>
      <Grid
        container
        direction="column"
      >
        <Modal
          open={isOpenModal}
          onClose={() => setIsOpenModalState(false)}
        >
          <StyledPaper>
            <H6 style={{ margin: '0 0 24px 0'}}>Редактирование</H6>
            <TextField
              fullWidth
              variant="outlined"
              label="Отделение"
              type="text"
              value={modalData.branch_name}
              onChange={(e) => handleChangeModalField(e.target.value, 'branch_name')}
              style={{marginBottom: 20}}
              disabled
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Топ значение"
              type="text"
              value={modalData.top_value}
              onChange={(e) => handleChangeModalField(e.target.value, 'top_value')}
              style={{marginBottom: 20}}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Значение"
              type="text"
              value={modalData.value}
              onChange={(e) => handleChangeModalField(e.target.value, 'value')}
              style={{marginBottom: 20}}
            />
            <Grid container xl={12} lg={12} justify="space-between">
              <Button
                size="large"
                onClick={() => setIsOpenModalState(false)}
              >
                Отменить
              </Button>
              <StyledSubmitButton
                variant="contained"
                size="large"
                onClick={() => handleSaveModalData()}
              >
                Сохранить
              </StyledSubmitButton>
            </Grid>
          </StyledPaper>
        </Modal>
        <Container>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            className={styles.SearchBlock}
          >
            <Link to='/handbooks'>
              <div className={styles.Back}>
                <img alt="" src={arrowLeft} />
                <span className={styles.Back__text}>Назад</span>
              </div>
            </Link>
          </Grid>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            alignItems="center"
            className={styles.SearchBlock}
          >
            <Grid item xs={5}>
              <div className={styles.MainTitle}>Справочник — PR-value</div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Поиск"
                style={{
                  width: '100%',
                }}
                variant="outlined"
                icon
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setFoundName(e.target.value)
                  }
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <div className={styles.Buttons}>
                <a href={`${server}/api/v1/pr_values/download`} download>
                  <Button className={styles.Button}>
                    <ArrowDownward />
                  </Button>
                </a>
                <Button
                  className={`${styles.Button} ${styles.Button_purple}`}
                  onClick={() => fileRef.current.click()}
                >
                  <ArrowUpward />
                </Button>
                <input
                  ref={fileRef}
                  name="myFile"
                  type="file"
                  onChange={(e) => {
                    setLoading(true);
                    uploadPRValues(
                      e.target.files[0],
                      () => {
                        fetchPRValues();
                        setEvent({ open: true, message: 'Файл успешно загружен!', status: 'success' });
                      },
                      () => {
                        setLoading(false);
                        setEvent({ open: true, message: 'Не удалось загрузить файл!', status: 'failed' });
                      }
                    )
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </Grid>
          </Grid>
          <div>
            <Paper className={styles.Paper}>
              <TableContainer>
                <Table
                  className={styles.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                  theme='black'
                >
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align='left'
                          padding={headCell.disablePadding ? 'none' : 'default'}
                          sortDirection={orderBy === headCell.id ? order : false}
                          className={styles.Title}
                        >
                          {headCell.id === 'edit' ? (
                            headCell.label
                          ) : (
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={createSortHandler(headCell.id)}
                              IconComponent={() => <TriangleIcon className={styles.SortIcon} style={{transform: order === 'desc' && orderBy === headCell.id && 'rotate(180deg)'}} />}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell align="left">{row.branch_name}</TableCell>
                            <TableCell align="left">
                              {row.top_value}
                            </TableCell>
                            <TableCell align="left">
                              {row.value}
                            </TableCell>
                            <TableCell align="left">
                              <div className={styles.EditIcon}>
                                <EditIcon
                                  fill='#00B956'
                                  onClick={() => {
                                    setDefaultModalData(row);
                                    setModalData(row);
                                    setIsOpenModalState(true);
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Container>
      </Grid>
    </Page>
  )
};

export default HandbookPRValue;
