
const _parseJSON = (response) => response.text().then((text) => text ? JSON.parse(text) : {});

// async
const QueryWrapper = async (query, resolveHandler, rejectHandler) => {
  return await query
    .then(response => _parseJSON(response))
    .then(res => {
      if (res?.errors?.detail === 'Unauthorized' || res?.error === 'wrong token') {
        window.location.href = "/";
        localStorage.clear();
      } else {
        resolveHandler(res);
      }

      return res;
    })
    .catch(err => {
      if (rejectHandler) {
        rejectHandler(err)
      }
    })
}

export default QueryWrapper;
