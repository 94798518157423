// @flow

// Libraries
import * as React from 'react';
import 'moment/locale/ru';

// Components
import { Container, Grid } from '@material-ui/core';
import Page from '@components/Page';
import { Link, useRouteMatch } from 'react-router-dom';

// Styles
import styles from './styles.sass';
import useAuth from '../../hooks/useAuth';

const DirectoryPR = () => {
  const { url } = useRouteMatch();
  const { user: { role } } = useAuth();

  return (
    <Page>
      <Grid
        container
        direction="column"
      >
        <Container>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            className={styles.SearchBlock}
          >
            <button className={styles.Back}>
              <span className={styles.Back__text} />
            </button>
          </Grid>
          <div className={styles.Handbook}>
            <div className={styles.Handbook__title}>Список справочников:</div>
            <div>
              <div className={styles.Handbook__item}>
                <Link exact to={`${url}/operators`}>Операторы</Link>
              </div>
              <div className={styles.Handbook__item}>
                <Link exact to={`${url}/pr_campaign`}>PR-кампании</Link>
              </div>
              {/* {role === 'pr_manager' && ( */}
              {/*  <div className={styles.Handbook__item}> */}
              {/*    <Link exact to={`${url}/pr_value`}>PR Value</Link> */}
              {/*  </div> */}
              {/* )} */}
              <div className={styles.Handbook__item}>
                <Link exact to={`${url}/regions`}>Регионы</Link>
              </div>
              <div className={styles.Handbook__item}>
                <Link exact to={`${url}/smi`}>СМИ</Link>
              </div>
              <div className={styles.Handbook__item}>
                <Link exact to={`${url}/tags`}>Теги</Link>
              </div>
            </div>
          </div>
        </Container>
      </Grid>
    </Page>
  )
};

// Exports
export default DirectoryPR;
