import { ADD_PATH, REMOVE_PATH, CLEAR_PATH } from './types';


const routingsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_PATH: {
      return [
        ...state,
        action.payload
      ]
    }

    case REMOVE_PATH: {
      const countOfSteps = action.payload || 1;

      return [...state].slice(0, -countOfSteps);
    }

    case CLEAR_PATH: {
      return [];
    }

    default: {
      return state;
    }
  }
};

export default routingsReducer;
