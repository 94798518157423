// @flow

// Libraries
import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';

// Components
import Checkbox from '@material-ui/core/Checkbox';
import MultiSelect from 'react-multi-select-component';
import { Button, Input } from '@material-ui/core';
import DateRangePicker from '../DateRangePicker';

// Constants
import { RUS_STRINGS } from '@constants/charts';

// Api
import { addToHistory } from '../../api/chartsApi';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector } from 'react-redux';
import { getToken } from '../../api/articleApi';
import { server } from '@constants/servers';
import styles from './styles.sass';

const ChartControlHeader = ({ settings, handleUpdate }: any) => {
  const {
    monitoring_regions,
    monitoring_sections,
    monitoring_types
  } = useSelector(store => store.monitoring_constants);
  const [changedSettings, setChangedSettings] = React.useState(settings);
  const [searchIsActive, setSearchIsActive] = React.useState(false);
  const [types, setTypes] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [defaultRows, setDefaultRows] = React.useState([]);
  const [selectedRegions, setSelectedRegions] = React.useState([]);

  const fetchRegions = async () => {
    await fetch(`${server}/api/v1/monitoring/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    })
    .then(response => response.json())
    .then(res => {
      res && setDefaultRows(res);
    });
  };

  React.useEffect(() => {
    fetchRegions();
  }, []);

  const handleChangeSettings = updatedSetting =>
    setChangedSettings(prev => ({ ...prev, ...updatedSetting }));
  const handleGenerateReport = () => {
    const newChangedSettings = {
      ...changedSettings,
      regions: regions,
      types: types,
      selectedRegions: selectedRegions.map(sr => sr)
    };

    addToHistory(
      {
        report: {
          params: {
            ...newChangedSettings,
            createdAt: moment().format('YYYY-MM-DD HH:mm'),
            progress: '100',
            name: changedSettings.name
          }
        }
      },
      ({ data: { id } }) => handleUpdate(changedSettings, id)
    );
  };

  const filterRegions = (data, searchString) => {
    setSearchIsActive(Boolean(searchString));
    const regionsSet = new Set(regions.map(region => region.label));
    const lowerCaseSearchString = searchString
      ? searchString.toLowerCase()
      : '';

    return data.reduce((filtered, d) => {
      if (
        regionsSet.has(d.region) &&
        (!searchString || d.label.toLowerCase().includes(lowerCaseSearchString))
      ) {
        filtered.push(d);
      }

      return filtered;
    }, []);
  };

  const handleFilter = (data, searchString) => {
    setSearchIsActive(Boolean(searchString));
    return [...data].filter(item => item.label.includes(searchString));
  };

  const renderItemSelect = ({ checked, option, onClick, disabled }: any) => {
    return (
      <div
        className={`item-renderer ${disabled && 'disabled'}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Checkbox
          color="secondary"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
          style={{ margin: 0, padding: 0, height: 20 }}
        />
        <span>{option.label}</span>
      </div>
    );
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={styles.Settings}>
          <div className={styles.Selector}>
            <Input
              type="text"
              className={styles.Input}
              placeholder="Имя отчета"
              onChange={e => handleChangeSettings({ name: e.target.value })}
            />
          </div>
          <div className={styles.Text}>Период с</div>
          <DateRangePicker
            styles={{
              TextField: styles.TextField
            }}
            datePickerProps={{
              value: [
                moment(changedSettings.dateFrom, 'YYYY-MM-DD').format(
                  'MM.DD.YYYY'
                ),
                moment(changedSettings.dateTo, 'YYYY-MM-DD').format(
                  'MM.DD.YYYY'
                )
              ],
              maxDate: moment()
              .utc()
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
              onChange: ([dateFrom, dateTo]) =>
                handleChangeSettings({
                  dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                  dateTo: moment(dateTo).format('YYYY-MM-DD')
                })
            }}
          />
        </div>
      </div>

      <div className={styles.Wrapper}>
        <div className={styles.SelectBlock}>
          <p className={styles.SubTitleMain}>Типы и регионы мониторинга</p>
          <div className={styles.SelectWrapper}>
            <div className={styles.Select}>
              <MultiSelect
                options={monitoring_types.map(option => ({
                  label: option.name,
                  value: option.id
                })).sort((a, b) => a.label > b.label ? 1 : -1)}
                value={types}
                hasSelectAll={!searchIsActive}
                overrideStrings={{
                  allItemsAreSelected: 'Все',
                  selectSomeItems: 'Выбор макрорегиона',
                  ...RUS_STRINGS
                }}
                onChange={types => {
                  setTypes(types);
                  if (types.find(i => i.label === 'Региональный')) {
                    setRegions(
                      monitoring_regions.map(option => ({
                        label: option.name,
                        value: option.id
                      })).sort((a, b) => a.label > b.label ? 1 : -1)
                    );
                  } else {
                    setRegions([]);
                  }
                }}
                filterOptions={handleFilter}
                ItemRenderer={renderItemSelect}
                labelledBy="Select"
              />
            </div>
            <div className={styles.Select}>
              <MultiSelect
                options={monitoring_regions.map(option => ({
                  label: option.name,
                  value: option.id
                })).sort((a, b) => a.label > b.label ? 1 : -1)}
                value={regions}
                hasSelectAll={!searchIsActive}
                overrideStrings={{
                  allItemsAreSelected: 'Все регионы',
                  selectSomeItems: 'Выбор макрорегиона',
                  ...RUS_STRINGS
                }}
                onChange={regions => {
                  setRegions(regions);
                }}
                filterOptions={handleFilter}
                ItemRenderer={renderItemSelect}
                labelledBy="Select"
                disabled={!types.find(item => item.label === 'Региональный')}
              />
            </div>
            <div className={styles.Select}>
              <MultiSelect
                options={defaultRows.map(option => ({
                  label: option.region_name,
                  value: option.region_id,
                  region: option.name
                })).sort((a, b) => a.label > b.label ? 1 : -1)}
                value={selectedRegions}
                hasSelectAll={!searchIsActive}
                overrideStrings={{
                  allItemsAreSelected: 'Все регионы',
                  selectSomeItems: 'Выбор макрорегиона',
                  ...RUS_STRINGS
                }}
                onChange={regions => setSelectedRegions(regions)}
                filterOptions={filterRegions}
                ItemRenderer={renderItemSelect}
                labelledBy="Select"
                disabled={regions.length === 0}
              />
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleGenerateReport}
              className={styles.SubmitBtn}
              disabled={
                (types.find(item => item.label === 'Федеральный') && !types.find(item => item.label === 'Региональный'))
                  ? false
                  : types.length === 0 || regions.length === 0 || selectedRegions.length === 0
              }
            >
              Сформировать
            </Button>
          </div>
        </div>

        {/* TODO: раскоментировать, если понадобится фильтрация по операторам  */}
        {/* <div className={styles.SelectBlock}>  */}
        {/*  <p className={styles.SubTitleMain}>Раздел мониторинга</p>  */}
        {/*  <MultiSelect  */}
        {/*    options={monitoring_sections.map(option => ({  */}
        {/*      label: option.name,  */}
        {/*      value: option.id  */}
        {/*    }))}  */}
        {/*    value={changedSettings.operators}  */}
        {/*    hasSelectAll={!searchIsActive}  */}
        {/*    overrideStrings={{ allItemsAreSelected: 'Все разделы', selectSomeItems: 'Выбор макрорегиона', ...RUS_STRINGS }}  */}
        {/*    onChange={operators => handleChangeSettings({ operators })}  */}
        {/*    filterOptions={handleFilter}  */}
        {/*    ItemRenderer={renderItemSelect}  */}
        {/*    labelledBy="Select"  */}
        {/*  />  */}
        {/* </div>  */}
      </div>
    </div>
  );
};

export default ChartControlHeader;
