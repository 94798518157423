// @flow

// Libraries
import * as React from 'react';

// Components
import { Button, TextField, CircularProgress } from '@material-ui/core';
import UploadButton from '../../../../components/UploadButton/index';
// Styles
import styles from './styles.sass';
import Loader from '../../../../components/Loader';

// Types
type EditModeFormTypes = {
  articleData: {
    monitoring_section: number
  };
  handleCancel: () => void;
  handleChange: () => void;
  handleSave: () => void;
};

const EditModeForm = ({
  articleData,
  handleCancel,
  handleChange,
  handleSave
}: EditModeFormTypes) => {
  const [editData, setEditData] = React.useState(articleData);

  React.useEffect(() => {
    handleChange(editData);
  }, [editData]);

  const handleEditFieldChange = (value) => {
    setEditData(data => ({ ...data, ...value }));
  };

  /*

  const operator_from_section = React.useMemo(() => OPERATORS.find(
    ({ key }) => articleData?.monitoring_section === key
  ), [articleData]);

 const wordsNeedsOfHighlight = React.useMemo(() => {
    const words = dictionary[operator_from_section?.id];

    return words
      ? words
        .split('\n')
        .map((word) => {
          const trimmedWord = word.trim();
          if (trimmedWord) {
            return [...symbols, [[], []]].map(
              ([startSymbol, endSymbol]) =>
                `${startSymbol}${trimmedWord}${endSymbol}`
            );
          }

          return null;
        })
        .filter((word) => word)
        .flat()
      : [];
  }, [operator_from_section]); */

  const uploadFileHandler = ({ target: { files } }): void => {
    handleEditFieldChange({video: files[0]});
  }

  return (
    <div className={styles.Edit}>
      <div className={styles.Edit__Title}>Редактирование</div>
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Издательство:</div>
        <TextField
          placeholder="Издание"
          value={editData.source}
          onChange={({ target: { value }}) => handleEditFieldChange({ source: value })}
          classes={{root: styles.Edit__Form}}
        />
      </div>
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Главный заголовок:</div>
        <TextField
          placeholder="Главный заголовок"
          value={editData.title}
          onChange={({ target: { value }}) => handleEditFieldChange({ title: value })}
          classes={{root: styles.Edit__Form}}
        />
      </div>
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Превью:</div>
        <TextField
          placeholder="Ссылка на превью"
          value={editData.image}
          onChange={({ target: { value }}) => handleEditFieldChange({ image: value })}
          classes={{root: styles.Edit__Form}}
          multiline
        />
      </div>
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Ссылка на видео:</div>
        <TextField
          placeholder="Ссылка на видео"
          value={editData.video_url}
          onChange={({ target: { value }}) => handleEditFieldChange({ video_url: value })}
          classes={{root: styles.Edit__Form}}
          multiline
        />
      </div>
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Загрузка видео:</div>
        <UploadButton title='Выбрать файл' onChange={uploadFileHandler} />
      </div>
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Второй заголовок:</div>
        <TextField
          placeholder="Второй заголовок"
          value={editData.preview}
          onChange={({ target: { value }}) => handleEditFieldChange({ preview: value })}
          classes={{root: styles.Edit__Form}}
          multiline
        />
      </div>
      {/*
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Контент:</div>
        <Highlighted
          text={editData.text}
          highlight={wordsNeedsOfHighlight}
          editable
          onChange={({ target: { value }}) => handleEditFieldChange({ text: value })}
        />
      </div>
      */}
      <div className={styles.Edit__Item}>
        <div className={styles.Edit__SubTitle}>Редактирование контента:</div>
        <TextField
          placeholder="Контент"
          value={editData.text}
          onChange={({ target: { value }}) => handleEditFieldChange({ text: value })}
          classes={{root: styles.Edit__Form}}
          multiline
        />
      </div>
      <div className={styles.Edit__Actions}>
        <Button
          classes={{root: styles.Edit__Button}}
          onClick={() => handleCancel()}
        >
          Отменить
        </Button>
        <Button
          classes={{root: styles.Edit__Button}}
          color="access"
          style={{
            background: '#00B956',
            color: '#ffffff',
          }}
          onClick={() => handleSave(editData)}
        >
          Сохранить
        </Button>
      </div>
    </div>
  )
};

// Exports
export default EditModeForm;
