// @flow

// Libraries
import moment from 'moment';
import 'moment/locale/ru';

// Constants
import { server } from '@constants/servers';
import QueryWrapper from './QueryWrapper';
import store from '../store/store';
import { getToken } from './articleApi';

// Токен пользователя
const {
  user: {
    token
  },
} = store.getState();

// Создание кампании
export const createCampaign = (newCampaign, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/pr_campaigns`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        ...newCampaign,
        date: moment(newCampaign.date).utc().format("YYYY-MM-DDT00:00:00"),
      })
    }),
    resolveHandler,
    rejectHandler
  )
};

// Получение кампаний
export const getCampaign = (resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/pr_campaigns`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

// Редактирование кампании
export const editCampaign = (campaignForEditing, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/pr_campaigns/${campaignForEditing.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        branch_ids: campaignForEditing.branch_ids,
        campaign_type: campaignForEditing.campaign_type,
        date: moment(campaignForEditing.date).utc().format("YYYY-MM-DDT00:00:00"),
        deleted_at: campaignForEditing.deleted_at,
        name: campaignForEditing.name,
        operator_names: campaignForEditing.operator_names,
        theme: campaignForEditing.theme_enabled ? campaignForEditing.theme : null,
        theme_id: campaignForEditing.theme_enabled ? campaignForEditing.theme_id : null,
        theme_enabled: campaignForEditing.theme_enabled,
      })
    }),
    resolveHandler,
    rejectHandler
  )
};

// Удаление кампании
export const deleteCampaign = (campaignForDeleting, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/pr_campaigns/${campaignForDeleting.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
    }),
    resolveHandler,
    rejectHandler
  )
};

// Поиск кампаний
export const searchСampaigns = async (query, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/pr_campaigns?pr_campaign_query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export default { getCampaign };
