// @flow

// Libraries
import moment from 'moment';

// Components
import QueryWrapper from './QueryWrapper';

// Constants
import { server } from '@constants/servers';

import store from '../store/store';
import { joinedGetParameters } from '../methods/anyMethods';
import { COUNT_ARTICLES_IN_ONE_PAGE } from '../pages/Main';
import defaultRegions from "../constants/defaultRegions";

const {
  user: { id: userID, monitoring_sections: userSections },
  monitoring_constants: { monitoring_sections: groups }
} = store.getState();

export const getToken = () => store.getState()?.user?.token;

const ARTICLES_LIMIT = 1000;

// Получение статей для редактора новостей
export const getEditorArticles = (resolveHandler, rejectHandler = () => {}) => {
  QueryWrapper(
    fetch(
      `${server}/api/v1/monitoring/posts?parents_only=true&sort[][id]=desc`,
      {
        method: 'GET',
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${getToken()}`
        }
      }
    ),
    resolveHandler,
    rejectHandler
  );
};

// Получение вложенных статей
export const getChildArticles = async (
  id,
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/${id}/children`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Фильтрация статей для редактора по алфавиту
export const filterArticles = (
  type,
  resolveHandler,
  rejectHandler = () => {}
) => {
  QueryWrapper(
    fetch(
      `${server}/api/v1/monitoring/posts?parents_only=true&sort=title&order=${type}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${getToken()}`
        }
      }
    ),
    resolveHandler,
    rejectHandler
  );
};

// Получение групп статей с вложенными статьями для мониторинга
// eslint-disable
export const getMonitoringArticles = (
  day,
  type,
  status = 'published',
  newUserSections,
  monitoringRegions = [],
  resolveHandler,
  rejectHandler = () => {}
) => {
  const filters = newUserSections || userSections || groups.map(({ id }) => id);

  const regions =
    type !== 'federal' && monitoringRegions.length !== 0
      ? monitoringRegions.map(({ id }) => id)
      : '';

  const monitoringDate =
    day.split('-').length > 1
      ? day.split('T')[0]
      : day === 'released'
      ? moment()
          .utc()
          .format('YYYY-MM-DD')
      : moment()
          .add(1, 'days')
          .utc()
          .format('YYYY-MM-DD');
  const monitoringType =
    type === 'notype' ? '' : type === 'federal' ? '3' : '2';

  const joinedParams = joinedGetParameters({
    'filters[]': filters,
    'monitoring_regions[]': regions,
    monitoring_date: status !== 'in_process' ? monitoringDate : null,
    monitoring_type: monitoringType,
    limit: ARTICLES_LIMIT,
    status
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/home?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Получение статей по конкретной категории (МТС, Мегафон и т.д.)
export const getArticles = async (
  data,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const monitoringDate =
    data.date === null
      ? null
      : !data.customDate
      ? data.date === 'released'
        ? moment()
            .utc()
            .format('YYYY-MM-DD')
        : moment()
            .add(1, 'days')
            .utc()
            .format('YYYY-MM-DD')
      : data.date;

  const joinedParams = joinedGetParameters({
    'filters[]': data.operator,
    monitoring_date: monitoringDate,
    monitoring_type: data.type,
    limit: 10000,
    status: data.status
  });

  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/home?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Получение статьи
export const getArticle = async (
  postID,
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/${postID}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Редактирование статьи
export const editArticle = async (
  articleData,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const formData = new FormData();

  // eslint-disable-next-line guard-for-in
  for (const property in articleData.editedArticle) {
    if (articleData.editedArticle[property] === null) {
      formData.append(property, '');
    } else {
      formData.append(property, articleData.editedArticle[property]);
    }
  }

  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/${articleData.articleID}`, {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Отправка статьи в мониторинг
export const sendToMonitoring = async (
  articleData,
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/${articleData.postID}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        monitoring_region: articleData.params.region,
        monitoring_section: articleData.params.section,
        monitoring_type: articleData.params.type,
        monitoring_date: moment()
          .add(1, 'days')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
        processed: true
      })
    }),
    resolveHandler,
    rejectHandler
  );
};

// Вложение статьи в статью
export const dragNode = async (
  nodes,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const requestBody =
    nodes.parentNode && nodes.childNode
      ? [
          { id: nodes.parentNode.id, posts_count: 1 },
          { id: nodes.childNode.id, post_id: nodes.parentNode.id }
        ]
      : [
          { id: nodes.childNode.post_id, posts_count: 0 },
          { id: nodes.childNode.id, post_id: null }
        ];

  const joinedParams = joinedGetParameters({
    'sort[][id]': 'asc'
  });

  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts?${joinedParams}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        collection: 'posts',
        posts: requestBody
      })
    }),
    resolveHandler,
    rejectHandler
  );
};

// Работа с набором статей (апдейт)
export const updateArticles = async (
  updateArticlesData,
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        collection: 'posts',
        posts: updateArticlesData
      })
    }),
    resolveHandler,
    rejectHandler
  );
};

// Получение закладок пользователя
export const getFavoriteArticles = async (
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/favorites`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Добавление статьи в закладки
export const bookmarkingArticle = async (
  postID,
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_favorites`, {
      method: 'POST',
      body: JSON.stringify({
        user_id: userID,
        post_id: postID
      }),
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Удаление статьи из закладок
export const removeFromBookmarks = async (
  postID,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const joinedParams = joinedGetParameters({
    post_id: postID,
    user_id: userID
  });

  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts_favorites?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    res => {
      QueryWrapper(
        fetch(`${server}/api/v1/monitoring/posts_favorites/${res[0].id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': `application/json`,
            Authorization: `Bearer ${getToken()}`
          }
        }),
        resolveHandler,
        rejectHandler
      );
    },
    rejectHandler
  );
};

// Поиск статей для конкретной категории
export const searchArticlesInCategory = async (
  categoryInfo,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const monitoringDate =
    categoryInfo.date === 'released'
      ? moment()
          .utc()
          .format('YYYY-MM-DD')
      : moment()
          .add(1, 'days')
          .utc()
          .format('YYYY-MM-DD');

  const joinedParams = joinedGetParameters({
    'filters[]': categoryInfo.key,
    title: categoryInfo.title,
    monitoring_date: monitoringDate,
    status: categoryInfo?.status
  });

  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/home?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Поиск статей по мониторингу
export const searchArticlesInMonitoring = async (
  monitoringInfo,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const filters = groups.map(({ id }) => id);

  const monitoringType =
    monitoringInfo.type === 'notype'
      ? ''
      : monitoringInfo.type === 'federal'
      ? '3'
      : '2';

  const joinedParams = joinedGetParameters({
    monitoring_date: monitoringInfo.filterDate,
    monitoring_type: monitoringType,
    title: monitoringInfo.title,
    status: monitoringInfo.status,
    filters
  });

  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/home?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Поиск статей по закладкам
export const searchFavouriteArticles = async (
  title,
  resolveHandler,
  rejectHandler = () => {}
) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/favorites?title=${title}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

export const fetchArticles = async (data, resolveHandler, rejectHandler) => {
  const { date, type, regions, sections, status, words } = data;

  const joinedParams = joinedGetParameters({
    'monitoring_regions[]':
      regions && regions.length !== 0 && regions.map(({ id }) => id),
    'filters[]': sections && sections.length !== 0 && sections,
    monitoring_date: status !== 'in_process' ? date : null,
    monitoring_type: type || '',
    status,
    title: words.length > 0 ? words : null,
    limit: ARTICLES_LIMIT
  });
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/home?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Фильтрация статей в мониторинге по дате
// в будущем нужно передавать объект с датой вместо кучи полей...
// eslint-disable-next-line max-params
export const filterArticlesInMonitoring = async (
  monitoringDate,
  type,
  status = 'published',
  monitoringRegions = [],
  resolveHandler,
  rejectHandler = () => {}
) => {
  const filters = groups.map(item => `filters[]=${item.id}`).join('&');
  const monitoringType =
    type === 'notype' ? '' : type === 'federal' ? '3' : '2';

  const regions =
    type !== 'federal' && monitoringRegions.length !== 0
      ? monitoringRegions.map(({ id }) => id)
      : '';

  const joinedParams = joinedGetParameters({
    'filters[]': filters,
    'monitoring_regions[]': regions,
    monitoring_date: monitoringDate,
    monitoring_type: monitoringType,
    limit: ARTICLES_LIMIT,
    status
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/home?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Поиск статей для редактора
export const searchEditorArticles = async (
  title,
  resolveHandler,
  rejectHandler = () => {}
) => {
  QueryWrapper(
    fetch(
      `${server}/api/v1/monitoring/posts?parents_only=true&sort[][id]=asc&title=${title}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${getToken()}`
        }
      }
    ),
    resolveHandler,
    rejectHandler
  );
};

// Фильтрация новостей в редакторе по "обработанные \ необработанные"
export const filterProccesedEditorArticles = async (
  filterType,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const url = () => {
    switch (filterType) {
      case 'all':
        return `${server}/api/v1/monitoring/posts?parents_only=true&sort[][id]=asc`;
      case 'proccesed':
        return `${server}/api/v1/monitoring/posts?parents_only=true&sort[][id]=asc&processed=true`;
      case 'no-proccesed':
        return `${server}/api/v1/monitoring/posts?parents_only=true&sort[][id]=asc&processed=false`;
      default:
        return `${server}/api/v1/monitoring/posts?parents_only=true&sort[][id]=asc`;
    }
  };

  QueryWrapper(
    fetch(url(), {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Фильтрация статей в редакторе по дате
export const filterDateEditorArticles = async (
  date,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const joinedParams = joinedGetParameters({
    'sort[][id]': 'asc',
    parents_only: true,
    date: moment(date)
      .utc()
      .format('YYYY-MM-DD')
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

const transformIsFavorite = isFavorite => {
  if (isFavorite === 'all') {
    return null;
  }

  return isFavorite === 'only_favorites';
};

const transformProcessed = processed => {
  if (processed === 'all') {
    return null;
  }

  return processed === 'proccesed';
};

// eslint-disable-next-line max-params
export const globalFilterArticles = (filters, page) => {
  const newFilters = {
    date: {
      to: moment(filters.dateTo).format('YYYY-MM-DD'),
      from: moment(filters.dateFrom).format('YYYY-MM-DD')
    },
    is_favorite: transformIsFavorite(filters.isFavorite),
    words: filters.keyWords,
    processed: transformProcessed(filters.processed),
    monitoring_types: filters.types.map(t => t.id),
    monitoring_sections: filters.sections.map(r => r.key),
    tags: filters.tags,
    pr_campaigns: filters.pr_campaigns,
    sort: filters.sort.type,
    order: filters.sort.direction,
    regions: filters.regions.map(r => r.value),
    parents_only: filters.parents_only,
    top_smi: filters.onlyTopSMI
  };

  if (newFilters.processed === 'all') {
    delete newFilters['processed'];
  }

  if (newFilters.regions.length === 0 && (newFilters.monitoring_types.includes('2') || newFilters.monitoring_types.includes(2))) {
    newFilters['regions'] = defaultRegions.map(r => r.value);
  }

  if (newFilters.is_favorite === null) {
    delete newFilters['is_favorite'];
  }

  if (newFilters.processed === null) {
    delete newFilters['processed'];
  }

  return fetch(`${server}/api/v1/monitoring/posts`, {
    method: 'POST',
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${store.getState()?.user?.token}`
    },
    body: JSON.stringify({
      ...newFilters,
      page
    })
  }).then(res => res.json());
};

// Фильтрация статей в редакторе через чекбокс
export const filterCheckboxEditorArticles = async (
  data,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const joinedParams = joinedGetParameters({
    'sort[][id]': 'asc',
    'monitoring_regions[]': data.regions,
    'monitoring_sections[]': data.section,
    parents_only: true,
    monitoring_type: data.type
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  );
};

// Запуск рассылки письма со статьями
export const sendEmailsForUsers = async (
  type,
  resolveHandler,
  rejectHandler = () => {}
) => {
  const joinedParams = joinedGetParameters({
    monitoring_type: encodeURIComponent(type)
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring_emails?${joinedParams}`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    }),
    resolveHandler({
      message: 'Письма отправлены',
      status: 'success'
    }),
    rejectHandler({
      message: 'Письма не отправлены',
      status: 'error'
    })
  );
};

// Отправка мониторинга на дату
// eslint-disable-next-line max-params
export const sendToMonitoringDate = async (
  monitoring_type,
  monitoring_date,
  resolveHandler,
  rejectHandler = () => {}
) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/posts/publish`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      },
      body: JSON.stringify({ monitoring_type, monitoring_date })
    }),
    resolveHandler,
    rejectHandler
  );
};

export default { getArticle };
