/* eslint react/prop-types: 0 */

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';

// Components
import Settings from '../Settings';
import { Container, Grid } from '@material-ui/core';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

// Assets
import SettingsIcon from '@material-ui/icons/Settings';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';

// Styles
import styles from './styles.sass';


const Icons = {
  'settings': SettingsIcon
};

type ItemTypes = {
  link: string;
  title: string;
  subItems: Array<{
    link: string;
    title: string;
  }>
}

type ControlPanelPropTypes = {
  changeTypeNewsHandler?: () => void;
  items: Array<ItemTypes>,
};


// надо в будущем это переделать на фулл редакс, а то муть какая-то
const TYPES_MAP = {
  3: {
    id: 3,
    link: '#',
    title: 'Федеральные'
  },
  2: {
    id: 2,
    link: '#',
    title: 'Региональные'
  }
}


const ControlPanel = ({
  items = [],
  changeTypeNewsHandler = () => {},
}: ControlPanelPropTypes) => {
  const dispatch = useDispatch();
  const { user_monitoring_settings } = useSelector(store => store);
  const [anchorSettings, setAnchorSettings] = React.useState(null);
  const [newsType, setNewsType] = React.useState(TYPES_MAP[user_monitoring_settings.monitoring_type]);
  const [dropDownsData, setDropDowns] = React.useState(items.reduce((data, item) => {
    if (item.hasOwnProperty('subItems')) {
      return {
        ...data,
        [item.title]: false
      }
    }

    return data
  }, {}));

  const screenIsMedium = useMediaQuery('(max-width:991px)');

  React.useEffect(() => {
    if (user_monitoring_settings?.monitoring_type) {
      setNewsType(TYPES_MAP[user_monitoring_settings?.monitoring_type]);
    }
  }, [user_monitoring_settings]);

  React.useEffect(() => {
    changeTypeNewsHandler(newsType.id);
  }, [newsType]);

  const DefaultTab = ({ tab }: DefaultTabTypes) => {
    const IconComponent = () => {
      const Icon = Icons[tab?.icon];
      return <Icon style={{marginRight: 10}} />;
    };

    const Content = () => (
      <>
        {tab?.icon && <IconComponent />}
        <span>
          {tab.title}
        </span>
      </>
    );

    return (
      <>
        {tab?.isSettings ? (
          <div>
            <div
              className={styles.Item}
              onClick={(e) => setAnchorSettings(e.currentTarget)}
            >
              <Content />
            </div>
            <Settings
              anchorSettings={anchorSettings}
              setAnchorSettings={setAnchorSettings}
            />
          </div>
        ) : (
          <a href={tab.link} className={styles.Item}>
            <Content />
          </a>
        )}
      </>
    )
  };

  const MainTabs = () => {
    return items.map((tab): any => {
      if (tab.hasOwnProperty('subItems')) {
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={styles.Item}
            onClick={() => setDropDowns(prev => ({ ...prev, [tab.title]: !prev[tab.title] }))}
          >
            <div
              className={`
                ${styles.DropDown__title}
                ${dropDownsData[tab.title] && styles.DropDown__title_open}
              `}
            >
              {!screenIsMedium && (
                <>
                  Выбранный тип новостей:
                  &nbsp;
                </>
              )}
              {newsType.title}
            </div>
            {dropDownsData[tab.title] && (
              <div className={styles.Menu}>
                {tab.subItems.map(subItem => (
                  <Link
                    to={{
                      pathname: "/",
                    }}
                    onClick={() => dispatch({ type: 'CHANGE_USER_MONITORING_SETTINGS', payload: { monitoring_type: subItem.id } })}
                  >
                    {subItem.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        )
      }

      return <DefaultTab tab={tab} />;
    })
  };

  const Tabs = React.useCallback(() => (
    <div className={styles.Tabs}>
      <MainTabs />
      <div className={styles.MobileMenu}>
        <Link
          to='/bookmarks'
          className={styles.MobileMenu__item}
        >
          <BookmarkIcon outlined />
        </Link>
      </div>
    </div>
  ), [anchorSettings, dropDownsData, user_monitoring_settings, newsType]);

  return (
    <Grid container className={styles.Root}>
      <Container>
        <Tabs />
      </Container>
    </Grid>
  )
};

export default ControlPanel;
