// @flow

// Libraries
import React from 'react';
import _ from 'lodash';

// Components
import { Chart, CommonSeriesSettings, Format, Label, Legend, Series, ValueAxis } from 'devextreme-react/chart';

// Methods
import { divider } from '../../../../../methods/chartsMethods';

// Types
import { BarChart as Types } from '../../types';
import { divideGraphsValue } from '../../../../../utils/methods';


const BarChart = ({
  dataSource,
  series: fullSeries,
  row,
  title,
  animation,
  sortDirection = 'desc',
  type = 'bar',
  compact = false
}: Types) => {
  const maxValue = _.max(dataSource.map(row => {
    const updatedRow = { ...row };
    delete updatedRow.label;
    return _.max(Object.values(updatedRow));
  }));

  const series = fullSeries.filter(({ key }) => dataSource.some(item => Object.keys(item).includes(key)));

  const divider_numbers = maxValue >= 1_000_000
    ? 1_000_000
    : maxValue > 1000
      ? 1000
      : 1;

  const MAX_SYMBOLS_LENGTH_OF_LABELS = 90;

  // Эта библа переворачивает входной массив, поэтому нужно сортировать в обратном порядке от нужного
  const getComparator = (a, b) => sortDirection === 'asc' ? b - a : a - b;

  const sort = dataSource => sortDirection === 'none'
    ? dataSource
    : [...dataSource].sort((a, b) => getComparator(
      Object.entries(a).find(([key, _]) => key !== 'label')[1],
      Object.entries(b).find(([key, _]) => key !== 'label')[1]
    ))

  const preparedDataSource = React.useMemo(() => sort(dataSource)
    .map((row, i) => ({
      ...row,
      label: row.label.length - 3 > MAX_SYMBOLS_LENGTH_OF_LABELS
        ? `${row.label.substring(0, MAX_SYMBOLS_LENGTH_OF_LABELS)}...`
        : row.label === ''
          ? new Array(i).fill(' ').join('')
          : row.label
    }
  )), [dataSource]);

  const formatter = text => {
    const number = Number(text);
    if (divider_numbers === 1_000_000) {
      return `${_.round((number / divider_numbers), 1).toString().replaceAll('.', ',')} ${!compact ? 'млн' : ''}`;
    }

    return number.toLocaleString();
  }

  return (
    <Chart
      id="chart"
      dataSource={preparedDataSource}
      rotated={row}
      palette={series.map(({ color}) => color)}
      title={title}
      customizeLabel={labelInfo => {
        const color = series.find(({ name }) => name === labelInfo.seriesName)?.color;

        return {
          backgroundColor: 'transparent',
          font: {
            size: compact ? 10 : 14,
            color: color || 'orange',
            marginLeft: -10
          }
        }
      }}
      animation={animation}
    >
      <CommonSeriesSettings
        argumentField='label'
        hoverMode="nearestPoint"
        selectionMode="allArgumentPoints"
        type={type}
        position='outside'
      >
        <Label visible position="columns">
          <Format
            formatter={formatter}
          />
        </Label>
      </CommonSeriesSettings>
      <ValueAxis
        maxValueMargin={0.3}
        position="left"
        label={{
          customizeText: ({ value }) => divideGraphsValue(value)
        }}
      />
      {series.map(({ key, name }) => <Series key={key} valueField={key} name={name} />)}
      <Legend
        orientation="horizontal"
        itemTextPosition="right"
        horizontalAlignment="center"
        verticalAlignment="bottom"
        columnCount={12}
      />
    </Chart>
  );
}

export default BarChart;
