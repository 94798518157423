// @flow

export const isHoliday = (date: Date): boolean => {
  // список праздников, которые нужно исключить из выбора
  const holidays = [
    new Date(date.getFullYear(), 0, 1), // Новый год
    new Date(date.getFullYear(), 0, 7), // Рождество Христово (православное)
    new Date(date.getFullYear(), 1, 23), // День мужика
    new Date(date.getFullYear(), 2, 8), // Международный женский день
    new Date(date.getFullYear(), 4, 1), // Праздник Весны и Труда
    new Date(date.getFullYear(), 4, 9), // День Победы
    new Date(date.getFullYear(), 5, 12), // День России
    new Date(date.getFullYear(), 10, 4), // День народного единства
  ];

  // проверяем, есть ли выбранная дата в списке праздников
  return holidays.some((holiday) => date.getTime() === holiday.getTime());
}
