
export const dictionary = {
  'megafon': `
    МегаФону
    МегаФоне
    МегаФоном
    МегаФона
    МегаФон
    Мегафону
    Мегафоне
    Мегафоном
    Мегафона
    Мегафон
    мегафону
    мегафоне
    мегафоном
    мегафона
    мегафон
    MegaFon
    Megafon
    megafon
    Мегафон Ритейл
    Мегафон Ритейлу
    Мегафон Ритейле
    Мегафон Ритейлом
    Мегафон Ритейла
    Мегафон-Ритейл
    Мегафон-Ритейлу
    Мегафон-Ритейле
    Мегафон-Ритейлом
    Мегафон-Ритейла
    МегаФон Ритейл
    МегаФон Ритейлу
    МегаФон Ритейле
    МегаФон Ритейлом
    МегаФон Ритейла
    МегаФон-Ритейл
    МегаФон-Ритейлу
    МегаФон-Ритейле
    МегаФон-Ритейлом
    МегаФон-Ритейла
    МегаФон ритейл
    МегаФон ритейлу
    МегаФон ритейле
    МегаФон ритейлом
    МегаФон ритейла
    МегаФон-ритейл
    МегаФон-ритейлу
    МегаФон-ритейле
    МегаФон-ритейлом
    МегаФон-ритейла
    Мегафон ритейл
    Мегафон ритейлу
    Мегафон ритейле
    Мегафон ритейлом
    Мегафон ритейла
    Мегафон-ритейл
    Мегафон-ритейлу
    Мегафон-ритейле
    Мегафон-ритейлом
    Мегафон-ритейла
    МегаФон ТВ
    Мегафон ТВ
    МегаФонТВ
    МегафонТВ
    megafon.ru
    МегаФон Плюс
    МегаФон Плюсу
    МегаФон Плюсе
    МегаФон Плюсы
    МегаФон Плюса
    МегаФон Плюсом
    Мегафон Плюс
    Мегафон Плюсу
    Мегафон Плюсе
    Мегафон Плюсы
    Мегафон Плюса
    Мегафон Плюсом
    МегаФон плюс
    МегаФон плюсу
    МегаФон плюсе
    МегаФон плюсы
    МегаФон плюса
    МегаФон плюсом
    Мегафон плюс
    Мегафон плюсу
    Мегафон плюсе
    Мегафон плюсы
    Мегафон плюса
    Мегафон плюсом
    Мегафон 1440
    Мегафон1440
  `,
  'mts': `
    МТС
    МТСу
    МТСе
    МТСа
    МТСом
    мтс
    мтсу
    мтсе
    мтса
    мтсом
    MTS
    mts
    Мобильные ТелеСистемы
    Мобильным ТелеCистемам
    Мобильных ТелеСистем
    Мобильными ТелеСистемами
    Мобильных ТелеСистемах
    Мобильные Телесистемы
    Мобильным ТелеCистемам
    Мобильных ТелеСистем
    Мобильными ТелеСистемами
    Мобильных ТелеСистемах
    Мобильные телесистемы
    Мобильным телесистемам
    Мобильных телесистем
    Мобильными телесистемами
    Мобильных телесистемах
    мобильные телесистемы
    мобильным телесистемам
    мобильных телесистем
    мобильными телесистемами
    мобильных телесистемах
    KION
    mts.ru
    МТС Premium
    МТС premium
    МТС Премиум
    МТС Премиума
    МТС Премиуму
    МТС Премиумом
    МТС Премиуме
    МТС премиум
    МТС премиума
    МТС премиуму
    МТС премиумом
    МТС премиуме
    МТС ТВ
  `,
  'tele2': `
    Tele2
    Tele 2
    tele2
    tele 2
    Теле2
    Теле 2
    теле2
    теле 2
    Tele2 Россия
    tele2.ru
    Т2 РТК Холдинг
    Т2 РТК Холдинга
    Т2 РТК Холдингу
    Т2 РТК Холдинге
    Т2 РТК Холдингом
    Т2 Холдинг
    Т2 Холдинга
    Т2 Холдингу
    Т2 Холдинге
    Т2 Холдингом
    Т2 Мобайл
    Т2 Мобайлу
    Т2 Мобайле
    Т2 Мобайла
    Т2 Мобайлом
  `,
  'beeline': `
    Билайн
    Билайна
    Билайну
    Билайном
    Билайне
    билайн
    билайна
    билайну
    билайном
    билайне
    БиЛайн
    БиЛайна
    БиЛайну
    БиЛайном
    БиЛайне
    Beeline
    beeline
    beeline.ru
    Вымпелком
    Вымпелком
    Вымпелкома
    Вымпелкому
    Вымпелкомом
    Вымпелкоме
    ВымпелКом
    ВымпелКом
    ВымпелКома
    ВымпелКому
    ВымпелКомом
    ВымпелКоме
    вымпелком
    вымпелкома
    вымпелкому
    вымпелкомом
    вымпелкоме
    Vimpelcom
    VimpelCom
    Вымпел-Коммуникации
    Вымпел-Коммуникаций
    Вымпел-Коммуникациям
    Вымпел-Коммуникациями
    Вымпел-Коммуникациях
    Вымпел Коммуникации
    Вымпел Коммуникаций
    Вымпел Коммуникациям
    Вымпел Коммуникациями
    Вымпел Коммуникациях
    Вымпел-коммуникации
    Вымпел-коммуникаций
    Вымпел-коммуникациям
    Вымпел-коммуникациями
    Вымпел-коммуникациях
    Вымпел коммуникации
    Вымпел коммуникаций
    Вымпел коммуникациям
    Вымпел коммуникациями
    Вымпел коммуникациях
    ВымпелКоммуникации
    ВымпелКоммуникаций
    ВымпелКоммуникациям
    ВымпелКоммуникациями
    ВымпелКоммуникациях
    Вымпелкоммуникации
    Вымпелкоммуникаций
    Вымпелкоммуникациям
    Вымпелкоммуникациями
    Вымпелкоммуникациях
    Veon
    veon
    Веон
    Веона
    Веону
    Веоном
    Веоне
    веон
    веона
    веону
    веоном
    веона
    Билайн ТВ
    БилайнТВ
  `,
  'yota': `
    Yota
    yota
    Йота
    Йоту
    Йоте
    Йоты
    Йотой
    йота
    йоту
    йоте
    йоты
    йотой
    yota.ru
    Скартел
    Скартелу
    Скартеле
    Скартела
    Скартелом
    скартел
    скартелу
    скартеле
    скартела
    скартелом
    NetByNet
    Netbynet
    netbynet
    NBN
    nbn
    WiFire
    WIFIRE
    wifire
    Нет Бай Нет
    Нет Бай Нета
    Нет Бай Нете
    Нет Бай Нету
    Нет Бай Нетом
    Нет бай нет
    Нет бай нета
    Нет бай нете
    Нет бай нету
    Нет бай нетом
    нет бай нет
    нет бай нета
    нет бай нете
    нет бай нету
    нет бай нетом
    НетБайНет
    НетБайНета
    НетБайНете
    НетБайНету
    НетБайНетом
    Нетбайнет
    Нетбайнета
    Нетбайнете
    Нетбайнету
    Нетбайнетом
    нетбайнет
    нетбайнета
    нетбайнете
    нетбайнету
    нетбайнетом
    Нэт Бай Нэт
    Нэт Бай Нэта
    Нэт Бай Нэте
    Нэт Бай Нэту
    Нэт Бай Нэтом
    Нэт бай нэт
    Нэт бай нэта
    Нэт бай нэте
    Нэт бай нэту
    Нэт бай нэтом
    нэт бай нэт
    нэт бай нэта
    нэт бай нэте
    нэт бай нэту
    нэт бай нэтом
    НэтБайНэт
    НэтБайНэта
    НэтБайНэте
    НэтБайНэту
    НэтБайНэтом
    Нэтбайнэт
    Нэтбайнэта
    Нэтбайнэте
    Нэтбайнэту
    Нэтбайнэтом
    нэтбайнэт
    нэтбайнэта
    нэтбайнэте
    нэтбайнэту
    нэтбайнэтом
    Нэт Бай Нэт Холдинг
    Нэт Бай Нэт Холдингу
    Нэт Бай Нэт Холдинге
    Нэт Бай Нэт Холдинга
    Нэт Бай Нэт Холдингом
    Нет Бай Нет Холдинг
    Нет Бай Нет Холдингу
    Нет Бай Нет Холдинге
    Нет Бай Нет Холдинга
    Нет Бай Нет Холдингом
    НэтБайНэт Холдинг
    НэтБайНэт Холдингу
    НэтБайНэт Холдинге
    НэтБайНэт Холдинга
    НэтБайНэт Холдингом
    НетБайНет Холдинг
    НетБайНет Холдингу
    НетБайНет Холдинге
    НетБайНет Холдинга
    НетБайНет Холдингом
    Нэтбайнэт Холдинг
    Нэтбайнэт Холдингу
    Нэтбайнэт Холдинге
    Нэтбайнэт Холдинга
    Нэтбайнэт Холдингом
    Нетбайнет Холдинг
    Нетбайнет Холдингу
    Нетбайнет Холдинге
    Нетбайнет Холдинга
    Нетбайнет Холдингом
    нэтбайнэт Холдинг
    нэтбайнэт Холдингу
    нэтбайнэт Холдинге
    нэтбайнэт Холдинга
    нэтбайнэт Холдингом
    нетбайнет Холдинг
    нетбайнет Холдингу
    нетбайнет Холдинге
    нетбайнет Холдинга
    нетбайнет Холдингом
    netbynet.ru
  `
};

export const symbols = [
  [[`'`], [`'`]],
  [[`"`], [`"`]],
  [[`«`], [`»`]],
  [['`'], ['`']],
]
