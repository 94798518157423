
// Libraries
import React from 'react';

// Components
import { RadioGroup } from '@material-ui/core';
import CustomPopover from '../CustomPopover';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { StyledFormControlLabel, StyledIcon, StyledRadio } from '../index';

// Assets
import SortIcon from '@material-ui/icons/Sort';

// Styles
import styles from './styles.sass';


const SORTING = [
  {
    label: 'По охвату',
    type: 'audition',
    direction: 'desc'
  },
  {
    label: 'По охвату',
    type: 'audition',
    direction: 'asc'
  },
  {
    label: 'По времени',
    type: 'date',
    direction: 'desc'
  },
  {
    label: 'По времени',
    type: 'date',
    direction: 'asc'
  },
];


const SortDirection = ({ sort, onChange }: any) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <StyledIcon classes={{ root: styles.PopoverContainer }}>
        <SortIcon onClick={() => setOpenModal(true)} />
        {openModal && (
          <CustomPopover
            id='groupChangeMood'
            closeHandler={() => setOpenModal(false)}
            classes={{
              root: styles.Popover
            }}
          >
            <RadioGroup
              aria-label="monitoringType"
              name="monitoringType"
              value={`${sort.type} ${sort.direction}`}
              onChange={(e) => {
                const [type, direction] = e.target.value.split(' ');
                onChange({ type, direction });
              }}
            >
              {SORTING.map(({ label, type, direction }) => {
                const isCurrentSort = sort.type === type && sort.direction === direction;

                return (
                  <div key={type} className={styles.RadioButton}>
                    <StyledFormControlLabel
                      active={isCurrentSort}
                      value={`${type} ${direction}`}
                      control={<StyledRadio />}
                      label={label}
                    />
                    {direction === 'asc'
                      ? (
                        <ArrowDropUpIcon
                          style={{
                            color: isCurrentSort ? '#00B956' : 'rgba(0, 0, 0, 0.87)',
                            margin: '-4px 0 0 0' }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          style={{
                            color: isCurrentSort ? '#00B956' : 'rgba(0, 0, 0, 0.87)',
                            margin: '-4px 0 0 0'
                          }}
                        />
                      )
                    }
                  </div>
                )
              })}
            </RadioGroup>
          </CustomPopover>
        )}
      </StyledIcon>
    </>
  )
};

export default SortDirection;