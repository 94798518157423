
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { SET_EVENT } from '../../store/event/types';
import styles from './styles.sass';


const CopyLinkButton = ({ link, onClick, margin }: any) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    navigator.clipboard.writeText(link);
    dispatch({
      type: SET_EVENT,
      payload: { open: true, message: 'Ссылка скопирована', status: 'success' }
    });

    if (onClick) {
      onClick();
    }
  };


  return (
    <Button
      variant='outlined'
      classes={{root: styles.Button}}
      style={{margin}}
      onClick={handleClick}
    >
      Скопировать ссылку
    </Button>
  )
};

export default CopyLinkButton;