// Libraries
import moment from 'moment';


export const GET_TABLE_CONTENT_FROM_OBJECT = (
  obj = {},
  fields = [],
  types = [],
  customContents = {},
  customMethods = {},
  // eslint-disable-next-line max-params
) => {
  const dateToRu = (date, withoutMMSS) => moment(date, 'YYYY-MM-DD mm:ss').format(withoutMMSS ? 'DD.MM.YYYY' : `DD.MM.YYYY mm:ss`);

  const transformLabelToType = (label, type, field) => {
    switch (type) {
      case 'date': {
        return dateToRu(label)
      }

      case 'dateWithoutTime': {
        return dateToRu(label, true)
      }

      case 'custom': {
        return customMethods[field](label)
      }

      default: {
        return label === 'WITHOUT' ? '' : label
      }
    }
  }

  return fields.reduce((setup, field) => {
    const additionalField = customContents[field] ? {
      customContent: customContents[field]
    } : {
      label: transformLabelToType(obj[field], types[field], field)
    }

    return  [
      ...setup,
      {
        id: field,
        ...additionalField,
      }
    ]
  }, [])
};
