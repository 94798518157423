// @flow

// Libraries
import React, { Children, cloneElement } from 'react';
import styles from './tree-node-renderer.scss';

type MinimalThemeTreeNodeRendererPropTypes = {
  treeIndex: number;
  swapFrom?: number;
  swapDepth?: number;
  swapLength?: number;
  scaffoldBlockPxWidth: number;
  lowerSiblingCounts: Array<number>;
  treeId: string;
  listIndex: number;
  rowDirection?: string;
  children: Node;
  customCheckBoxHandler: () => void;

  // Drop target
  connectDropTarget: () => void;
  isOver: boolean;
  canDrop: boolean;
  draggedNode: any;

  // used in dndManager
  getPrevRow: () => void;
  node: any;
  path: Array<number | string >;
}

const MinimalThemeTreeNodeRenderer = (props: MinimalThemeTreeNodeRendererPropTypes) => {
  const {
    canDrop = false,
    draggedNode = null,
    children,
    scaffoldBlockPxWidth,
    lowerSiblingCounts,
    connectDropTarget,
    isOver,
    treeIndex,
    treeId,
    listIndex,
    getPrevRow, // Delete from otherProps
    node, // Delete from otherProps
    path, // Delete from otherProps
    ...otherProps
  } = props;

  const [highlight, setHighlight] = React.useState(false);
  const [selected] = React.useState([]);

  // const checkBoxChangeHandler = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleMouseOver = () => {
    if (highlight) {
      setHighlight(true);
    }
  };

  const handleMouseLeave = () => {
    setHighlight(false);
  };


  let dropType;
  const scaffoldBlockCount = lowerSiblingCounts.length - 1;
  if (canDrop && !isOver) {
    dropType = 'validDrop'
  } else if (!canDrop && isOver) {
    dropType = 'invalidDrop'
  }

  return connectDropTarget(
    <div
      {...otherProps}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      {...otherProps}
      onFocus={() => {}}
      className={styles.node + (highlight ? ` ${styles.highlight}` : '') + (dropType ? ` ${styles[dropType]}` : '')}
    >
      <div
        className={styles.nodeContent}
        style={{paddingLeft: scaffoldBlockPxWidth * scaffoldBlockCount}}
      >
        {Children.map(children, child =>
          cloneElement(child, {
            checkBoxChangeHandler: child.props.customCheckBoxHandler,
            selectedCheckboxes: selected,
            isOver,
            canDrop,
            draggedNode,
          })
        )}
      </div>
    </div>
  );
};

export default MinimalThemeTreeNodeRenderer;
