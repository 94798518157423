
// Libraries
import React from 'react';
import * as classnames from 'classnames';

// Components
import { StyledFormControlLabel, StyledIcon, StyledRadio } from '../index';
import CustomPopover from '../CustomPopover';
import { RadioGroup } from '@material-ui/core';

// Assets
import FavoritesIcon from '../../../../assets/components/FavoritesIcon';

// Styles
import styles from './styles.sass';

const VALUES = {
  all: 'all',
  only_favorites: 'only_favorites',
  without_favorites: 'without_favorites'
}

const SORTING = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Избранные',
    value: 'only_favorites',
  },
  {
    label: 'Не избранные',
    value: 'without_favorites',
  },
];

const FavoritesSort = ({ sort, onChange, textComponent }: any) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <StyledIcon
        classes={{
          root: classnames(
            styles.PopoverContainer,
            textComponent ? styles.PopoverContainer_withContent : ''
          )
        }}
      >
        <div
          className={styles.Row}
          onClick={() => setOpenModal(true)}
        >
          <FavoritesIcon
            isActive={[VALUES.all, VALUES.only_favorites].includes(sort)}
            palette='purple'
          />
          {textComponent}
        </div>
        {openModal && (
          <CustomPopover
            id='groupChangeMood'
            closeHandler={() => setOpenModal(false)}
            classes={{
              root: styles.Popover
            }}
          >
            <RadioGroup
              aria-label="monitoringType"
              name="monitoringType"
              value={sort}
              onChange={e => onChange(e.target.value)}
            >
              {SORTING.map(({ label, value }) => {
                const isCurrentSort = sort === value;

                return (
                  <div className={styles.Row}>
                    <StyledFormControlLabel
                      active={isCurrentSort}
                      value={value}
                      control={<StyledRadio />}
                      label={label}
                    />
                  </div>
                )
              })}
            </RadioGroup>
          </CustomPopover>
        )}
      </StyledIcon>
    </>
  )
};

export default FavoritesSort;