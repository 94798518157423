// @flow

// Libraries
import * as React from 'react';

// Components
import { Button, Grid, MenuItem, Modal, TextField } from '@material-ui/core';
import { StyledSubmitButton } from '../../../Operators/CustomComponents';
import StyledPaper from '@components/StyledPaper';
import { H6 } from '@components/Typography';
import MultipleSelect from '../../../../../components/MultipleSelect';
import DatePicker from '@components/DatePicker';
import { StyledFormControlLabel } from '../../../../Main/Components';
import Checkbox from '@material-ui/core/Checkbox';

// Constants
import { CAMPAIGNS_MAP_BACKEND } from '../../index';

// Styles
import styles from '../../styles.sass';
import { useSelector } from 'react-redux';
import { OPERATORS } from '../../../../../constants/general';

// Types
type Types = {
  isOpenModal: boolean;
  onClose: () => void;
  submitHandler: () => void;
  onChangeHandler: () => void;
  modalData: {
    date: string;
    name: string;
    branch_ids: any;
    operator_names: any;
    theme: string|number;
    theme_enabled: boolean;
    theme_id: string|number;
    campaign_type: string;
  },
  magistralThemes: any
};

const EditModal = ({ isOpenModal, onClose, submitHandler, modalData, onChangeHandler, magistralThemes}: Types) => {
  const {
    monitoring_regions: regions,
  } = useSelector(store => store.monitoring_constants);

  return (
    <Modal
      open={isOpenModal}
      onClose={() => onClose()}
    >
      <StyledPaper style={{width: 390}}>
        <H6 style={{ margin: '0 0 24px 0'}}>Редактирование PR-кампании</H6>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 25,
            marginBottom: 20
          }}
        >
          <span style={{marginRight: 10}}>
            Дата:
          </span>
          <DatePicker
            format="dd.MM.yyyy"
            value={modalData.date}
            onChange={date => onChangeHandler(date, 'date')}
          />
        </div>
        <TextField
          fullWidth
          variant="filled"
          label="Название"
          type="text"
          value={modalData.name}
          onChange={(e) => onChangeHandler(e.target.value, 'name')}
          style={{marginBottom: 20}}
        />
        <div style={{marginBottom: 20}}>
          <TextField
            select
            label='Тип кампании'
            variant='filled'
            value={modalData.campaign_type}
            onChange={(e) => {
              onChangeHandler(e.target.value, 'campaign_type');
              onChangeHandler([], 'branch_ids');
            }}
            classes={{root: styles.Popover__TextField}}
          >
            {CAMPAIGNS_MAP_BACKEND.map(({ id, name, type }) => <MenuItem key={id} value={type}>{name}</MenuItem>)}
          </TextField>
        </div>
        {modalData.campaign_type === 'regional' && (
          <div style={{marginBottom: 20}}>
            <MultipleSelect
              value={modalData.branch_ids}
              onChange={(res) => onChangeHandler(res, 'branch_ids')}
              options={regions}
              label='Макрорегионы'
            />
          </div>
        )}
        <div style={{marginBottom: 20}}>
          <MultipleSelect
            value={modalData.operator_names}
            onChange={(res) => onChangeHandler(res, 'operator_names')}
            options={OPERATORS.map(({ id, name }) => ({ id, name }))}
            label='Операторы'
          />
        </div>
        <div className={styles.Popover__container}>
          <TextField
            select
            label='Направление бизнеса'
            variant='filled'
            value={modalData.theme_id}
            onChange={(e) => {
              onChangeHandler(e.target.value, 'theme_id');
              onChangeHandler(magistralThemes.find(theme => theme.title === e.target.value)?.title, 'theme');
            }}
            classes={{root: styles.Popover__TextField}}
            disabled={!modalData.theme_enabled}
          >
            {magistralThemes.map(({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>)}
          </TextField>
        </div>
        <div className={styles.Popover__container}>
          <StyledFormControlLabel
            control={<Checkbox checked={!modalData.theme_enabled} />}
            label='Без направления бизнеса'
            onChange={(e) => {
              onChangeHandler(!e.target.checked, 'theme_enabled');
              onChangeHandler(null, 'theme_id');
            }}
          />
        </div>
        <Grid container xl={12} lg={12} justify="space-between">
          <Button
            size="large"
            onClick={() => onClose()}
          >
            Отменить
          </Button>
          <StyledSubmitButton
            variant="contained"
            size="large"
            onClick={() => {
              submitHandler();
              onClose();
            }}
          >
            Сохранить
          </StyledSubmitButton>
        </Grid>
      </StyledPaper>
    </Modal>
  )
};


// Exports
export default EditModal;
