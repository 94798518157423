// @flow

// Libraries
import * as React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

// Components
import Loader from '../../components/Loader';
import { Grid } from '@material-ui/core';
import QueryWrapper from '../../api/QueryWrapper';

// Constants
import { server } from '@constants/servers';

// Assets
import logo from '../../assets/Logo.svg';

const Unsubscribe = () => {
  const [isLoading, setLoadingState]= React.useState(true);
  const [error, setError] = React.useState(null);

  const searchs = window.location.search.split('&');
  const token = searchs[0] && searchs[0].replace('?mail_token=', '') || '';
  const monitoringType = searchs[1] && searchs[1].replace('monitoring_type=', '') || '';

  const updateSettings = (settings) => {
    setLoadingState(true);

    QueryWrapper(
      fetch(`${server}/api/v1/account/settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(settings)
      }),
      (res) => {
        if (res.data?.error) {
          setLoadingState(false);
          setError(res.data?.error);
        } else {
          setLoadingState(false);
          setError(null);
        }
      },
      (err) => {
        setLoadingState(false);
      },
    )
  };

  const getSettings = async () => {
    return await axios({
      method: 'get',
      url: `${server}/api/v1/account/settings`,
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        setLoadingState(false);

        if (res.data?.error) {
          setError(res.data?.error);
        } else {
          const type = monitoringType === 'federal' ? 'Федеральный' : 'Региональный';
          if (monitoringType === 'federal') {
            updateSettings(
              {
                subscribe: {
                  monitoring_regions: res.data.subscribe.monitoring_regions,
                  monitoring_sections: res.data.subscribe.monitoring_sections,
                  monitoring_types: res.data.subscribe.monitoring_types.map(item => {
                    if (item.label === type ) {
                      return {...item, value: false};
                    } else {
                      return item;
                    }
                  })
                }
              }
            );
          } else {
            updateSettings(
              {
                subscribe: {
                  monitoring_regions: res.data.subscribe.monitoring_regions.map(item => ({...item, value: false})),
                  monitoring_sections: res.data.subscribe.monitoring_sections,
                  monitoring_types: res.data.subscribe.monitoring_types.map(item => {
                    if (item.label === type ) {
                      return {...item, value: false};
                    } else {
                      return item;
                    }
                  })
                }
              }
            );
          }
        }
      })
      .catch((err) => console.log(err))
  };

  React.useEffect(() => {
    getSettings();
  }, []);

  return (
    <Grid
      container
      direction="column"
      align="center"
      justify="center"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <img style={{ margin: "0 0 20px 0"}} src={logo} alt="logo" />
          <h2 style={{ margin: "0 0 20px 0"}}>
            {error ? 'Срок действия ссылки истёк' : 'Вы отписались от e-mail рассылки'}  
          </h2>
          <Link to="/">На главную</Link>
        </>
      )}
    </Grid>
  )
};

// Exports
export default Unsubscribe;
