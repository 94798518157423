// @flow

// Libraries
import * as React from 'react';

// Api
import { getFavoriteArticles, searchFavouriteArticles } from '../../api/articleApi';

// Components
import { Container, Grid } from '@material-ui/core';
import Page from '@components/Page';
import NewsCard from '@components/NewsCard';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

// Styles
import styles from './styles.sass';

// Constants
import { VIEWS } from '@constants/views';
import ArticlesManipulator from '@components/ArticlesManipulator';
import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';

const Bookmarks = () => {
  const [articles, setArticles] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [day] = React.useState('released');

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const view = useSelector(store => store?.global_monitoring_settings?.view) || VIEWS.cells;
  const rowView = view === VIEWS.rows || !isDesktop;


  const Content = () => (
    <>
      {articles.length > 0 && (
        <Grid
          container
          xs={12}
          spacing={rowView ? 0 : 2}
        >
          {articles.map((item) => (
            <Grid
              item
              xs={12}
              sm={rowView ? 12 : 6}
              md={rowView ? 12 : 4}
              spacing={0}
            >
              <NewsCard
                setAllArticles={setArticles}
                newsId={item && item.id}
                preview={item && item.image}
                title={item && item.title}
                withVideo={Boolean(item?.video_file_url || item?.video_url)}
                isFavoritePage={item && item.is_favorite}
                edition='ria'
                rowView={rowView}
                source={{
                  name: item?.source || '',
                  logo: null
                }}
                inCategory
                additionalEventsOnClick={() => localStorage.setItem('fromBookmarks', true)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );

  React.useEffect(() => {
    setLoading(true);

    getFavoriteArticles(
      (res) => {
        setArticles(res);
        setLoading(false)
      },
      () => setLoading(false)
    );
  }, []);

  const Empty = () => (
    <div className={styles.Empty}>
      <span>
        Новости не найдены
      </span>
    </div>
  );

  const List = React.useCallback(() => (
    <Grid
      xs={12}
      container
      className={styles.EditorBlock}
    >
      {loading
        ? <Loader />
        : articles && articles.length > 0
          ? <Content />
          : <Empty />
      }
    </Grid>
  ), [articles, loading, view]);

  return (
    <Page forUser>
      <Container className={styles.Container}>
        <ArticlesManipulator
          dateFilterSettings={{
            show: false
          }}
          searchHandler={words => {
            setLoading(true);
            searchFavouriteArticles(
              words,
              res => {
                setArticles(res);
                setLoading(false);
              }
            )
          }}
          day={day}
        />
        <Grid xs={12} container className={styles.EditorBlock}>
          <List />
        </Grid>
      </Container>
    </Page>
  )
};

// Exports
export default Bookmarks;
