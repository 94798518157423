// Types
import { HIDE_EVENT, SET_EVENT } from './types';


export const EVENT_TYPES = {
  error: 'error',
  success: 'success'
};

const DEFAULT_STATE = {
  open: false,
  status: '',
  message: '',
}

const eventReducer = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case SET_EVENT: {
      return { ...action.payload };
    }

    case HIDE_EVENT: {
      return DEFAULT_STATE;
    }

    default:
      return state;
  }
};

export default eventReducer;
