// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import * as _ from 'lodash';

// Api
import { createTag, deleteTag, editTag } from '../../../api/tagsApi';

// Components
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Page from '@components/Page';
import AddTagPopover from '@components/AddTagPopover';

// Constants
import { server } from '@constants/servers';

// Assets
import arrowLeft from '@assets/arrowLeft.svg';
import { ReactComponent as TriangleIcon } from '@assets/triangle.svg';

// Styles
import styles from './styles.sass';

// Constants
import { MODAL_MODES } from '@constants/modal';
import Tabs from '../../../components/HandbookTagsModal/Tabs';
import QueryWrapper from '../../../api/QueryWrapper';
import useAuth from '../../../hooks/useAuth';
import Loader from '../../../components/Loader';
import { getToken } from '../../../api/articleApi';


const HandbookTags = () => {
  const { user: { token } } = useAuth();
  const [allTagsStatus, setAllTagsStatus] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [tags, setTags] = React.useState(null);
  const [foundName, setFoundName] = React.useState('');
  const [anchorPopover, setAnchorPopover] = React.useState(null);
  const [tagData, setTagData] = React.useState(null);
  const [tagType, setTagType] = React.useState('custom');
  const [loading, setLoading] = React.useState(true);

  const [activeTagsId, setActiveTagsId] = React.useState([]);

  // Modals
  const [modalMode, setModalMode] = React.useState(null);
  const [modalData, setModalData] = React.useState([]);
  const [newTagsName, setNewTagsName] = React.useState([]);
  const [mergeTags, setMergeTags] = React.useState({
    merged: modalData,
    title: ''
  });

  const isOpenedPopover = Boolean(anchorPopover);

  React.useEffect(() => {
    setModalData(tags && tags.filter(tag => tag.checked));
  }, [tags]);

  const getTagsByType = async () => {
    setLoading(true);

    if (tagType === 'custom') {
      await QueryWrapper(
        // fetch(`${server}/api/v1/monitoring/tags/${tagType}?sort=title&order=asc&limit=10000`, {
        fetch(`${server}/api/v1/monitoring/tags/?type=${tagType}&sort=title&order=asc&limit=10000`, {
          method: 'GET',
          headers: {
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${getToken()}`
          }
        }),
        res => {
          setTags(
            res.map((tag) => ({
              title: tag.title,
              id: tag.id,
              tag_type: tag.tag_type,
              checked: false
            }))
          )
        }
      );
    }

    if (tagType === 'theme') {
      await QueryWrapper(
        fetch(`${server}/api/v1/monitoring/themes`, {
          method: 'GET',
          headers: {
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${getToken()}`
          }
        }),
        res => {

          console.log(res);
          setTags(
            res.map((tag) => ({
              title: tag.title,
              id: tag.id,
              tag_type: 'themes',
              checked: false
            }))
          )
        }
      );
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getTagsByType(tagType);
  }, [tagType]);

  React.useEffect(() => {
    if (modalMode !== null) {
      setNewTagsName(modalData.map(tag => ({ id: tag.id, title: tag.title, newName: '' })));
    } else {
      setNewTagsName([])
    }
  }, [modalMode])

  const submitAddForm = (newTag) => {
    if (newTag) {
      createTag(
        {
          title: newTag,
          type: tagType
        },
        (res) => {
          if (!res.errors) {
            setAnchorPopover(null);
            getTagsByType(tagType);
          } else {
            const errorText = res.errors.title === 'has already been taken' ? 'Тег уже существует' : 'Неизвестная ошибка'
            setError(errorText);
          }
        }
      );
    }
  };

  const handleRename = () => {
    setModalMode(MODAL_MODES.RENAME);
  };

  const handleRestore = async () => {
  //  TODO: урл для востановления PUT  /api/v1/monitoring/tags/custom/:id/restore
    setLoading(true);

    await QueryWrapper(
      fetch(`${server}/api/v1/monitoring/tags/custom/${activeTagsId[0]}/restore`, {
        method: 'PUT',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({collection: 'tags', tags: activeTagsId })
      }),
      (res) => {
        if (!res.errors) {
          setAnchorPopover(null);
          setTags(prev => prev.filter(tag => tag.id !== res.id))
        }
      }
    );

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    const deletedData = activeTagsId.map(activeTagId => ({
      id: activeTagId,
      deleted_at: moment().locale('ru').format('YYYY-MM-DDTHH:mm:ss')
    }));

   await deleteTag(
      {
        type: tagType,
        tags: deletedData
      },
      () => getTagsByType(tagType)
    );

    setLoading(false);
  };

  const handleCheckboxChange = React.useCallback((item, checked) => {
    if (!activeTagsId.find(id => id === item.id) && checked === true) {
      setActiveTagsId(prev => [...prev, item.id])
    }

    if (activeTagsId.find(id => id === item.id) && checked === false) {
      const newActiveTagsId = _.reject(activeTagsId, i => i === item.id);
      setActiveTagsId(newActiveTagsId)
    }


    setTags(tags.map(tag => tag.title === item.title ? {...tag, checked: checked} : tag))
  }, [tags, activeTagsId]);

  const handleSaveEditedName = async ({ id, newName }) => {
    setLoading(true);
    const editedData = [{
      id: id,
      title: newName
    }];

    await editTag(
      {
        type: tagType,
        tag: editedData
      },
      () => {
        setModalMode(null);
        setActiveTagsId([]);
        getTagsByType(tagType);
      }
    );

    setLoading(false);
  };

  const handleMergeTags = () => {
    setTags(tags => [...tags, { id: 1000, title: mergeTags.title, checked: false }]);
    setMergeTags(tags => ({ ...tags, title: '' }));
    setModalMode(null);
  };

  const allCheckboxHandler = (checked) => {
    setTags(tags.map(tag => ({ ...tag, checked })));
    setAllTagsStatus(checked);
    if (checked) {
      setActiveTagsId(tags.map(tag => tag.id))
    } else {
      setActiveTagsId([])
    }
  };

  const WHITE_LIST_TAB_WITH_CONTROL_RULES = [
    'custom',
    'theme'
  ];

  const TagsList = () => loading ? <Loader text='Загрузка тегов...' /> : tags && (
    <FormGroup className={styles.Checkboxes}>
      {tags
        .filter(tag => tag?.title?.toLowerCase().includes(foundName.toLowerCase()))
        .map(item => {
          return (
            <FormControlLabel
              key={item.title}
              control={(
                <Checkbox
                  checked={item.checked}
                  onChange={({ target: { checked } }) => handleCheckboxChange(item, checked)}
                />
              )}
              label={item.title}
            />
          )
        })}
    </FormGroup>
  );

  console.log(modalData);

  return (
    <Page>
      <Grid
        container
        direction='column'
        className={styles.Container}
      >
        <Container>
          <Grid
            lg={12}
            xl={12}
            container
            direction='row'
            className={styles.SearchBlock}
          >
            <Link to='/handbooks'>
              <div className={styles.Back}>
                <img alt="" src={arrowLeft} />
                <span className={styles.Back__Text}>Назад</span>
              </div>
            </Link>
          </Grid>
          {/* Служебные в ней 1 тег "не органика" */}
          <Grid
            lg={12}
            xl={12}
            container
            direction='row'
            alignItems='center'
            className={styles.SearchBlock}
          >
            <Grid item xs={3}>
              <div className={styles.MainTitle}>Справочник — Теги</div>
            </Grid>
            <Grid item xs={2}>
              {WHITE_LIST_TAB_WITH_CONTROL_RULES.includes(tagType) && (
                <Button
                  className={styles.AddButton}
                  onClick={(e) => setAnchorPopover(e.currentTarget)}
                >
                  Создать тег
                </Button>
              )}
              <AddTagPopover
                isOpenedPopover={isOpenedPopover}
                anchorPopover={anchorPopover}
                setAnchorPopover={setAnchorPopover}
                tagData={tagData}
                onChange={(e) => setTagData(e.target.value)}
                onSubmit={(newTag) => submitAddForm(newTag)}
                error={error}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                placeholder="Поиск"
                style={{
                  width: '100%',
                }}
                variant='outlined'
                icon
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size='small'
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setFoundName(e.target.value)
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} row>
              {WHITE_LIST_TAB_WITH_CONTROL_RULES.includes(tagType) && (
                <div className={styles.Actions}>
                  <Button
                    className={styles.Actions__Item}
                    onClick={handleRename}
                    disabled={(modalData && modalData.length >= 0) && activeTagsId.length !== 1}
                  >
                    Переименовать
                  </Button>
                  <Button
                    classes={{root: `${styles.Actions__Item} ${styles.Actions__Item_remove}`}}
                    onClick={handleDelete}
                    disabled={activeTagsId.length <= 0}
                  >
                    Удалить
                  </Button>
                </div>
              )}
              {tagType === 'custom_deleted' && (
                <div className={styles.Actions}>
                  <Button
                    className={styles.Actions__Item}
                    onClick={handleRestore}
                    disabled={activeTagsId.length !== 1}
                  >
                    Восcтановить
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          <div className={styles.Menu}>
            <Tabs
              onChangeHandler={(type) => setTagType(type)}
              initialValue={0}
              isHandbook
              whiteList={['custom', 'theme']}
            />
          </div>
          <Paper className={styles.Content}>
            <div className={styles.Header}>
              <Checkbox
                classes={{ root: styles.Header__Checkbox }}
                checked={allTagsStatus}
                onChange={({ target: { checked } }) => {
                  allCheckboxHandler(checked)
                }}
              />
              <TriangleIcon className={styles.TriangleIcon} />
            </div>
            <TagsList />
            <Modal
              open={modalMode}
              onClose={() => setModalMode(null)}
              className={styles.Modal}
            >
              <div className={styles.Modal__Container}>
                <div className={styles.Modal__Title}>
                  {modalMode === MODAL_MODES.RENAME && 'Изменение имени'}
                  {modalMode === MODAL_MODES.MERGE && 'Объединение тегов'}
                </div>
                {modalMode === MODAL_MODES.RENAME && (
                  <div className={styles.Modal__Items}>
                    {modalData.map(tag => (
                      <div className={styles.Modal__Item}>
                        <div className={styles.Modal__ChangeName}>
                          <div>Текущее имя:</div>
                          <div>{tag.title}</div>
                        </div>
                        <div className={styles.Modal__ChangeName}>
                          <div>Новое имя:</div>
                          <TextField
                            placeholder='Введите новое имя тега'
                            style={{
                              flex: 'auto',
                              margin: '0 8px 0 0'
                            }}
                            variant="outlined"
                            onChange={({ target: { value } }) => setNewTagsName(prev =>
                              prev.map(item => item.id === tag.id ? { ...item, newName: value } : item )
                            )}
                          />
                        </div>
                        <div className={styles.Modal__Submit}>
                          <Button
                            className={`${styles.AddButton} ${styles.AddButton_submit}`}
                            onClick={() => handleSaveEditedName(newTagsName.find(item => item.id === tag.id))}
                          >
                            Сохранить
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {modalMode === MODAL_MODES.MERGE && (
                  <div>
                    <div>
                      <div>Список имен тегов, которые объединяются:</div>
                      <div>
                        {modalData.map(tag => (
                          <div>{tag.title}</div>
                        ))}
                      </div>
                    </div>
                    <div style={{marginTop: 10}}>
                      <div>Название для объединения:</div>
                      <TextField
                        placeholder='Введите новое имя тега'
                        style={{
                          flex: 'auto',
                          margin: '0 8px 0 0'
                        }}
                        variant="outlined"
                        value={mergeTags.title}
                        onChange={({ target: { value }}) => setMergeTags(prev => ({ ...prev, title: value }))}
                      />
                    </div>
                    <div className={styles.Modal__Submit}>
                      <Button
                        className={`${styles.AddButton} ${styles.AddButton_submit}`}
                        onClick={handleMergeTags}
                      >
                        Сохранить
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          </Paper>
        </Container>
      </Grid>
    </Page>
  )
};

export default HandbookTags;
