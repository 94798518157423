// @flow

// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ruLocale from 'date-fns/locale/ru';

// Components
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';

// App
import App from './App';

// Store
import store from './store/store';

// Styles
import './additionalStyles.css';

// Utils
import * as serviceWorker from '@utils/serviceWorker';

const $root: ?HTMLElement = document.getElementById('root');


const formats = {
  normalDate: "dd.MM.yyyy",
  keyboardDate: "dd.MM.yyyy",
};

$root &&
  ReactDOM.render(
    <Provider store={store}>
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ruLocale} dateFormats={formats}>
        <App />
      </LocalizationProvider>
    </Provider>,
    $root
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
