
export const joinedGetParameters = params => {
  if (params) {
    try {
      return Object.entries(params)
        .map(([param, value]) => {
          if (![undefined, null].includes(value)) {
            if (Array.isArray(value)) {
              return value.map(oneValue => `${param}=${oneValue}`).join('&');
            }

            return `${param}=${value}`;
          }

          return null;
        })
        .filter(val => val)
        .join('&');
    } catch (err) {
      return '';
    }
  }
}