// @flow

// Libraries
import * as React from 'react';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';

// Api
import { logIn } from '../../api/authApi';

// Components
import Page from '@components/Page';
import { Button, IconButton, Input, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// Hooks
import useUsers from '../../hooks/useUsers';
import useAuth from '../../hooks/useAuth';

// Styles
import styles from './styles.sass';
import { clearAllData } from '../../utils/methods';


const HOME_PAGES = {
  admin: '/',
  user: '/',
  editor: '/',
  analyst: '/',
  pr_manager: '/',
};


const Login = () => {
  const { hasAuth, user } = useAuth();
  const { dispatchUserData } = useUsers();

  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [formData, setFormData] = React.useState({
    login: '',
    password: ''
  });
  const [loading, setLoading] = React.useState(false);
  const handleChangeForm = React.useCallback((field) => setFormData(prev => ({ ...prev , ...field})), []);

  const handleAuth = async () => {
    setLoading(true);
    await logIn(
      {
        login: formData.login,
        password: formData.password
      },
      (user) => {

        if (user.token) {
          if (user.user.role === 'super') {
            const newUser = {...user, user: {...user.user, role: 'editor', isSuper: true}};
            dispatchUserData(newUser);
          } else {
            dispatchUserData(user);
          }

          setFormData({
            login: '',
            password: ''
          });
        }
      },
      err => setErrorMessage(err)
    );
    setLoading(false);
  };

  React.useEffect(() => {
    if (!hasAuth) {
      clearAllData(true);
    }
  }, []);

  return (
    <Page withoutFooter withoutLogin loading={loading}>
      {hasAuth && <Redirect to={HOME_PAGES[user.role]} />}
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          <div className={styles.Title}>Авторизация в МегаСми</div>
          <Input
            type='text'
            className={styles.Input}
            classes={{input: styles.Input__text}}
            placeholder='Логин или Email'
            onChange={(e) => handleChangeForm({ login: e.target.value })}
            fullWidth
          />
          <Input
            type={showPassword ? 'text' : 'password'}
            className={`${styles.Input} ${styles.Input_password}`}
            classes={{input: styles.Input__text}}
            placeholder='Пароль'
            onChange={(e) => handleChangeForm({ password: e.target.value })}
            style={{marginBottom: 0}}
            fullWidth
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(prev => !prev)}
                  style={{padding: 0}}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
          <div className={styles.RemindPassword} style={{marginBottom: '20px'}}>
            <span style={{marginRight: '30px', color: 'red'}}>
              {errorMessage ? 'Неверный логин или пароль' : '' }
            </span>

            <span>
              {/* <span>Забыли пароль? </span>
              <Link to={`${url}/remind`}>
                <span className={styles.RemindPassword__link}>Восстановить</span>
              </Link> */}
            </span>
          </div>
          <Button
            className={styles.Button}
            onClick={handleAuth}
          >
            Войти
          </Button>
          {/* <div className={styles.Divider}>
            Или
          </div>
          <Link to={`${url}/request`}>
            <div className={styles.Request}>
              Отправить запрос на создание нового аккаунта
            </div>
          </Link> */}
        </div>
      </div>
    </Page>
  )
};

export default Login;
