// @flow

// Libraries
import * as React from 'react';

// Components
import { DatePicker } from '@material-ui/pickers';
import { ClickAwayListener, TextField } from '@material-ui/core';
import styles from './styles.sass';

// Types
type DatePickerTypes = {
  value: string;
  onChange: () => void;
  style: CSSRuleList;
  textFieldStyle: CSSRuleList;
};


const DatePickerWrapper = ({value, onChange, style, textFieldStyle, ...props}: DatePickerTypes) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ClickAwayListener>
      <DatePicker
        disableToolbar
        disableOpenPicker
        disableCloseOnSelect
        style={style}
        variant="inline"
        theme="primary"
        format="dd.MM.yyyy"
        margin="none"
        value={value}
        onChange={value => {
          onChange(value);
          setIsOpen(false);
        }}
        renderInput={(props) => (
          <div className={styles.TextField}>
            <TextField
              {...props}
              style={{
                width: '81px',
                ...textFieldStyle
              }}
              helperText=''
              onClick={() => setIsOpen(prev => !prev)}
            />
          </div>
        )}
        helperText={null}
        open={isOpen}
        {...props}
        readOnly
      />
    </ClickAwayListener>
  )
};

// Exports
export default DatePickerWrapper;
