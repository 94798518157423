  // Libraries
  import React, { useCallback, useEffect, useMemo, useState } from 'react';
  import moment from 'moment';
  import _ from 'lodash';

  // Components
  import { Link, useLocation } from 'react-router-dom';
  import { Container, Grid } from '@material-ui/core';
  import ArticlesManipulator from '@components/ArticlesManipulator';
  import Page from '@components/Page';
  import NewsCard from '@components/NewsCard';
  import ShowMore from '@components/ShowMore';
  import Loader from '@components/Loader';
  import RegionPopover from '../Articles/RegionPopover';

  // Constants
  import { DEFAULT_SECTIONS_ORDER } from '@components/Settings';
  import { VIEWS } from '@constants/views';
  import { BRANCHES_QUEUE } from '../Directory/Charts';

  // Api
  import { fetchArticles } from '../../api/articleApi';

  // Methods
  import { sortByPriority } from '../../methods/articlesMethods';

  // Types
  import { CHANGE_BUFFERS } from '../../store/buffers/types';

  // Hooks
  import { useGroupArticles } from '../../utils/hooks';
  import { useDispatch, useSelector } from 'react-redux';
  import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
  import useAuth from '../../hooks/useAuth';

  // Styles
  import styles from './styles.sass';

  // Assets
  import filter_icon from '@assets/filter.png';
  import { StyledIcon } from '../Main/Components';
  import { OPERATORS_KEYS_QUEUE } from '../../constants/general';


  const parse_query_string = (query) => {
    if (query === '' || query === null) {
      return null;
    }

    const consts = query.split("&");
    const query_string = {};
    for (let i = 0; i < consts.length; i++) {
      const pair = consts[i].split("=");
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        const arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }

  const Monitoring = () => {
    const role = useSelector(({ user }) => user.role);
    const {
      monitoring_regions: regions,
      monitoring_sections
    } = useSelector(store => store.monitoring_constants);


    const {
      user: {
        monitoring_sections: userSettings
      },
    } = useAuth();

    const dispatch = useDispatch();

    const {
      monitoring_type: userMonitoringTypes,
      monitoring_regions: userMonitoringRegions
    } = useSelector(store => store?.user_monitoring_settings);

    const location = useLocation();

    const type = React.useMemo(() => {
      const isRegional = location.pathname.includes('regional');
      const isFederal = location.pathname.includes('federal');

      return isRegional
        ? 2
        : isFederal
          ? 3
          : userMonitoringTypes
    }, [location, userMonitoringTypes]);

    const sectionsFromStore = useSelector(store => store.settings.monitoring_sections);
    const articlesFromStorage = useSelector(store => store.buffer?.monitoring_acticles);

    const sections = React.useMemo(() => sectionsFromStore
      ? sectionsFromStore
        .filter(({ value }) => value)
        .sort((a, b) => a.priority - b.priority)
        .map(({ id }) => id)
      : DEFAULT_SECTIONS_ORDER
    , [sectionsFromStore]);

    const isPreparedMonitoring = React.useMemo(() => location.pathname.split('/')[2] === 'prepared', [location]);

    const urlParams = React.useMemo(() => {
      const parsedSearch = parse_query_string(location.search.substring(1));

      if (parsedSearch && Object.keys(parsedSearch).length > 0) {
        return parsedSearch;
      }

      const queryString = location.pathname.split('?');

      if (queryString.length > 1) {
        return parse_query_string(queryString[1]);
      }

      return null;
    }, [location]);

    const hasParams = !!urlParams;

    const params = React.useMemo(() => {
      const urlParamsFiltered = { ...urlParams };
      delete urlParamsFiltered?.region;

      return hasParams ? {
        ...urlParams,
        sections: urlParams?.sections ? urlParams?.sections : sections,
        regions: urlParams?.regions
          ? sections[regions.find(region => +region?.id === +urlParams?.region)]
          : regions,
        type: parseInt(urlParams?.type, 10) || type,
      } : {}
    }, [urlParams]);

    // модалка с выбором региона
    const [popoverIsOpen, setPopoverIsOpen] = React.useState(false);

    const date = isPreparedMonitoring
        ? moment().add(1, 'day').format('YYYY-MM-DD')
        : moment().subtract(0, 'day').format('YYYY-MM-DD');

    const status = isPreparedMonitoring ? 'in_process' : 'published';

    const mainParams = {
      date,
      status,
      words: ''
    }

    const [filters, setFilters] = useState(
      hasParams ? {
        ...mainParams,
        ...params,
      } : {
        regions,
        sections,
        type,
        ...mainParams
      }
    );

    React.useEffect(() => {
      dispatch({ type: 'CHANGE_USER_MONITORING_SETTINGS', payload: { monitoring_type: type } })
    }, [type])

    const view = useSelector(store => store?.global_monitoring_settings?.view) || VIEWS.cells;

    // Все новости
    const [articles, setArticles] = useState(!isPreparedMonitoring ? articlesFromStorage : null);
    const [loading, setLoading] = useState(true);

    // Если true, то мы не загружаем другие дни мониторинга и не переходим к ним
    const [lock, setLock] = useState(isPreparedMonitoring);

    // Медиазапросы
    const isDesktop = useMediaQuery('(min-width: 1200px)');
    const isTablet = useMediaQuery('(min-width: 991px)');

    // Лимит на число новостей в одной рубрике (визуально)
    const NEWS_LIMITER = isDesktop ? 5 : isTablet ? 4 : 3;

    // Формирование групп из новостей
    const { groups } = useGroupArticles(articles);

    const handleChangeFilters = useCallback(updatedValues => {
      setFilters(prev => ({ ...prev, ...updatedValues }))
    }, [filters, setFilters]);

    useEffect(() => {
      if (!(_.isEqual(sections, filters.sections))) {
        handleChangeFilters({ sections });
      }
    }, [sections]);

    useEffect(() => {
      if (filters.type !== type) {
        handleChangeFilters({ type });
      }
    }, [type]);

    useEffect(() => {
      handleChangeFilters({ params })
    }, [params]);

    const getContentForFederalMonitoring = () => {
      return {
        'WITHOUT_REGIONS': sections.map(categoryId => {
          const curGroup = groups[categoryId];
          return [categoryId, sortByPriority(curGroup)];
        })
      }
    };

    const getContentForRegionalMonitoring = () => {
      const { regions } = filters;

      return regions
        .filter(region => userMonitoringRegions ? userMonitoringRegions.find(({ id }) => id === region.id) : true)
        .reduce((reducer, { id, name }) => (
        {
          ...reducer,
          [name]: sections.map(categoryId => {
            if (groups[categoryId]) {
              const curGroup = groups[categoryId].filter(({ monitoring_region }) => monitoring_region === id);
              const filteredArticlesBySettingsRegions = curGroup && curGroup.filter(({ monitoring_region }) =>
                filters.regions.map(region => region.id).includes(monitoring_region)
              );

              return [categoryId, sortByPriority(filteredArticlesBySettingsRegions)];
            }

            return [categoryId, []]
          })
        }
      ), {});
    };

    // Подготовка групп к выводу на экран
    const content = useMemo(() => {
      if (groups) {
        if (filters.type === 3) {
          return getContentForFederalMonitoring();
        }

        if (filters.type === 2) {
          return getContentForRegionalMonitoring();
        }
      }

      return null;
    }, [groups, userMonitoringRegions, userMonitoringTypes, userSettings]);

    useEffect(() => {
      const usedFilters = { ...filters };
      if (isPreparedMonitoring) {
        usedFilters.date = null
        usedFilters.regions = null
      }

      lock && setLoading(true);

      // фетчим статьи
      fetchArticles(
        usedFilters,
        res => {
          if (lock || (res && Object.values(res).flat().length !== 0)) {
            setArticles(res);
            setLock(true);
            setLoading(false);
          } else {
            !lock && handleChangeFilters({
              ...usedFilters,
              date: moment(filters.date).subtract(1, 'day').format('YYYY-MM-DD')
            });
          }

          if (!isPreparedMonitoring && res !== null) {
            dispatch({ type: CHANGE_BUFFERS, payload: { monitoring_articles: res } });
          }
        }
      );
    }, [filters]);

    const searchHandler = useCallback(words => handleChangeFilters({ words }), [filters]);

    // Компоненты

    const Title = React.useCallback(({ children }: any) => (
      <Grid xs={12} className={styles.Header}>
        {children}
      </Grid>
    ), []);

    const rowView = React.useMemo(() => view === VIEWS.rows || !isDesktop, [view, isDesktop]);

    const Category = React.useCallback(({ categoryId, data, region }: any) => {
      const hasData = data && Array.isArray(data) && data.length > 0;

      if (!hasData) {
        return <></>
      }

      return (
        <div className={styles.Container}>
          <Title>
            {monitoring_sections.find(item => item.key === Number(categoryId))?.name}
          </Title>
          <Grid
            container
            xs={12}
            spacing={rowView ? 0 : 2}
          >
            {data
              .filter(({ monitoring_date }) => monitoring_date)
              .map((article, index) => {
                if (article.post_id === null) {
                  const hasChild = !!article?.children;
                  const thisGroup = hasChild ? [...article?.children, article].filter(({ monitoring_date }) => monitoring_date) : null;
                  const Card = () => (
                    <NewsCard
                      setAllArticles={setArticles}
                      newsId={article && article.id}
                      preview={article && article.image}
                      title={article && article.title}
                      withVideo={Boolean(article?.video_file_url || article?.video_url)}
                      childrenCount={thisGroup ? thisGroup.length - 1 : 0}
                      isFavoritePage={article && article.is_favorite}
                      edition='ria'
                      rowView={rowView}
                      bigSize
                      source={{
                        name: article?.source || '',
                        logo: null,
                        url: article?.source_url || ''
                      }}
                    />
                  );

                  if (index === 0) {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={rowView ? 12 : 8}
                        spacing={1}
                        classes={{ root: styles.NewsCard}}
                      >
                        <Card />
                      </Grid>
                    )
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={rowView ? 12 : 6}
                      md={rowView ? 12 : 4}
                      spacing={0}
                      classes={{ root: styles.NewsCard}}
                    >
                      <Card />
                    </Grid>
                  )
                }
              })
            }
          </Grid>
          {(data.length > NEWS_LIMITER || (isPreparedMonitoring && data.filter(({ monitoring_date }) => monitoring_date).length > 1)) && (
            <Link
              to={`/articles/section?date=${filters.date}&type=${filters.type}&region=${region === 'WITHOUT_REGIONS' ? region : regions.find(({ name }) => name === region)?.id}&operator=${categoryId}&status=${filters.status}`}
              style={{ width: '100%'}}
            >
              <ShowMore
                count={data?.length}
                label={
                  isPreparedMonitoring
                    ? 'Посмотреть все новости и поменять их порядок'
                    : null
                }
              />
            </Link>
          )}
        </div>
      )
    }, [monitoring_sections, isPreparedMonitoring, rowView, filters]);

    const Empty = React.useCallback(() => (
      <div className={styles.Empty}>
        {filters?.words.length > 0 ? (
          <span>Нет результатов поиска по вашему запросу</span>
        ) : isPreparedMonitoring ? (
          <span>
            Готовящийся мониторинг пуст
          </span>
        ) : (
          <span>
            Новости за {moment(filters.date).format('DD.MM.YYYY')} по {filters.type === 3 ? 'федеральному' : 'региональному'} мониторингу не найдены
          </span>
        )}
      </div>
    ), [filters]);

    const checkEmptyContent = React.useCallback(items => !(items && Object.values(items).some(region => region.some(([_, arr]) => arr.length > 0))), []);
    const checkEmptyRegion = React.useCallback(items => items.reduce((arr, [_, values]) => ([...arr, ...values]), []).length === 0, []);

    const RegionSelectorButton = React.useCallback(() => filters.type === 2 && !loading && (
      <div className={styles.RegionSelectorContainer}>
        <div
          className={styles.RegionSelectorButton}
          onClick={() => setPopoverIsOpen(true)}
        >
          <StyledIcon>
            <img
              src={filter_icon}
              style={{width: 20, height: 20}}
              alt=''
            />
          </StyledIcon>
          <div>Открыть фильтр регионов</div>
        </div>
      </div>
    ), [filters, loading])

    const RegionSelectorModal = React.useCallback(() => popoverIsOpen ? (
      <div className={styles.RegionSelectorModal}>
        <RegionPopover
          closeHandler={() => setPopoverIsOpen(false)}
          submitHandler={(updatedRegions) => {
            dispatch({ type: 'CHANGE_USER_MONITORING_SETTINGS', payload: { monitoring_regions: updatedRegions } });
            setPopoverIsOpen(false);
          }}
        />
      </div>
    ) : <></>, [popoverIsOpen]);

    const Content = React.useCallback(() => {
      const contentIsEmpty = checkEmptyContent(content);

      if (contentIsEmpty)
        return <Empty />;

      return (
        <div className={styles.Row}>
          {Object.entries(content)
            .sort(
              (a, b) => BRANCHES_QUEUE.findIndex(name => name === a[0]) - BRANCHES_QUEUE.findIndex(name => name === b[0])
            )
            .map(([region, values], i) => {
              const regionDoesntHaveValues = checkEmptyRegion(values) && Array.isArray(values);

              if (regionDoesntHaveValues) {
                return <></>;
              }

              const sortedRoot = role === 'user'
                ? filters.sections
                : OPERATORS_KEYS_QUEUE

              const sortedValues = values.sort((a, b) => sortedRoot.indexOf(a[0]) - sortedRoot.indexOf(b[0]))

              return (
                <div key={i}>
                  {region !== 'WITHOUT_REGIONS' && (
                    <div className={styles.Header}>
                      {region}
                    </div>
                  )}
                  {sortedValues
                    .map(([categoryId, data]) => <Category categoryId={categoryId} region={region} data={data} />)}
                </div>
              )
            })
          }
        </div>
      )
    }, [rowView, content]);

    const List = React.useCallback(() => (
      <Grid
        xs={12}
        container
        className={styles.Container}
      >
        {loading
          ? <Loader text={lock || hasParams ? 'Загрузка данных...' : 'Загрузка'} />
          : <Content />
        }
      </Grid>
    ), [content, loading, rowView, popoverIsOpen]);

    return (
      <Page>
        <div className={styles.Monitoring}>
          <Container className={styles.Container}>
            <ArticlesManipulator
              dateFilterSettings={{
                show: !isPreparedMonitoring,
                readonly: false
              }}
              searchHandler={searchHandler}
              dateFiltrationHandler={date => {
                handleChangeFilters({ date: date.slice(0, date.length - 1) });
                setLock(true);
              }}
              day={filters.date}
              onSendToMonitoring={status => setLoading(status === true)}
              handleClear={() => setArticles(null)}
              disabled={loading}
            />
            <RegionSelectorButton />
            <RegionSelectorModal />
            <List />
          </Container>
        </div>
      </Page>
    )
  };

  export default Monitoring;
