import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { MenuProps, useStyles } from './utils';
import { sortByBranchesQueue } from '../../pages/Directory/Charts';


const MultipleSelect = ({ value, onChange, options = [], label }: any) => {
  const classes = useStyles();
  const isAllSelected =
    value && value.length > 0 && value.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value && value[value.length - 1] === "all") {
      onChange(value.length === options.length ? [] : options.map(({ id }) => id));
      return;
    }
    onChange(value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id="mutiple-select-label"
        classes={{
          root: classes.label
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={Array.isArray(value) ? value : []}
        onChange={handleChange}
        renderValue={(selected) => selected.map(item => options.find(({ id }) => id === item)?.name).join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                value && value.length > 0 && value.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Выбрать все"
          />
        </MenuItem>
        {sortByBranchesQueue(options).map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <ListItemIcon>
              <Checkbox checked={value && value.includes(option.id)} />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultipleSelect;
