// @flow

// Libraries
import * as React from 'react';
import classnames from 'classnames';

// Components
import CustomPopover from '../../Main/Components/CustomPopover';
import { Button, Checkbox, FormControl, FormGroup } from '@material-ui/core';
import { StyledFormControlLabel } from '../../Main/Components';
import { BODY2 } from '@components/Typography';

// Styles
import styles from './styles.sass';
import { useDispatch, useSelector } from 'react-redux';
import { sortByBranchesQueue } from '../../Directory/Charts';


// Types
type ArticleFiltrationTypes = {
  closeHandler: () => void;
  submitHandler: () => void;
  classNames: any;
};

const RegionPopover = ({
  closeHandler,
  submitHandler,
  classNames,
}: ArticleFiltrationTypes) => {
  const regions = useSelector(store => store.monitoring_constants.monitoring_regions);
  const currentMonitoringRegions = useSelector(store => store.user_monitoring_settings?.monitoring_regions);
  const [monitoringRegions, setMonitoringRegions] = React.useState(currentMonitoringRegions || regions);

  const Regions = React.useCallback(() => (
    <FormControl
      key="monitoringTypes"
      className={styles.FormControl}
      component="fieldset"
    >
      <FormGroup>
        {sortByBranchesQueue(regions).map(region => (
          <div className={styles.RegionLabel}>
            <StyledFormControlLabel
              key={region.id}
              active={monitoringRegions.find(({ id }) => id === region.id)}
              value={region.id}
              control={(
                <Checkbox
                  checked={monitoringRegions.find(({ id }) => id === region.id)}
                  onChange={({ target: { checked } }) =>
                    setMonitoringRegions(prev => checked ? [...prev, region] : [...prev.filter(({ id }) => id !== region.id)])
                  }
                  name={region.id}
                />
              )}
              label={region.name}
            />
          </div>
        ))}
      </FormGroup>
    </FormControl>
  ), [regions, monitoringRegions]);

  return (
    <CustomPopover
      id='region_popover'
      closeHandler={() => closeHandler()}
      classes={{
        root: classnames(styles.CheckBoxFiltersRoot, classNames?.root),
      }}
    >
      <div style={{fontSize: 18, paddingLeft: 6}}>Регионы:</div>
      <Regions />
      <Button
        variant="outlined"
        color="primary"
        style={{
          width: '100%',
          marginBottom: 10,
        }}
        onClick={() => setMonitoringRegions(regions)}
      >
        Выбрать все
      </Button>
      <Button
        variant="contained"
        color="access"
        style={{
          background: monitoringRegions.length === 0 ? 'rgb(233, 233, 233)' : '#00B956'
        }}
        className={styles.Submit}
        onClick={() => submitHandler(monitoringRegions)}
        disabled={monitoringRegions.length === 0}
      >
        ОТФИЛЬТРОВАТЬ
      </Button>
    </CustomPopover>
  )
};

// Exports
export default RegionPopover;
