

export const getBranchByValue = val => {
  if (val === 1) {
    return 'Зарубежный'
  }

  if (val === 2) {
    return 'Региональный'
  }

  if (val === 3) {
    return 'Федеральный'
  }

  return null;
};