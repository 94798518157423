// @flow

// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

// Components
import {
  CircularProgress,
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

// Assets
import { ReactComponent as TriangleIcon } from '@assets/triangle.svg';

// Styles
import styles from './styles.sass';


const Table = ({
  header,
  rows,
  settings = {},
  loading,
  withLink = false,
  lintItemId = null,
  baseLink
}: any) => {
  const {
    withoutSort,
    emptyMessage = 'Данных не найдено',
    defaultOrderBy = '',
    defaultOrderDirection = ''
  } = settings;

  const [sortingDirection, setOrderDirection] = React.useState(defaultOrderDirection);
  const [sortingBy, setSortingBy] = React.useState(defaultOrderBy);

  const handleRequestSort = (property) => {
    const isAsc = sortingBy === property && sortingDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setSortingBy(property);
  };

  const sortHandler = (property) => () => {
    handleRequestSort(property);
  };

  const stableSort = data => _.orderBy(data, row => {
    const foundLabel = _.find(row.content, { id: sortingBy }).label;
    const labelIsDate = moment(foundLabel, 'DD.MM.YYYY', true).isValid() || moment(foundLabel, 'DD.MM.YYYY mm:ss', true).isValid();
    return labelIsDate ? moment(foundLabel, 'DD.MM.YYYY mm:ss').valueOf() : foundLabel;
  }, [sortingDirection]);

  const content = React.useMemo(
    () => {
      if (withoutSort) {
        return rows;
      }

      return rows && stableSort(rows);
    },
    [rows, sortingBy, sortingDirection]
  );

  return (
    <Paper className={styles.Paper}>
      <TableContainer>
        <MaterialTable
          className={styles.Table}
          size='medium'
          aria-label="enhanced table"
          theme='black'
        >
          <TableHead>
            <TableRow>
              {header.cells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align='left'
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  className={`${styles.Cell} ${headCell?.className}`}
                  style={headCell?.style}
                >
                  {headCell?.sort
                    ? (
                      <TableSortLabel
                        active={sortingBy === headCell.id}
                        direction={sortingBy === headCell.id ? sortingDirection : 'asc'}
                        onClick={sortHandler(headCell.id)}
                        IconComponent={() => (
                          <TriangleIcon
                            className={`
                              ${styles.SortIcon}
                              ${sortingDirection === 'asc' && sortingBy === headCell.id && styles.SortIcon_directed}
                              ${sortingBy === headCell.id && styles.Ordered}
                            `}
                          />
                        )}
                      >
                        <span className={sortingBy === headCell.id ? styles.Ordered : ''}>
                          {headCell.label}
                          {headCell?.additionalLabel}
                        </span>
                      </TableSortLabel>
                    )
                    : (
                      <>
                        {headCell.label}
                        {headCell?.additionalLabel}
                      </>
                    )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content && content.length ? content.map(row => {
              return (
                <TableRow key={row.id}>
                  {header.cells.map(cell => {
                    const foundRow = row.content.find(col => col.id === cell.id);
                    const content = foundRow
                      ? foundRow?.customContent
                        ? foundRow?.customContent
                        : (
                          <>
                            {foundRow.label}
                            {foundRow?.additionalLabel}
                          </>
                        )
                      : (
                        <>
                          —
                        </>
                      );

                    const data = row.content.find(col => col.id === 'data') || {};
                    return (
                      <TableCell
                        key={cell.id}
                        component="th"
                        scope="row"
                        padding="none"
                        className={styles.Cell}
                        style={cell.style}
                      >
                        {withLink && cell.id === lintItemId
                          ? (
                            <Link
                              className={styles.customLink}
                              to={{
                                pathname: `${baseLink}`,
                                state: {
                                  prCampaignId: data?.customContent?.id,
                                  prCampaignName: data?.customContent?.name,
                                }
                              }}
                            >
                              {content}
                            </Link>
                          )
                          : content
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            }) : (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  colSpan={header.cells.length}
                  className={styles.EmptyCell}
                >
                  {loading ? (
                    <div className={styles.EmptyCell__Container}>
                      <div className={styles.Loader}>
                        <div className={styles.Loader__Circle}>
                          <CircularProgress color="primary" />
                        </div>
                      </div>
                    </div>
                  ) : emptyMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>
    </Paper>
  )
}

export default Table;
