
export const OPERATORS_IDS = {
  megafon: 'megafon',
  yota: 'yota',
  mts: 'mts',
  beeline: 'beeline',
  tele2: 'tele2',
  news: 'news'
}

export const OPERATORS = [
  {
    id: OPERATORS_IDS.megafon,
    name: "МегаФон",
    color: "rgb(0, 185, 86)",
    key: 25,
  },
  {
    id: OPERATORS_IDS.yota,
    name: "Yota",
    color: "rgb(0, 255, 255)",
    key: 26,
  },
  {
    id: OPERATORS_IDS.mts,
    name: "МТС",
    color: "rgb(235, 90, 64)",
    key: 27,
  },
  {
    id: OPERATORS_IDS.beeline,
    name: "билайн",
    color: "rgb(255, 167, 23)",
    key: 28,
  },
  {
    id: OPERATORS_IDS.tele2,
    name: "t2",
    color: "rgb(68, 65, 137)",
    key: 29,
  },
  {
    id: OPERATORS_IDS.news,
    name: "Новости отрасли",
    color: "rgb(68, 65, 137)",
    key: 30,
  }
];

export const OPERATORS_KEYS_QUEUE = OPERATORS.map(({ key }) => key)

export const sortByOperatorsQueue = arr => [...arr].sort(
  (a, b) => OPERATORS.findIndex(name => name === a.name) - OPERATORS.findIndex(name => name === b.name)
);