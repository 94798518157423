// Libraries
import React from 'react';

// Components
import { DateRangePicker } from '@material-ui/pickers';
import { Box, TextField } from '@material-ui/core';


const DateRangePickerWrapper = ({
  styles,
  datePickerProps = {
    value: [Date(), Date()],
    onChange: () => null,
  }
}: any) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <DateRangePicker
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      label="Open me from button"
      startText=""
      endText=""
      mask="__.__.____"
      disableToolbar
      renderInput={(startProps, endProps) => (
        <React.Fragment>
          <TextField {...startProps} variant="standard" helperText='' classes={{root: styles.TextField}} />
          <Box sx={{ mx: 2 }}> по </Box>
          <TextField {...endProps} variant="standard" helperText='' classes={{root: styles.TextField}} />
        </React.Fragment>
      )}
      maxDate={Date()}
      {...datePickerProps}
    />
  )
};

export default DateRangePickerWrapper;
