// @flow

// Libraries
import moment from 'moment';

// Components
import QueryWrapper from './QueryWrapper';

// Methods
import { joinedGetParameters } from '../methods/anyMethods';

// Constants
import { server } from '@constants/servers';
import store from '../store/store';
import {getRanges} from "../pages/Directory/Charts/components/DynamicRowChartWithZoom";
import { getToken } from './articleApi';


// Токен пользователя
const {
  user: {
    token
  },
} = store.getState();


const TYPES = {
  year: 'year',
  period: 'period',
  withRanges: 'withRanges',
  federal: 'federal',
  regional: 'regional'
}

const defaultCharts = [
  ['mentions_chart'],
  ['audience_chart', TYPES.withRanges],
  ['mentions_stats', TYPES.year],
  ['mentions_stats', TYPES.period],
  ['audience_stats', TYPES.year],
  ['audience_stats', TYPES.period],
  ['top_infopovodov'],
  ['pr_campaigns'],
  ['top3_pr_campaigns'],
  ['paid_free_table'],
  ['magistral_themes'],
  ['infopovodov_chart'],
  ['mood_chart'],
  ['mood_stats'],
  ['paid_free_chart'],
  ['pr_campaigns_avg_weekly_stats', TYPES.federal],
  ['pr_campaigns_avg_weekly_stats', TYPES.regional]
];

const getYearAgoDate = (date, format = 'YYYY-MM-DD') => moment(date || undefined, format).subtract(1, 'year').startOf('month').format('YYYY-MM-DD')

const getStartYearDate = (date, format = 'YYYY-MM-DD') => moment(date, format).startOf('year').format(format);

const STATIC_DATES = {
  'mentions_chart': getYearAgoDate,
  'mentions_stats': getStartYearDate,
  'audience_chart': getYearAgoDate,
  'audience_stats': getStartYearDate,
  'mood_chart': getYearAgoDate
};


// eslint-disable-next-line max-params
const getChart = async (settings, chart, callback, monsterVariable = false) => {
  const [chartName, chartType] = chart;

  const haveStaticDates = chartType !== TYPES.period && STATIC_DATES.hasOwnProperty(chartName) && !settings?.offStatic;
  const from = haveStaticDates ? STATIC_DATES[chartName](settings.dateTo) : settings.dateFrom;
  const to = settings.dateTo;
  const macroRegions = settings.regions.map(({ value }) => value);
  const regions = settings.selectedRegions.map(({ value }) => value);
  const types = settings.types.map(({ value }) => value);
  const ranges = chartType === TYPES.withRanges
    ? getRanges({
      startValue: moment(from).format('DD.MM.YYYY'),
      endValue: moment(to).format('DD.MM.YYYY')
    })
    : null;

  const joinedParams = joinedGetParameters({
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD'),
    'monitoring_types[]': types,
    'monitoring_regions[]': macroRegions,
    'regions[]': regions,
    'ranges[]': ranges
  });

  const callFetch = async (from, to, field) => QueryWrapper(
    fetch(`${server}/api/v1/reporting/pr_dashboard/${chartName}?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    res => {
      if (monsterVariable) {
        return res
      } else {
        callback(res, field);
      }
    }
  )
    .then(res => res);

  if ([TYPES.year, TYPES.period, TYPES.federal, TYPES.regional].includes(chartType)) {
    return await callFetch(
      chartType === TYPES.year
        ? moment(from, 'YYYY-MM-DD').startOf('year').format('YYYY-MM-DD')
        : from,
      to,
      `${chartName}_${chartType}`
    );
  } else {
    return await callFetch(from, to, chartName);
  }
};

export const fetchCharts = async (settings, callback) => {
  console.log('111111111', settings);
  const recursionGet = async (index = 0) => {
    await getChart(
      settings,
      defaultCharts[index],
      (res, field) => {
        callback(res, field);
        index !== defaultCharts.length - 1 && recursionGet(index + 1)
      }
    )
  };

  recursionGet();
};

export const getYearAudienceChart = async (settings) => {
  return await getChart(
    settings,
    ['audience_chart', TYPES.withRanges],
    () => {},
    true
  )
};

export const getFullHistory = (resolveHandler, rejectHandler = () => {}) => {
  QueryWrapper(
    fetch(`${server}/api/v1/reporting/reports`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export const getOneHistory = (id, resolveHandler, rejectHandler = () => {}) => {
  QueryWrapper(
    fetch(`${server}/api/v1/reporting/reports/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export const addToHistory = (report, resolveHandler, rejectHandler = () => {}) => {
  QueryWrapper(
    fetch(`${server}/api/v1/reporting/reports`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(report)
    }),
    resolveHandler,
    rejectHandler
  )
};

export const deleteFromHistory = (id, resolveHandler, rejectHandler = () => {}) => {
  QueryWrapper(
    fetch(`${server}/api/v1/reporting/reports/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
    }),
    resolveHandler,
    rejectHandler
  )
};

export const getXLCharts = async (id) => {
  await fetch(`${server}/api/v1/reporting/reports/${id}/download`, {
    method: 'GET',
    headers: {
      'Content-Type': `blob`,
      'Authorization': `Bearer ${getToken()}`
    },
  })
  .then((res) => res.blob())
  .then((res) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(res);
    a.download = `Отчёт.xls`;
    a.click();
  })
  .catch((err) => {console.error(err)})
};
