// @flow

// Libraries
import * as React from 'react';
import moment from 'moment';

// Components
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Page from '@components/Page';
import { ReactComponent as TriangleIcon } from '@assets/triangle.svg';

// Api
import { createUser, deleteUser, editUser, getUsers, searchUser } from '../../api/usersApi';

// Assets
import edit2 from '@assets/edit2.svg';
import rubbish from '@assets/rubbish.svg';

// Styles
import styles from './styles.sass';

const DirectoryPR = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [dense] = React.useState(false);
  const [foundName] = React.useState('');
  const [openModal, setOpenModalState] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [editedData, setEditedData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // eslint-disable-next-line max-params
  const createData = (id, name, email, role, last_access, userData) => {
    return { id, name, email, role, last_access, userData };
  };

  const [defaultRows, setDefaultRows] = React.useState([]);

  const [rows, setRows] = React.useState(defaultRows);

  React.useEffect(() => setRows(() =>
    foundName === ''
      ? defaultRows
      : defaultRows.filter(row => row.name.toLowerCase().includes(foundName.toLowerCase()))
  ),[foundName, defaultRows])

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headCells = [
    { id: 'id', disablePadding: false, label: 'id' },
    { id: 'name', disablePadding: false, label: 'ФИО' },
    { id: 'email', disablePadding: false, label: 'E-mail' },
    { id: 'role', disablePadding: false, label: 'Роль' },
    { id: 'last_access', disablePadding: false, label: 'Последний вход' },
    { id: 'controls', disablePadding: false, label: '', unsort: true },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleSaveChanges = () => {
    if (type === 'edit') {
      editUser(
        editedData,
        () => getUsers(
          (res) => resolveHandler(res)
        )
      )
    }

    if (type === 'create') {
      createUser(
        editedData,
        () => getUsers(
          (res) => resolveHandler(res)
        )
      );
    }
    setEditedData(null);
    setOpenModalState(false);
  };

  const handleChangeEmployee = React.useCallback((field) => setEditedData(prev => ({ ...prev , ...field})), []);

  const roleConverter = (role) => {
    switch (role) {
      case 'user':
          return 'Пользователь';
      case 'editor':
        return 'Редактор';
      case 'analyst':
        return 'Аналитик';
      case 'admin':
        return 'Администратор';
      default:
          return 'Пользователь';
    }
  };

  const resolveHandler = (res) => {
    const newData = res.map(
      item => (
        createData(
          item.id,
          `${item.first_name || ''} ${item.last_name || ''}`,
          item.email,
          roleConverter(item.role),
          item.last_access ? moment(item.last_access, 'YYYY-MM-DDThh:mm:ss').format('DD.MM.YYYY') : '-',
          {
            id: item.id,
            username: item.username,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            role: item.role
          }
        )
      )
    );
    setDefaultRows(newData);
  };

  React.useEffect(() => {
    getUsers(
      (res) => resolveHandler(res)
    );
  }, []);

  const deleteUserHandler = (userID) => {
    deleteUser(
      userID,
      (res) => {
        getUsers(
          (res) => resolveHandler(res)
        );
      }
    );
  };

  return (
    <Page>
      <Grid
        container
        direction="column"
      >
        <Container style={{ marginTop: '20px'}}>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            alignItems="center"
            className={styles.SearchBlock}
          >
            <Grid item xs={2}>
              <div className={styles.MainTitle}>Сотрудники</div>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={styles.AddButton}
                theme='#00B956'
                onClick={() => {
                  setType('create');
                  setOpenModalState(true);
                }}
              >
                Добавить
              </Button>
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder="Поиск"
                style={{
                  width: '100%',
                }}
                variant="outlined"
                icon
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
                onChange={e => searchUser(
                  e.target.value,
                  (res) => resolveHandler(res)
                )}
              />
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            <Modal
              open={openModal}
              onClose={() => {
                setEditedData(null);
                setType(null);
                setOpenModalState(false);
              }}
              className={styles.Modal}
            >
              <div className={styles.Modal__Container}>
                <div className={styles.Modal__Title}>
                  {type === 'edit'
                    ? 'Редактирование информации о сотруднике'
                    : 'Добавление нового сотрудника'
                  }
                </div>
                <div className={styles.Modal__Content}>
                  <div className={styles.NameWrapper}>
                    <TextField
                      fullWidth
                      label="Фамилия"
                      variant="outlined"
                      value={editedData ? editedData.last_name : ''}
                      onChange={(e) => handleChangeEmployee({ last_name: e.target.value })}
                      classes={{root: styles.Popover__TextField}}
                    />
                    <TextField
                      fullWidth
                      label="Имя"
                      variant="outlined"
                      value={editedData ? editedData.first_name : ''}
                      onChange={(e) => handleChangeEmployee({ first_name: e.target.value })}
                      classes={{root: styles.Popover__TextField}}
                    />
                  </div>
                  <TextField
                    label="E-mail"
                    variant="outlined"
                    value={editedData ? editedData.email : ''}
                    onChange={(e) => handleChangeEmployee({ email: e.target.value })}
                    classes={{root: styles.Popover__TextField}}
                  />
                  <TextField
                    id="select"
                    label="Роль"
                    value={editedData ? editedData.role : ''}
                    variant="outlined"
                    select
                    onChange={(e) => handleChangeEmployee({ role: e.target.value })}
                  >
                    <MenuItem value="user">Пользователь</MenuItem>
                    <MenuItem value="editor">Редактор</MenuItem>
                    <MenuItem value="analyst">Аналитик</MenuItem>
                    <MenuItem value="admin">Администратор</MenuItem>
                    <MenuItem value="super">Суперпользователь</MenuItem>
                    <MenuItem value="pr_manager">PR-менеджер</MenuItem>
                  </TextField>
                  <TextField
                    label="Имя пользователя"
                    variant="outlined"
                    value={editedData ? editedData.username : ''}
                    onChange={(e) => handleChangeEmployee({ username: e.target.value })}
                    classes={{root: styles.Popover__TextField}}
                  />
                  <TextField
                    label="Пароль"
                    variant="outlined"
                    value={editedData ? editedData.password : ''}
                    onChange={(e) => handleChangeEmployee({ password: e.target.value })}
                    classes={{root: styles.Popover__TextField}}
                  />
                </div>
                <div className={styles.Modal__Controls}>
                  <Button
                    style={{marginRight: 16}}
                    onClick={() => {
                      setEditedData(null);
                      setType(null);
                      setOpenModalState(false);
                    }}
                    classes={{label: `${styles.Modal__Control} ${styles.Modal__Control_cancel}`}}
                  >
                    Отменить
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveChanges}
                    classes={{label: styles.Modal__Control}}
                  >
                    Сохранить
                  </Button>
                </div>
              </div>
            </Modal>
            <TableContainer>
              <Table
                className={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
                theme='black'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.id === 'last_access' ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={styles.Title}
                      >
                        {headCell?.unsort ? (
                          headCell.label
                        ) : (
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={() => <TriangleIcon className={styles.SortIcon} />}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell align="left">{row.id}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">{row.role}</TableCell>
                          <TableCell align="center">{row.last_access}</TableCell>
                          <TableCell>
                            <div className={styles.EntryContainer}>
                              <div
                                className={styles.EntryControl}
                                onClick={() => {
                                  setOpenModalState(true);
                                  setEditedData(row.userData);
                                  setType('edit')
                                }}
                              >
                                <img alt="" src={edit2} />
                              </div>
                              <div
                                className={styles.EntryControl}
                                onClick={() => deleteUserHandler(row.id)}
                              >
                                <img alt="" src={rubbish} />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Количество строк"
              labelDisplayedRows={({ from, to, count }) => `пользователи с ${from} по ${to} из ${count}`}
              page={page}
              onChangePage={(e, newPage) => setPage(newPage)}
              onChangeRowsPerPage={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
            />
          </Paper>
        </Container>
      </Grid>
    </Page>
  )
};

export default DirectoryPR;
