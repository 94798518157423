// @flow

export const editorTabMenu = [
  {
    exact: true,
    label: 'Редактор новостей',
    to: '/'
  },
  {
    label: `Готовящийся мониторинг`,
    to: '/monitoring/prepared'
  },
  {
    label: 'Выпущенный мониторинг',
    to: '/monitoring/released'
  },
  {
    label: 'Справочники',
    to: '/handbooks'
  },
  {
    label: `Заметки`,
    to: '/notes'
  },
];

export const analystTabMenu = [
  {
    exact: true,
    label: 'Создать отчет',
    to: '/'
  },
  {
    label: 'Редактор новостей',
    to: '/editor'
  },
  {
    label: 'История отчетов',
    to: '/reports_history'
  },
  {
    label: 'Выпущенный мониторинг',
    to: '/monitoring/released'
  },
  {
    label: 'Справочники',
    to: '/handbooks'
  },
];

export const userTabMenu = [
  {
    link: '#',
    title: 'Федеральные новости',
    subItems: [
      {
        id: 3,
        link: '#',
        title: 'Федеральные'
      },
      {
        id: 2,
        link: '#',
        title: 'Региональные'
      }
    ]
  },
  {
    link: '#',
    icon: 'settings',
    title: 'Настроить',
    isSettings: true
  },
  {
    link: '/bookmarks',
    title: 'Закладки'
  },
  {
    link: '/mailingSettings',
    title: 'Настройки рассылки'
  },
];

export const pr_managerTabMenu = [
  {
    exact: true,
    label: 'Справочники',
    to: '/handbooks'
  },
  {
    label: 'Редактор новостей',
    to: '/editor'
  },
  {
    label: 'Создать отчет',
    to: '/create_report'
  },
  {
    label: 'История отчетов',
    to: '/reports_history'
  },
]
