// @flow

// Libraries
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

// Components
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import Page from '@components/Page';
import NewsCard from '@components/NewsCard';
import BackButton from '@components/BackButton';
import DragCol from '../../components/DragCol';

// Methods
import { sortByPriority } from '../../methods/articlesMethods';

// Api
import { getArticle, getChildArticles, getToken } from '../../api/articleApi';
import { server } from '@constants/servers';
import QueryWrapper from '../../api/QueryWrapper';

// Methods
import { getFolder } from '../Article';

// Constants
import VIEWS from '@constants/views';

// Styles
import styles from './styles.sass';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';


const Group = () => {
  const location = useLocation();
  const { user: { role, token } } = useAuth();
  const mainItemId = Number(location.pathname.split("/")[3]);

  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const view = useSelector(store => store?.global_monitoring_settings?.view) || VIEWS.cells;
  const rowView = view === VIEWS.rows || !isDesktop;

  const [articles, setArticles] = React.useState([]);
  const [mainItem, setMainItem] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    fetchAll();
  }, []);

  const fetchChildren = main => {
    if (main) {
      setLoading(true);

      getChildArticles(
        main.post_id !== null ? main.post_id : mainItemId,
        children => {
          if (main.post_id !== null) {
            getArticle(
              main.post_id,
              fetchedArticle => {
                const parent = moment(fetchedArticle.monitoring_date).format("YYYY-MM-DD") === moment(main.monitoring_date).format("YYYY-MM-DD")
                  ? fetchedArticle
                  : main;
                setArticles(() => getFolder(parent, [fetchedArticle, ...children]));
                setLoading(false);
              }
            )
          } else {
            setArticles(() => getFolder(main, [main, ...children]))
            setLoading(false);
          }
        }
      )
    }
  };

  const fetchAll = () => {
    getArticle(
      mainItemId,
      fetchedMainItem => {
        setMainItem(fetchedMainItem);
        fetchChildren(fetchedMainItem);
      }
    );
  };

  const handleDragChange = async (reorderedArticles) => {
    await QueryWrapper(
      fetch(`${server}/api/v1/monitoring/posts/custom_priority`, {
        method: "PATCH",
        headers: {
          "Content-Type": `application/json`,
          "Authorization": `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          "posts": reorderedArticles.map(({ id, custom_priority }) => ({ id, custom_priority }))
        })
      }).then(() => fetchAll())
    );
  };

  const List = () => (
    <Grid xs={12} container className={styles.EditorBlock}>
      <Grid
        container
        xs={12}
        spacing={rowView ? 0 : 2}
      >
        {sortByPriority(articles).sort(({ post_id }) => post_id === null ? -1 : 1)
          .map((article, index) => index === 0 ? (
            <Grid
              item
              xs={12}
              sm={12}
              lg={rowView ? 12 : 8}
              spacing={1}
              classes={{ root: styles.NewsCard}}
            >
              <Link to={`/articles/${article.id}`}>
                <NewsCard
                  setAllArticles={setArticles}
                  newsId={article && article.id}
                  preview={article && article.image}
                  title={article && article.title}
                  withVideo={Boolean(article?.video_file_url || article?.video_url)}
                  isFavoritePage={article && article.is_favorite}
                  edition="ria"
                  rowView={rowView}
                  bigSize
                  source={{
                    name: article?.source || '',
                    logo: null,
                    url: article?.source_url || '',

                  }}
                  additionalEventsOnClick={() => localStorage.setItem("fromGroup", true)}
                />
              </Link>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={rowView ? 12 : 6}
              md={rowView ? 12 : 4}
              spacing={0}
              classes={{ root: styles.NewsCard}}
            >
              <NewsCard
                setAllArticles={setArticles}
                newsId={article && article.id}
                preview={article && article.image}
                title={article && article.title}
                withVideo={Boolean(article?.video_file_url || article?.video_url)}
                isFavoritePage={article && article.is_favorite}
                edition="ria"
                rowView={rowView}
                source={{
                  name: article?.source || '',
                  logo: null,
                  url: article?.source_url || '',
                  is_source_external: article?.is_source_external || false
                }}
                additionalEventsOnClick={() => localStorage.setItem("fromGroup", true)}
              />
            </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const Content = React.useCallback(() => {
    const getBackButtonRoute = () => {
      const {
        monitoring_date,
        monitoring_type,
        monitoring_region,
        monitoring_section,
        status,
      } = mainItem;

      const formattedMonitoringDate = moment(monitoring_date, "YYYY-MM-DDThh:mm:ss").format("YYYY-MM-DD");
      const params = `?date=${formattedMonitoringDate}&type=${monitoring_type}&region=${monitoring_region}&operator=${monitoring_section}&status=${status}`;

      const fromBookmarks = JSON.parse(localStorage.getItem("fromBookmarks"));

      if (fromBookmarks)
        return "/bookmarks"

      if (role === "user")
        return `/${params}`

      if (["editor", "analyst"].includes(role)) {
        const isFederal = monitoring_type === 3;
        const isPrepared =
          moment(monitoring_date, "YYYY-MM-DDThh:mm:ss").isAfter(moment().locale("ru"));

        return `/monitoring/${isPrepared ? "prepared" : "released"}/${isFederal ? "federal" : "regional"}${params}`
      }

      return `/`;
    }

    return (
      <>
        <BackButton to={getBackButtonRoute()} />
        <h1 style={{ margin: "0 0 20px 0", lineHeight: "1.5" }}>
          <span>Группа: </span>
          {mainItem?.title}
        </h1>
        <List />
      </>
    )
  }, [articles, mainItem]);

  const WrapperDragCol = () => {
    const filteredArticles = articles
      && articles.filter(({ monitoring_date }) => monitoring_date !== null);

    const condition = filteredArticles
      && filteredArticles.length > 0
      && filteredArticles[0]?.monitoring_date
      && role === "editor"
      && moment(filteredArticles[0]?.monitoring_date).isAfter(moment())
    ;

    if (condition) {
      return (
        <DragCol
          type="groups"
          items={articles}
          onChange={handleDragChange}
          loading={loading}
          setLoading={setLoading}
          firstElIsBlocked
        />
      )
    }

    return <></>
  }

  return (
    <Page loading={loading}>
      <div style={{display: "flex", width: "100%", maxWidth: "1920px"}}>
        <WrapperDragCol />
        <Container className={styles.Container}>
          {!loading && <Content />}
        </Container>
      </div>
    </Page>
  )
}

export default Group;
