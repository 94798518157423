
// Libraries
import { useDispatch } from 'react-redux';

// Constants
import { CHANGE_MONITORING_CONSTANTS } from '../store/monitoringConstants/types';
import { LOGIN, CHANGEUSER } from '../store/user/types';
import { OPERATORS } from '../constants/general';


export default function useUsers() {
  const dispatch = useDispatch();

  const dispatchUserData = user => {
    dispatch({
      type: CHANGE_MONITORING_CONSTANTS,
      payload: {
        monitoring_sections: OPERATORS,

        monitoring_types: (user && user.monitoring_types)
          ? user?.monitoring_types.reverse()
          : [{id: 3, name: "Федеральный"}, {id: 2, name: "Региональный"}],

        monitoring_regions: (user && user.monitoring_regions)
          ? user.monitoring_regions
          : [
            {id: 1, name: "Столица"},
            {id: 43, name: "Северо-Запад"},
            {id: 31, name: "Поволжье"},
            {id: 41, name: "Урал/Центр"},
            {id: 15, name: "Юг/Кавказ"},
            {id: 3, name: "Сибирь/Дальний Восток"}
          ]
      }
    });

    dispatch({ type: LOGIN, payload: { ...user.user, token: user.token } });

    // window.location.reload();
  };

  const dispatchUserRole = user => {
    dispatch({ type: CHANGEUSER, payload: user })
  };

  return { dispatchUserData, dispatchUserRole };
};
