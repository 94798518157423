// @flow

// Libraries
import * as React from 'react';
import 'moment/locale/ru';

// Components
import { Button, Popover, TextField } from '@material-ui/core';

// Constants
import { server } from '@constants/servers';

// Styles
import styles from './styles.sass';
import useAuth from '../../hooks/useAuth';
import { getToken } from '../../api/articleApi';

// Types
type AddTagPopoverTypes = {
  isOpenedPopover?: boolean;
  anchorPopover?: Node;
  setAnchorPopover?: () => void;
  onSubmit: () => void;
  autoCompleteShow?: boolean;
  autoCompleteCloseHandler: () => void;
  handbookHandler: () => void;
  error?: string;
  withHandbook?: boolean;
};

const AddTagPopover = ({
  isOpenedPopover,
  anchorPopover,
  setAnchorPopover,
  onSubmit,
  autoCompleteShow,
  autoCompleteCloseHandler,
  error,
  withHandbook = false,
  handbookHandler
}: AddTagPopoverTypes) => {
  const [tagsFromServer, setTagsFromServer] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const getCustomTags = async (e) => {
    setValue(e.target.value)
    await fetch(`${server}/api/v1/monitoring/tags?limit=5&title=${value}&sort=title&order=asc`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    })
      .then(response => response.json())
      .then((res) => {
        setTagsFromServer(res)
      })
      .catch((err) => console.log(err))
  };

  return (
    <Popover
      id={isOpenedPopover ? 'simple-popover' : undefined}
      open={isOpenedPopover}
      anchorEl={anchorPopover}
      onClose={() => setAnchorPopover(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{marginTop: 11}}
    >
      <div className={styles.Popover}>
        <div className={styles.Popover__title}>Добавить новый тег</div>
        <div className={styles.Popover__container}>
          <TextField
            label="Название"
            variant="filled"
            value={value}
            onChange={(e) => getCustomTags(e)}
            classes={{root: styles.Popover__TextField}}
            error={error}
            helperText={error}
          />
          {(!!tagsFromServer.length && !autoCompleteShow) && autoCompleteCloseHandler && (
            <div className={styles.Tags}>
              {tagsFromServer.map(item => {
                return (
                  <div
                    className={styles.TagsItem}
                    onClick={() => autoCompleteCloseHandler(item)}
                  >
                    {item.title}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {withHandbook && (
          <button
            className={styles.HandbookButton}
            onClick={() => handbookHandler()}
          >
            Перейти к справочнику тегов
          </button>
        )}
        <Button
          className={styles.Popover__addButton}
          onClick={() => {
            onSubmit(value);
            setValue('');
          }}
          disabled={!value}
        >
          Добавить
        </Button>
      </div>
    </Popover>
  )
};

export default AddTagPopover;

