// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Grid } from '@material-ui/core';
import { StyledTab, StyledTabs } from './customComponents';

// Styles
import styles from './styles.sass';

type TabMenuPropTypes = {
  onChangeHandler: () => void;
  initialValue: number;
  isHandbook: boolean;
  whiteList: any;
};

const tabs = [
  { label: 'Кастомные теги', type: 'custom' },
  { label: 'Направления бизнеса', type: 'theme' },
  { label: 'Служебные', type: 'official' },
  { label: 'Спикеры', type: 'position' },
  { label: 'СМИ', type: 'source' },
  { label: 'Регионы', type: 'branch' },
  { label: 'PR Кампании', type: 'pr_campaign' },
  { label: 'Корзина', type: 'custom_deleted', onlyForHandbook: true }
];


const Tabs = ({
  onChangeHandler,
  initialValue = 0,
  isHandbook = false,
  whiteList = []
}: TabMenuPropTypes) => {
  const [value, setValue] = React.useState(initialValue);
  const whitedTabs = whiteList.length > 0 ? tabs.filter(tab => whiteList.includes(tab.type)) : tabs;

  const onTabChange = (e, val) => {
    setValue(val);
    onChangeHandler(whitedTabs[val]?.type);
  };

  return (
    <Grid container className={styles.Root}>
      <StyledTabs
        style={{ width: '100%'}}
        value={value}
        onChange={(e, val) => onTabChange(e, val)}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {whitedTabs.map(({ label, onlyForHandbook }, i) => onlyForHandbook
          ? isHandbook && (
            <StyledTab
              label={label}
              value={i}
              classes={styles.Tab}
              component={Link}
            />
          ) : (
            <StyledTab
              label={label}
              value={i}
              classes={styles.Tab}
              component={Link}
            />
          ))}
      </StyledTabs>
    </Grid>
  )
};

// Exports
export default Tabs;
