import React, { useEffect, useState } from 'react';
import Chart from "../../../components/Chart";
import { server } from '@constants/servers';
import QueryWrapper from "../../../../../../api/QueryWrapper";
import {getToken} from "../../../../../../api/articleApi";
import moment from "moment";

const DynamicPRValue = (props: any) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		QueryWrapper(
			fetch(`${server}/api/v1/reporting/pr_dashboard/pr_value_line_graph`, {
				method: 'POST',
				headers: {
					'Content-Type': `application/json`,
					Authorization: `Bearer ${getToken()}`
				},
				body: JSON.stringify({
					from: moment(props.formattedDateFrom, 'DD.MM.YYYY').subtract(1, 'year').format('YYYY-MM-DD'),
					to: moment(props.formattedDateTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
					monitoring_types: props.settings.types.map(item => item.value),
					regions: props.settings.selectedRegions.map(item => item.value)
				})
			}),
			res => {
				setData(res.data.map(item => ({
					date: moment(item.day, 'YYYY-MM-DD').format('DD.MM.YYYY'),
					megafon_f: item.federal,
					megafon_r: item.regional
				})))
			}
		).then(res => res);
	}, []);

	return (
		<>
			<Chart
				type="RowChartWithZoom"
				variant={props.CHART_VARIANTS.average}
				range={props.range}
				dataSource={data}
				series={[
					{
						id: 25,
						key: "megafon_f",
						name: "Федеральные",
						color: "rgb(0, 185, 86)"
					},
					{
						id: 25,
						key: "megafon_r",
						name: "Региональные",
						color: "rgb(0, 100, 86)"
					}
				]}
				title='Динамика PR-value'
			/>
		</>
	)
};

export default DynamicPRValue;