// @flow

// Libraries
import React from 'react';
import PieChart, { Connector, Font, Label, Legend, Series } from 'devextreme-react/pie-chart';
import { Format, ValueAxis } from 'devextreme-react/chart';

// Methods
import { divider } from '../../../../../methods/chartsMethods';

// Types
import { PieChart as Types } from '../../types';
import { divideGraphsValue } from '../../../../../utils/methods';


const customizeText = (arg) => arg.valueText > 0 ? `${arg.valueText} (${arg.percentText})` : '';


const CustomPieChart = ({ dataSource, animation }: Types) => {
  return (
    <PieChart
      type="doughnut"
      dataSource={dataSource.values}
      palette={dataSource.palette}
      title={dataSource?.title}
      innerRadius={0.65}
      centerRender={() => (
        <svg>
          <text textAnchor="middle" x="100" y="120" style={{ fontSize: 24, fill:'#494949' }}>
            {dataSource.centerRenderText}
          </text>
        </svg>
      )}
      animation={animation}
    >
      <ValueAxis
        label={{
          customizeText: ({ value }) => divideGraphsValue(value)
        }}
      />
      <Series
        argumentField={dataSource.argumentField}
        valueField={dataSource.valueField}
      >
        <Label
          visible
          position="columns"
          customizeText={dataSource.customizeText || customizeText}
        >
          <Label visible>
            <Format
              type="fixedPoint"
              precision={0}
              formatter={text => `${Number(text).toLocaleString('ru')}`.replaceAll('.', ',')}
            />
          </Label>
          <Font size={16} />
          <Connector visible width={0.5} />
        </Label>
      </Series>
      <Legend horizontalAlignment='center' verticalAlignment="bottom" />
    </PieChart>
  );
}

export default CustomPieChart;
