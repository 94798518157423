import React from 'react';
import { Grid } from "@material-ui/core";
import styles from './styles.sass';

type TagsForActiveArticleTypes = {
	activeArticle: Object;
	tags: Array<Object>;
	removeTagHandler: () => void;
	setSelectedPrCampaign: () => void;
	selectedPrCampaign: Array<number>;
};

/**
 * Блок с тегами новостей выделенных чекбоксами
 * **/
const TagsForActiveArticle = ({
	activeArticle,
	tags,
	removeTagHandler,
	selectedPrCampaign,
	setSelectedPrCampaign,
}: TagsForActiveArticleTypes) => {

	const clickHandler = (tag) => {
		if (tag?.tag_type === 'pr_campaign') {
			if (selectedPrCampaign.find(i => i === tag?.tag_id)) {
				setSelectedPrCampaign(selectedPrCampaign.filter(i => i.id !== tag?.tag_id))
			} else {
				setSelectedPrCampaign([...selectedPrCampaign, {id: tag?.tag_id, name: tag?.title}])
			}
		}
	};

	return (
		<Grid lg={12} xl={12}>
			<div
				className={styles.Tags}
				style={{ margin: '10px 0 0' }}
			>
				{tags.map(tag => (
						<div
							className={`
									${styles.Tag} 
									${tag?.tag_type === 'pr_campaign' ? styles.PR : ''}  
									${selectedPrCampaign.find(i => i === tag?.tag_id) ? styles.Active : ''}
								`}
							onClick={() => clickHandler(tag)}
						>
							<div>{tag.title}</div>
							<div
								className={styles.Tag__Remove}
								onClick={(e) => {
									e.stopPropagation();
									removeTagHandler({
										post_id: activeArticle.id,
										tag_type: tag.tag_type,
										tag_id: tag.tag_id
									})
								}
								}
							/>
						</div>
					))}
			</div>
		</Grid>
	)
}

export default TagsForActiveArticle;