// Libraries
import _ from 'lodash';

// Hooks
import { useSelector } from 'react-redux';


const useAuth = () => {
  const user = useSelector(store => store?.user);
  const hasAuth = !!(!_.isEmpty(user) && user?.token);

  return {
    hasAuth,
    user
  }
};

export default useAuth;
