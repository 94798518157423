import React, { useEffect, useState } from 'react';
import Chart from "../../../components/Chart";
import { server } from '@constants/servers';
import QueryWrapper from "../../../../../../api/QueryWrapper";
import {getToken} from "../../../../../../api/articleApi";
import moment from "moment";
import styles from "./styles.sass";
import {Button} from "@material-ui/core";
import classnames from "classnames";

const DynamicPRValue = ({
	settings,
	formattedDateTo,
	formattedDateFrom,
}: any) => {
	const [showMode, setShowMode] = useState('period'); // period || startYear
	const [data, setData] = useState([]);

	useEffect(() => {
		QueryWrapper(
			fetch(`${server}/api/v1/reporting/pr_dashboard/pr_value_bar_graph`, {
				method: 'POST',
				headers: {
					'Content-Type': `application/json`,
					Authorization: `Bearer ${getToken()}`
				},
				body: JSON.stringify({
					from: showMode === 'period'
						? moment(formattedDateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD')
						: `${moment(formattedDateTo, 'DD.MM.YYYY').year()}-01-01`,
					to: moment(formattedDateTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
					monitoring_types: [3,2],
					regions: settings.selectedRegions.map(item => item.value)
				})
			}),
			res => {
				setData([
					{label: 'Федеральные', megafon: res.data.federal},
					{label: 'Региональные', megafon: res.data.regional}
				])
			}
		).then(res => res);
	}, [showMode]);

	return (
		<>
			<div className={styles.Title}>
				{`Сумма PR-value всех уникальных СМИ органических публикаций с ${showMode === 'period' ? formattedDateFrom : '01.01'} по ${formattedDateTo}`}
			</div>

			<div className={styles.Actions}>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowMode('period')}
					classes={{
						root: classnames(
							styles.Action,
							showMode === 'period' && styles.Action_selected
						)
					}}
				>
					За период
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowMode('startYear')}
					classes={{
						root: classnames(
							styles.Action,
							showMode === 'startYear' && styles.Action_selected
						)
					}}
				>
					С начала года
				</Button>
			</div>

			<Chart
				type="BarChart"
				dataSource={data}
				series={[
					{
						id: 25,
						key: 'megafon',
						name: 'МегаФон',
						color: 'rgb(0, 185, 86)'
					}
				]}
				sortDirection='none'
			/>
		</>
	)
};

export default DynamicPRValue;
