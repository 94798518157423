// @flow

// Libraries
import moment from 'moment';
import store from '../store/store';

export const RUS_STRINGS = {
  clearSearch: 'Очистить поиск',
  noOptions: 'Ничего не найдено',
  search: 'Поиск',
  selectAll: 'Выбрать все',
  selectSomeItems: 'Выбрать...',
}

export const REPORTS = [
  {
    value: 0,
    label: 'Упоминания и аудитория'
  },
  {
    value: 1,
    label: 'Топ инфоповодов'
  },
  {
    value: 2,
    label: 'PR кампании'
  },
  {
    value: 3,
    label: 'Направления бизнеса'
  },
  {
    value: 4,
    label: 'Тональность'
  }
];

const {
  monitoring_regions,
  monitoring_sections
} = store.getState().monitoring_constants;

export const DEFAULT_SETTINGS = {
  dateFrom: moment().subtract(1, 'month').subtract(1, 'day').locale('ru').format('YYYY-MM-DD'),
  dateTo: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  regions: monitoring_regions.map(({ id, name }) => ({
    label: name,
    value: id
  })),
  operators: monitoring_sections.map(({ id, name, key }) => ({
    label: name,
    value: id,
    section: key
  })),
  reports: REPORTS,
  name: ''
};