// @flow

// Libraries
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';
import { YMInitializer } from 'react-yandex-metrika';

// Components
import { ThemeProvider } from '@material-ui/core/styles';
import PrivateRoute from './components/PrivateRoute';

// Hooks
import useAuth from './hooks/useAuth';
import useUsers from './hooks/useUsers';

// Pages
import Login from './pages/Login';
import Remind from './pages/Login/Remind';
import Request from './pages/Login/Request';
import Notes from './pages/Notes';
import Unsubscribe from './pages/Unsubscribe';

// Methods
import { clearAllData } from './utils/methods';

// Constants
import { server } from '@constants/servers';

// Routes
import { ROUTES } from './routes';

// Styles
import styles from './App.scss';

// Themes
import mainMaterialTheme from './styles/mainMaterialTheme';
import axios from 'axios';
import EventSnackbar from './components/EventSnackbar';
import { SET_EVENT } from './store/event/types';
import { useDispatch } from 'react-redux';


const App = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');
  const { hasAuth } = useAuth();
  const { dispatchUserData } = useUsers();

  const autoLogin = React.useCallback(
    async () => {
      const userByToken = await axios
        .post(`${server}/api/v1/account/user_by_token/${tokenFromUrl}`)
        .then(async user => {
          const { data } = user;
          if (data?.token && data?.user) {
            await Cookie.set('token', data.token, { expires: 7, path: '' });

            if (data?.user?.role === 'super') {
              const newUser = {...data, user: {...data.user, role: 'user', isSuper: true}};
              await dispatchUserData(newUser);
            } else {
              await dispatchUserData(data);
            }

            window.location.replace(window.location.pathname);
          }

          if (data?.error) {
            dispatch({
              type: SET_EVENT,
              payload: {
                open: true,
                message: 'Неверный или устаревший токен',
                status: 'error'
              }
            });
            clearAllData();
          }
        })
        .catch((err) => console.log(err))
    },
    [hasAuth, window.location.search]
  );

  React.useEffect(() => {
    if (!hasAuth && tokenFromUrl) {
      autoLogin();
    }
  }, [hasAuth, tokenFromUrl, window.location.search])

  const Routes = () => React.useMemo(() => (
    <>
      <Route component={Remind} path="/login/remind" />
      <Route component={Request} path="/login/request" />
      <Route component={Login} path="/login" />
      <Route component={Notes} path="/notes/:id" />
      <Route component={Unsubscribe} path="/unsubscribe" />
      {ROUTES.map(route => <PrivateRoute {...route} key={uuidv4()} />)}
    </>
  ), []);
  const id = JSON.parse(localStorage.getItem('state'))?.user?.id || '';
  const role = JSON.parse(localStorage.getItem('state'))?.user?.role || '';

  return (
    <ThemeProvider theme={mainMaterialTheme}>
      <BrowserRouter>
        <div className={styles.Root}>
          <YMInitializer
            accounts={[92353848]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              ecommerce: "dataLayer",
              clientRole: `${role}`,
              clientID: `${id}`,
            }}
          />
          <Switch>
            <Routes />
          </Switch>
          <EventSnackbar />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  )
};

export default App;
