// @flow

// Libraries
import React from 'react';
import { Chart, CommonSeriesSettings, Format, Label, Legend, Series, ValueAxis } from 'devextreme-react/chart';

// Types
import { StackedBarChart as Types } from '../../types';

const RowStackedChart = ({ dataSource, series, argumentField, row = false, animation }: Types) => {
  return (
    <Chart
      id="chart"
      // какой-то баг с библой, из-за которой здесь отображаются элементы в обратном порядке, .reverse это фиксит
      dataSource={dataSource.reverse()}
      rotated={row}
      palette={series.map(({ color }) => color)}
      animation={animation}
    >
      <CommonSeriesSettings
        argumentField={argumentField}
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
        type="fullstackedbar"
        ignoreEmptyPoints
      >
        <Label visible>
          <Format
            type="fixedPoint"
            precision={0}
            formatter={value => `${Math.round(value * 100) / 100}%`.replaceAll('.', ',')}
          />
        </Label>
      </CommonSeriesSettings>
      <ValueAxis />
      {series.map(({ key, name }) => <Series key={name} valueField={key} name={name} />)}
      <Legend
        orientation="horizontal"
        itemTextPosition="right"
        horizontalAlignment="center"
        verticalAlignment="bottom"
        columnCount={4}
      />
    </Chart>
  );
}

export default RowStackedChart;
