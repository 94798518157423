// eslint-disable-next-line
import * as React from 'react';
import { Button, Grid, TableCell, withStyles } from '@material-ui/core';

export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: '16px 0',
  }
}))(TableCell);

export const StyledTableCellAction = withStyles((theme) => ({
  root: {
    padding: '0',
    verticalAlign: 'top'
  }
}))(TableCell);

export const StyledAddButton = withStyles((theme) => ({
  root: {
    color: '#00A84E',
    borderColor: '#00A84E',
    margin: '20px 0 24px 0'
  }
}))(Button);

export const StyledSubmitButton = withStyles((theme) => ({
  root: {
    background: '#00A84E',
    color: '#fff',
    margin: '0 0 0 8px'
  }
}))(Button);

export const StyledGrid = withStyles((theme) => ({
  root: {
    display: 'grid',
    columnGap: '16px',
    rowGap: '16px',
    gridTemplateColumns: 'repeat(2, 49%)',
  }
}))(Grid);
