import { createMuiTheme } from '@material-ui/core/styles';

const MAIN_THEME = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(0, 168, 78)',
    },
    secondary: {
      main: 'rgb(115, 25, 130)',
    },
    error: {
      main: '#cc0000'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 768,
      tablet: 992,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'rgba(0, 168, 78, 1)',
        '&:hover': {
          backgroundColor: 'rgba(0, 168, 78, 0.7)',
        }
      },
    },
  },
});

export default MAIN_THEME;
