// @flow

// Libraries
import React from 'react';

// Components
import { Button, Container, Grid } from '@material-ui/core';
import Page from '@components/Page';
import DocumentEditor from '@components/DocumentEditor';

// API
import { createNote, deleteNote, fetchNote, fetchNotes } from '../../api/notesApi';

// Styles
import styles from './styles.sass';
import Loader from '../../components/Loader';


const DEFAULT_NODE_TEMPLATE = {
  id: 10000000,
  title: "Новая заметка",
  content: "",
  custom: true
}

const Notes = () => {
  const refNoteSlug = window.location.href.split("/")[4];
  const [notes, setNotes] = React.useState([]);
  const [activeNote, setActiveNote] = React.useState(null);
  const [customNote, setCustomNote] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    if (refNoteSlug) {
      fetchNote(
        refNoteSlug,
        ({ data }) => {
          setActiveNote(data);
          setLoading(false);
        }
      )
    } else {
      fetchNotes(
        (fetchedNotes) => {
          if (!fetchedNotes?.err) {
            setNotes(fetchedNotes.data);
            setActiveNote(fetchedNotes.data[0]);
            setLoading(false);
          }
        },
      )
    }
  }, [refNoteSlug]);

  const save = (updatedNote) => {
    const clearNoteObj = { ...updatedNote };
    delete clearNoteObj.custom;
    delete clearNoteObj.id;
    setLoading(true);

    createNote(
      clearNoteObj,
      ({ data }) => {
        if (data) {
          setNotes(prev => prev.map(note => note.custom ? data : note))
          setActiveNote(data);
          setCustomNote(null);
        }
        setLoading(false);
      }
    )
  };

  const handleCreateNote = () => {
    setNotes(prev => [...prev, DEFAULT_NODE_TEMPLATE]);
    setCustomNote(DEFAULT_NODE_TEMPLATE);
    setActiveNote(DEFAULT_NODE_TEMPLATE);
  };

  const handleDeleteNote = () => {
    setLoading(true);
    deleteNote(
      activeNote.slug,
      () => {
        const updatedNotes = notes.filter(note => note.slug !== activeNote.slug)
        setNotes(updatedNotes);
        setActiveNote(updatedNotes[0]);
        setCustomNote(null);
        setLoading(false);
      }
    )
  };

  const Controls = () => (
    <div className={styles.Notes}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleCreateNote}
        style={{marginBottom: 15}}
        disabled={customNote || loading}
      >
        Создать заметку
      </Button>
      {!loading && notes && notes.sort((a, b) => b.id - a.id).map(note => (
        <div
          key={note.id}
          className={`${styles.Item} ${activeNote?.id === note.id && styles.Item_active}`}
          onClick={() => setActiveNote(note)}
        >
          <div className={styles.Name}>{note.title}</div>
        </div>
      ))}
    </div>
  );

  const Content = () => (
    <Grid
      container
      direction="column"
    >
      <Container>
        <div className={styles.Container}>
          {!refNoteSlug && <Controls />}
          {loading
            ? <Loader />
            : activeNote && (
              <DocumentEditor
                note={activeNote}
                onSave={save}
                onDelete={handleDeleteNote}
                canEdit={!!activeNote?.custom}
                viewMode={refNoteSlug}
              />
          )}
        </div>
      </Container>
    </Grid>
  );

  return (
    <>
      {refNoteSlug
        ? <Content />
        : (
          <Page>
            <Content />
          </Page>
        )
      }
    </>
  )
}

export default Notes;
