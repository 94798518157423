import React, { useEffect, useState } from 'react';
import Chart from "../../../components/Chart";
import { server } from '@constants/servers';
import QueryWrapper from "../../../../../../api/QueryWrapper";
import {getToken} from "../../../../../../api/articleApi";
import moment from "moment";
import styles from "./styles.sass";
import {Button} from "@material-ui/core";
import classnames from "classnames";

const Top3Company = ({
	type = 'federal',
	formattedDateTo,
	formattedDateFrom,
	settings
}: any) => {
	const [showMode, setShowMode] = useState('period'); // period || startYear
	const [data, setData] = useState([]);
	const [series, setSeries] = useState([]);

	const colors = ['rgb(0, 185, 86)','rgb(255, 167, 23)','rgb(68, 65, 137)']

	useEffect(() => {
		QueryWrapper(
			fetch(`${server}/api/v1/reporting/pr_dashboard/top3_pr_campaigns_by_value_bar_graph`, {
				method: 'POST',
				headers: {
					'Content-Type': `application/json`,
					Authorization: `Bearer ${getToken()}`
				},
				body: JSON.stringify({
					from: showMode === 'period'
						? moment(formattedDateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD')
						: `${moment(formattedDateTo, 'DD.MM.YYYY').year()}-01-01`,
					to: moment(formattedDateTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
					monitoring_types: [3,2],
					regions: settings.selectedRegions.map(item => item.value)
				})
			}),
			res => {

				if (res.data[type]) {
					setSeries(
						res.data[type].map((item, idx) => ({
								id: 20 + idx,
								key: item.title,
								name: item.title,
								color: colors[idx]
							}))
					)
					setData(res.data[type].map(item => ({
						[item.title]: item.value,
						label: ''
					})))
				}

			}
		).then(res => res);
	}, [showMode]);

	return (
		<>
			<div className={styles.Title}>
				{`ТОП-3 ${type === 'federal' ? 'федеральные' : 'региональные'} кампании по PR-Value с ${showMode === 'period' ? formattedDateFrom : '01.01'} по ${formattedDateTo}`}
			</div>
			<div className={styles.Actions}>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowMode('period')}
					classes={{
						root: classnames(
							styles.Action,
							showMode === 'period' && styles.Action_selected
						)
					}}
				>
					За период
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowMode('startYear')}
					classes={{
						root: classnames(
							styles.Action,
							showMode === 'startYear' && styles.Action_selected
						)
					}}
				>
					С начала года
				</Button>
			</div>

			 <Chart
				type="StackedBarChart"
				dataSource={data}
				series={series}
				row
			 />
		</>
	)
};

export default Top3Company;
