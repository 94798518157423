// Libraries
import React from 'react';

// Components
import Monitoring from '../Monitoring';
import Editor from '../Main';
import Charts from '../Directory/Charts';
import Employees from '../Employees';
import { Redirect } from 'react-router-dom';

// Hooks
import useAuth from '../../hooks/useAuth';


const Root = () => {
  const { hasAuth, user: { role } } = useAuth();

  const Component = React.useCallback(() => {
    if (hasAuth) {
      // eslint-disable-next-line default-case
      switch(role) {
        case 'user':
          return <Monitoring />

        case 'editor':
          return <Editor />

        case 'analyst':
          return <Charts />

        case 'admin':
          return <Employees />

        case 'pr_manager':
          return <Editor />

        default:
          return <Redirect to='/login' />
      }
    } else {
      return <Redirect to='/login' />
    }
  }, [hasAuth, role]);

  return <Component />;
};

export default Root;
