
import React from 'react';


const PALETTE = {
  green: '#00B956',
  purple: '#731982',
};

const FavoritesIcon = ({ isActive, palette = 'green', ...props }: any) => {
  const color = isActive ? PALETTE[palette] : '#9E9E9E';

  return (
    <svg {...props} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99984 13.3917L14.1498 16.5L12.7832 10.6417L17.3332 6.7L11.3415 6.19167L8.99984 0.666672L6.65817 6.19167L0.666504 6.7L5.2165 10.6417L3.84984 16.5L8.99984 13.3917Z" fill={color} />
    </svg>
  );
}

export default FavoritesIcon;