import React from "react";
import useAuth from "../../hooks/useAuth";
import { server } from '@constants/servers';
import * as _ from 'lodash';
import QueryWrapper from "../../api/QueryWrapper";
import {getToken} from "../../api/articleApi";

const UseHandbookTagsModal = ({
	postId,
	onCloseHandler,
	activeTags,
	addTagsHandler
}) => {
	const { user: { role } } = useAuth();
	const [tags, setTags] = React.useState(null);
	const TAG_TYPES = role === 'editor'
		? ['custom', 'pr_campaign']
		: ['custom', 'pr_campaign', 'official'];
	const [tagType, setTagType] = React.useState('custom');
	const [selectedTags, setSelectedTags] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	const handleCheckboxChange = (item, checked) => {
		if (!selectedTags.find(t => t.tag_id === item.tag_id) && checked === true) {
			setSelectedTags([...selectedTags, item])
		}

		if (selectedTags.find(t => t.tag_id === item.tag_id) && checked === false) {
			const newActiveTag = _.reject(selectedTags, i => i.tag_id === item.tag_id);
			setSelectedTags(newActiveTag)
		}

		setTags(prev => ({
			...prev,
			[tagType]: tags[tagType].map(tag => tag.title === item.title ? { ...tag, checked: checked } : tag)
		}))
	};

	const fetchAllTagsByType = async (type, title) => {
		setLoading(true);

		if (tagType === 'custom') {
			await QueryWrapper(
				fetch(`${server}/api/v1/monitoring/tags/?type=${type}&${title ? `title=${title}&` : ''}`, {
					method: 'GET',
					headers: {
						'Content-Type': `application/json`,
						'Authorization': `Bearer ${getToken()}`
					}
				}),
				(res) => setTags(prev => ({
					...prev,
					[type]: res.map((tag) => ({
						title: tag.title,
						tag_id: tag.id,
						tag_type: 'custom',
						checked: false
					}))
				}))
			)
		}
		if (tagType === 'pr_campaign') {
			await QueryWrapper(
				fetch(`${server}/api/v1/monitoring/pr_campaigns`, {
					method: 'GET',
					headers: {
						'Content-Type': `application/json`,
						'Authorization': `Bearer ${getToken()}`
					}
				}),
				(res) => setTags(prev => ({
					...prev,
					[type]: res.map((tag) => ({
						title: tag.name,
						tag_id: tag.id,
						tag_type: tagType,
						checked: false
					}))
				}))
			)
		}
		if (tagType === 'official') {
			setTags({
				official: [
					{title: "Органика", tag_id: 1, tag_type: 'official', checked: false},
					{title: "Не органика", tag_id: 2, tag_type: 'official', checked: false},
				]
			})
		}
		setLoading(false);
	}

	const searchTags = async (title) => {
		TAG_TYPES.map(type => fetchAllTagsByType(type, title))
	};

	const onClose = () => {
		onCloseHandler();
		setTags(null);
		setTagType('custom');
		setSelectedTags([]);
	};

	const singleArticlesubmitHandler = async () => {
		if (tagType === 'custom') {
			const tagByType = [...activeTags, ...selectedTags].filter(tag => tag.tag_type === 'custom');
			await QueryWrapper(
				fetch(`${server}/api/v1/monitoring/posts/${postId}`, {
					method: 'PATCH',
					body: JSON.stringify({
						tags: tagByType.map(item => item.tag_id)
					}),
					headers: {
						'Content-Type': `application/json`,
						'Authorization': `Bearer ${getToken()}`
					}
				}),
				(res) => {
					addTagsHandler();
					onClose();
				}
			)
		}

		if (tagType === 'pr_campaign') {
			const tagByType = [...activeTags, ...selectedTags].filter(tag => tag.tag_type === 'pr_campaign');
			await QueryWrapper(
				fetch(`${server}/api/v1/monitoring/posts/${postId}`, {
					method: 'PATCH',
					body: JSON.stringify({
						pr_campaigns: tagByType.map(item => item.tag_id)
					}),
					headers: {
						'Content-Type': `application/json`,
						'Authorization': `Bearer ${getToken()}`
					}
				}),
				(res) => {
					addTagsHandler();
					onClose();
				}
			)
		}

		if (tagType === 'official') {
			const tagByType = [...activeTags, ...selectedTags].filter(tag => tag.tag_type === 'official');
			const item = tagByType.find(item => item.tag_type === 'official')
			await QueryWrapper(
				fetch(`${server}/api/v1/monitoring/posts/${postId}`, {
					method: 'PATCH',
					body: JSON.stringify({
						paid: item.title === 'Не органика'
					}),
					headers: {
						'Content-Type': `application/json`,
						'Authorization': `Bearer ${getToken()}`
					}
				}),
				(res) => {
					addTagsHandler();
					onClose();
				}
			)
		}
	};

	const multiplyArticlesSubmitHandler = async () => {
		await postId.map(async articleId => {
			if (tagType === 'custom') {
				const tagByType = [...activeTags, ...selectedTags].filter(tag => tag.tag_type === 'custom');
				const tagsIds = tagByType.map(item => item.tag_id);
				const total = [...new Set(tagsIds)];
				await QueryWrapper(
					fetch(`${server}/api/v1/monitoring/posts/${articleId.id}`, {
						method: 'PATCH',
						body: JSON.stringify({
							tags: total
						}),
						headers: {
							'Content-Type': `application/json`,
							'Authorization': `Bearer ${getToken()}`
						}
					}),
					(res) => {
						addTagsHandler();
						onClose();
					}
				)
			}

			if (tagType === 'pr_campaign') {
				const tagByType = [...activeTags, ...selectedTags].filter(tag => tag.tag_type === 'pr_campaign');
				const tagIds = tagByType.map(item => item.tag_id);
				const total = [...new Set(tagIds)];
				await QueryWrapper(
					fetch(`${server}/api/v1/monitoring/posts/${articleId.id}`, {
						method: 'PATCH',
						body: JSON.stringify({
							pr_campaigns: total
						}),
						headers: {
							'Content-Type': `application/json`,
							'Authorization': `Bearer ${getToken()}`
						}
					}),
					(res) => {
						addTagsHandler();
						onClose();
					}
				)
			}

			if (tagType === 'official') {
				const tagByType = [...activeTags, ...selectedTags].filter(tag => tag.tag_type === 'official');
				const item = tagByType.find(item => item.tag_type === 'official')
				await QueryWrapper(
					fetch(`${server}/api/v1/monitoring/posts/${articleId}`, {
						method: 'PATCH',
						body: JSON.stringify({
							paid: item.title === 'Не органика'
						}),
						headers: {
							'Content-Type': `application/json`,
							'Authorization': `Bearer ${getToken()}`
						}
					}),
					(res) => {
						addTagsHandler();
						onClose();
					}
				)
			}
		})
	};

	return {
		loading,
		setLoading,
		selectedTags,
		setSelectedTags,
		tagType,
		setTagType,
		TAG_TYPES,
		tags,
		setTags,
		handleCheckboxChange,
		searchTags,
		fetchAllTagsByType,
		onClose,
		singleArticlesubmitHandler,
		multiplyArticlesSubmitHandler
	}
};

export default UseHandbookTagsModal;