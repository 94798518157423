// @flow

// Libraries
import * as React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';

// Components
import {Button, Checkbox, Container, Grid, InputAdornment, MenuItem, Popover, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Page from '@components/Page';
import EditModal from './Components/EditModal';
import Table from '../../../components/Table';
import DatePicker from '@components/DatePicker';
import {StyledFormControlLabel} from '../../Main/Components';
import QueryWrapper from '../../../api/QueryWrapper';
import MultipleSelect from '../../../components/MultipleSelect';

// Methods
import {GET_TABLE_CONTENT_FROM_OBJECT} from '../../../methods/tableMethods';

// Constants
import {server} from '@constants/servers';

// API
import {createCampaign, deleteCampaign, editCampaign, getCampaign, searchСampaigns} from '../../../api/prCampaignApi';

// Assets
import arrowLeft from '@assets/arrowLeft.svg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {ReactComponent as EditIcon} from '../../../assets/edit.svg';

// Styles
import styles from './styles.sass';
import {useSelector} from 'react-redux';
import store from '../../../store/store';
import {OPERATORS} from '../../../constants/general';
import {getToken} from '../../../api/articleApi';


export const CAMPAIGNS_MAP_BACKEND = [
	{
		id: 3,
		name: 'Федеральный',
		type: 'federal'
	},
	{
		id: 2,
		name: 'Региональный',
		type: 'regional'
	},
];

// Токен пользователя
const {
	user: {
		token
	},
} = store.getState();


const HandbookPRCampaigns = () => {
	const {
		monitoring_regions: regions,
	} = useSelector(store => store.monitoring_constants);

	const [anchorPopover, setAnchorPopover] = React.useState(null);

	const DEFAULT_ADD_CAMPAIGN_DATA = {
		date: new Date(),
		name: null,
		branch_ids: [],
		operator_names: null,
		theme: null,
		theme_id: null,
		theme_enabled: true,
		campaign_type: null
	};

	const [addCampaignData, setAddCampaignData] = React.useState(DEFAULT_ADD_CAMPAIGN_DATA);
	const [isOpenModal, setIsOpenModalState] = React.useState(false);
	const [modalData, setModalData] = React.useState({});
	const [defaultRows, setDefaultRows] = React.useState([]);
	const [rows, setRows] = React.useState(defaultRows);
	const [loading, setLoading] = React.useState(true);
	const [magistralThemes, setMagistralThemes] = React.useState([]);
	const isOpenedPopover = Boolean(anchorPopover);
	const [event, setEvent] = React.useState({
		open: false,
		message: null,
		status: null
	});

	// eslint-disable-next-line max-params
	const createData = (date, name, campaign_type, branch_ids, operator_names, theme, theme_id, theme_enabled, data = {}) => {
		return {date, name, campaign_type, branch_ids, operator_names, theme, theme_id, theme_enabled, data};
	};

	const changeCampaignData = (data) => {
		setAddCampaignData(prev => ({...prev, ...data}))
	};

	const handleChangeModalField = (value, fieldName) => {
		setModalData(prev => {
			const newModalData = {...prev};
			newModalData[fieldName] = value;
			return newModalData;
		});
	};

	const resolveHandler = (res) => {
		setDefaultRows(res.map(item => createData(
			moment(item.date).utc().format('DD.MM.YYYY'),
			item.name,
			item.campaign_type,
			item.branch_ids,
			item.operator_names,
			item.theme,
			item.theme_id,
			item.theme_enabled,
			item
		)));
		setLoading(false);
	};

	const rejectHandler = err => {
		setLoading(false);
	}

	const searchHandler = (value) => {
		setLoading(true);
		searchСampaigns(
			value,
			resolveHandler,
			rejectHandler
		);
	};

	const createPRCampaign = () => {
		const getErr = () => setEvent({open: true, message: 'Произошла ошибка при создании PR-кмпании', status: 'error'});

		createCampaign(
			addCampaignData,
			data => {
				if (data?.errors) {
					getErr();
				} else {
					getCampaign(
						res => {
							setAnchorPopover(null);
							setAddCampaignData(DEFAULT_ADD_CAMPAIGN_DATA);
							setEvent({open: true, message: 'PR-кампания успешно добавлена', status: 'success'});
							resolveHandler(res);
						}
					)
				}
			},
			() => {
				getErr();
				rejectHandler();
			}
		)
	};

	React.useEffect(() => setRows(defaultRows), [defaultRows]);

	const getMagistralThemes = (resolve, reject) => {
		QueryWrapper(
			fetch(`${server}/api/v1/monitoring/themes`, {
				method: 'GET',
				headers: {
					'Content-Type': `application/json`,
					'Authorization': `Bearer ${getToken()}`
				}
			}),
			resolve,
			reject
		)
	};

	React.useEffect(() => {
		getCampaign(
			resolveHandler,
			rejectHandler
		);
		getMagistralThemes(res => setMagistralThemes(res));
	}, []);

	const tableHead = React.useMemo(() => ({
		cells: [
			{id: 'date', disablePadding: false, label: 'Дата', sort: true},
			{id: 'name', disablePadding: false, label: 'Название', sort: true, style: {textAlign: 'left'}},
			{id: 'campaign_type', disablePadding: false, label: 'Тип кампании', sort: true},
			{id: 'branch_ids', disablePadding: false, label: 'Макрорегионы'},
			{id: 'operator_names', disablePadding: false, label: 'Операторы'},
			{id: 'theme', disablePadding: false, label: 'Тема', style: {textAlign: 'left'}},
			{id: 'edit', disablePadding: false, label: ''},
			{id: 'delete', disablePadding: false, label: ''},
		]
	}), []);


	const tableRows = React.useMemo(() => rows.map(row => ({
		content: GET_TABLE_CONTENT_FROM_OBJECT(
			row.data,
			['date', 'name', 'campaign_type', 'branch_ids', 'operator_names', 'theme', 'edit', 'delete', 'data'],
			{
				date: 'dateWithoutTime',
				operator_names: 'custom',
				branch_ids: 'custom',
				campaign_type: 'custom',
				data: 'custom'
			},
			{
				edit: (
					<div className={styles.EditIcon}>
						<EditIcon
							fill='#00B956'
							onClick={() => {
								setModalData(row.data);
								setIsOpenModalState(true);
							}}
						/>
					</div>
				),
				delete: (
					<div className={styles.EditIcon}>
						<DeleteForeverIcon
							style={{color: '#00B956'}}
							onClick={() => {
								const getErr = () => setEvent({
									open: true,
									message: 'Произошла ошибка при удалении PR-кампании',
									status: 'error'
								});

								deleteCampaign(
									row.data,
									data => {
										if (data?.errors) {
											getErr();
										} else {
											getCampaign(res => {
												setEvent({open: true, message: 'PR-кампания успешно удалена', status: 'success'});
												resolveHandler(res);
											})
										}
									},
									getErr
								)
							}}
						/>
					</div>
				),
				data: row.data
			},
			{
				operator_names: arr => arr && arr.length ? arr.map(operator => OPERATORS.find(({id}) => id === operator)?.name).join(', ') : [],
				branch_ids: arr => arr && arr.length ? arr.map(id => regions.find(region => region.id === id)?.name).join(', ') : [],
				campaign_type: label => CAMPAIGNS_MAP_BACKEND.find(campaign => campaign.type === label)?.name,
			},
		)
	})), [rows]);

	return (
		<Page event={event}>
			<Grid
				container
				direction='column'
			>
				<EditModal
					isOpenModal={isOpenModal}
					onClose={() => setIsOpenModalState(false)}
					onChangeHandler={(value, fieldName) => handleChangeModalField(value, fieldName)}
					submitHandler={() => {
						const getErr = () => setEvent({
							open: true,
							message: 'Произошла ошибка при редактировании PR-кампании',
							status: 'error'
						});

						editCampaign(
							modalData,
							data => {
								if (data?.errors) {
									getErr();
								} else {
									getCampaign(res => {
										resolveHandler(res);
										setEvent({open: true, message: 'PR-кампания успешно отредактирована', status: 'success'});
									})
								}
							},
							getErr
						)
					}}
					modalData={modalData}
					magistralThemes={magistralThemes}
				/>
				<Container>
					<Grid
						lg={12}
						xl={12}
						container
						direction='row'
						className={styles.SearchBlock}
					>
						<Link to='/handbooks'>
							<div className={styles.Back}>
								<img alt='' src={arrowLeft} />
								<span className={styles.Back__text}>Назад</span>
							</div>
						</Link>
					</Grid>
					<Grid
						lg={12}
						xl={12}
						container
						direction='row'
						alignItems='center'
						className={styles.SearchBlock}
					>
						<Grid item xs={5}>
							<div className={styles.MainTitle}>
								Справочник — PR — кампании
							</div>
						</Grid>
						<Grid item xs={2}>
							<Button
								className={styles.AddButton}
								theme='#00B956'
								onClick={(e) => setAnchorPopover(e.currentTarget)}
							>
								Добавить
							</Button>
							<Popover
								id={isOpenedPopover ? 'simple-popover' : undefined}
								open={isOpenedPopover}
								anchorEl={anchorPopover}
								onClose={() => setAnchorPopover(null)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								style={{marginTop: 11}}
							>
								<div className={styles.Popover}>
									<div className={styles.Popover__title}>Добавить новую PR-кампанию</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginTop: 25,
											marginBottom: 20
										}}
									>
                    <span style={{marginRight: 10}}>
                      Дата:
                    </span>
										<DatePicker
											format="dd.MM.yyyy"
											value={addCampaignData.date}
											onChange={date => changeCampaignData({date})}
										/>
									</div>
									<div className={styles.Popover__container}>
										<TextField
											label='Название'
											variant='filled'
											value={addCampaignData.name}
											onChange={(e) => changeCampaignData({name: e.target.value})}
											classes={{root: styles.Popover__TextField}}
										/>
									</div>
									<div className={styles.Popover__container}>
										<TextField
											select
											label='Тип кампании'
											variant='filled'
											value={addCampaignData.campaign_type}
											onChange={(e) => changeCampaignData({campaign_type: e.target.value, branch_ids: []})}
											classes={{root: styles.Popover__TextField}}
										>
											{CAMPAIGNS_MAP_BACKEND.map(({
                                                    id,
                                                    name,
                                                    type
                      }) => <MenuItem key={id} value={type}>{name}</MenuItem>)}
										</TextField>
									</div>
									{addCampaignData.campaign_type === 'regional' && (
										<div className={styles.Popover__container}>
											<MultipleSelect
												value={addCampaignData.branch_ids}
												onChange={(res) => changeCampaignData({branch_ids: res})}
												options={regions}
												label='Макрорегионы'
											/>
										</div>
									)}
									<div className={styles.Popover__container}>
										<MultipleSelect
											value={addCampaignData.operator_names}
											onChange={(res) => changeCampaignData({operator_names: res})}
											options={OPERATORS.map(({id, name}) => ({id, name}))}
											label='Операторы'
										/>
									</div>
									<div className={styles.Popover__container}>
										<TextField
											select
											label='Направление бизнеса'
											variant='filled'
											value={addCampaignData.theme_id}
											onChange={(e) => changeCampaignData({
												theme: magistralThemes.find(theme => theme.title === e.target.value)?.title,
												theme_id: e.target.value
											})}
											classes={{root: styles.Popover__TextField}}
											disabled={!addCampaignData.theme_enabled}
										>
											{magistralThemes.map(({id, title}) => <MenuItem key={id} value={id}>{title}</MenuItem>)}
										</TextField>
									</div>
									<div className={styles.Popover__container}>
										<StyledFormControlLabel
											control={<Checkbox checked={!addCampaignData.theme_enabled} />}
											label='Без направления бизнеса'
											onChange={(e) => changeCampaignData({theme_id: null, theme_enabled: !e.target.checked})}
										/>
									</div>
									<Button
										className={styles.Popover__addButton}
										theme='#00B956'
										style={{height: '51px'}}
										onClick={createPRCampaign}
										disabled={
											(addCampaignData.theme_enabled
												&& !addCampaignData.theme_id)
											|| !addCampaignData.operator_names
											|| !addCampaignData.campaign_type
											|| !addCampaignData.name
										}
									>
										Добавить
									</Button>
								</div>
							</Popover>
						</Grid>
						<Grid item xs={5}>
							<TextField
								placeholder='Поиск'
								style={{
									width: '100%',
								}}
								variant='outlined'
								icon
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								size='small'
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										searchHandler(e.target.value)
									}
								}}
							/>
						</Grid>
					</Grid>
					<Table
						withLink
						lintItemId="name"
						baseLink="/editor"
						header={tableHead}
						rows={tableRows}
						loading={loading}
						settings={{
							emptyMessage: 'PR-кампаний не найдено',
							defaultOrderBy: 'date',
							defaultOrderDirection: 'desc'
						}}
					/>
				</Container>
			</Grid>
		</Page>
	)
};

export default HandbookPRCampaigns;
