import React, { useEffect, useState } from 'react';
import Table from '@components/Table';
import { server } from '@constants/servers';
import QueryWrapper from "../../../../../../api/QueryWrapper";
import {getToken} from "../../../../../../api/articleApi";
import moment from "moment";
import styles from "./styles.sass";
import {
	Button,
	Table as MaterialTable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@material-ui/core";
import classnames from "classnames";

const PRCompanyTable = ({
	formattedDateTo,
	formattedDateFrom,
}: any) => {
	const [showMode, setShowMode] = useState('period'); // period || startYear

	const [data, setData] = useState([]);
	const [headers, setHeaders] = useState({cells: []});

	useEffect(() => {
		QueryWrapper(
			fetch(`${server}/api/v1/reporting/pr_dashboard/paid_free_table`, {
				method: 'POST',
				headers: {
					'Content-Type': `application/json`,
					Authorization: `Bearer ${getToken()}`
				},
				body: JSON.stringify({
					from: showMode === 'period'
						? moment(formattedDateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD')
						: `${moment(formattedDateTo, 'DD.MM.YYYY').year()}-01-01`,
					to: moment(formattedDateTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
				})
			}),
			res => {
				// setHeaders({
				// 	cells: [
				// 		{
				// 			id: 'main',
				// 			label: ``
				// 		},
				// 		...res.data.federal.map(item => ({
				// 			id: item.infopovod_name,
				// 			label: item.infopovod_name
				// 		}))
				// 	]
				// });
				setData(res.data.federal)
			}
		).then(res => res);
	}, [showMode]);

	const HEADER_ROW_SPAN =
		data.length > 1 ? data.length + 2 : data.length + 1;

	return (
		<>
			<div className={styles.Title}>
				{`Федеральные PR-компании и их PR-value с ${showMode === 'period' ? formattedDateFrom : '01.01'} по ${formattedDateTo}`}
			</div>

			<div className={styles.Actions}>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowMode('period')}
					classes={{
						root: classnames(
							styles.Action,
							showMode === 'period' && styles.Action_selected
						)
					}}
				>
					За период
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowMode('startYear')}
					classes={{
						root: classnames(
							styles.Action,
							showMode === 'startYear' && styles.Action_selected
						)
					}}
				>
					С начала года
				</Button>
			</div>

			<Table
				header={headers}
				rows={data}
				settings={{
					withoutSort: true
				}}
			/>


			<TableContainer>
				<MaterialTable className={styles.PRTable} size="medium" theme="black">
					<TableHead>
						<TableRow>
							<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
								PR-кампания
							</TableCell>
							<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
								Направление бизнеса
							</TableCell>
							<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
								<b>Сумма PR-Value</b>
							</TableCell>
							{/* <TableCell rowSpan={HEADER_ROW_SPAN} align="center"> */}
							{/*	Макрорегион */}
							{/* </TableCell> */}
							{/* <TableCell colSpan={2} align="center"> */}
							{/*	Всего */}
							{/* </TableCell> */}
							<TableCell colSpan={2} align="center">
								Неорганические
							</TableCell>
							<TableCell colSpan={2} align="center">
								Органические
							</TableCell>
						</TableRow>
						<TableRow>
							{/* <TableCell align="center">Публикации</TableCell> */}
							{/* <TableCell align="center">Аудитория</TableCell> */}
							<TableCell align="center">Публикации</TableCell>
							<TableCell align="center">Аудитория</TableCell>
							<TableCell align="center">Публикации</TableCell>
							<TableCell align="center">Аудитория</TableCell>
						</TableRow>
					</TableHead>

						{data.map(item => {
							return (
								<TableBody>
									<TableRow key={item.infopovod_name}>
										<TableCell rowSpan={HEADER_ROW_SPAN}>
											{item.infopovod_name}
										</TableCell>
										<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
											{item.magistral_theme || 'Без направления бизнеса'}
										</TableCell>
										<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
											<b>{item.stats[0].pr_value}</b>
										</TableCell>
										{/* <TableCell rowSpan={HEADER_ROW_SPAN} align="center"> */}
										{/*	{item.stats[0].name} */}
										{/* </TableCell> */}
										{/* <TableCell rowSpan={HEADER_ROW_SPAN} align="center"> */}
										{/*	{item.stats[0].free.posts + item.stats[0].paid.posts} */}
										{/* </TableCell> */}
										{/* <TableCell rowSpan={HEADER_ROW_SPAN} align="center"> */}
										{/*	{item.stats[0].free.audience + item.stats[0].paid.audience} */}
										{/* </TableCell> */}

										{/* неоргганика = платные */}
										<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
											{item.stats[0].paid.posts}
										</TableCell>
										<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
											{item.stats[0].paid.audience}
										</TableCell>

										{/* оргганика = бесплатные */}
										<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
											{item.stats[0].free.posts}
										</TableCell>
										<TableCell rowSpan={HEADER_ROW_SPAN} align="center">
											{item.stats[0].free.audience}
										</TableCell>
									</TableRow>
								</TableBody>
							)
						})}
				</MaterialTable>
			</TableContainer>
		</>
	)
};

export default PRCompanyTable;
