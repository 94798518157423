// @flow

// Libraries
import React from 'react';
import { Button, Checkbox, Modal, TextField } from '@material-ui/core';

// Components
import StyledPaper from '@components/StyledPaper';

// Styles
import styles from './styles.sass';
import { StyledFormControlLabel } from '../../../Main/Components';
import { MenuItem, Select } from '@mui/material';


const SMIModal = ({ showModal, setShowModal, modalData, setModalData, handleSave }: any) => {
  const editModalField = (editedObj) => setModalData(prev => ({
    ...prev,
    content: {
      ...prev.content,
      ...editedObj
    }
  }));

  const { content } = modalData;

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <StyledPaper>
        <div className={styles.Modal__Title}>Редактировать СМИ</div>
        <TextField
          label="Название СМИ"
          variant="filled"
          classes={{root: styles.Modal__TextField}}
          value={content.name}
          onChange={({ target: { value }}) => editModalField({ name: value })}
          disableAutoFocus
        />
        <TextField
          label="PR Value ($)"
          variant="filled"
          classes={{root: styles.Modal__TextField}}
          value={content.pr_value}
          onChange={({ target: { value }}) => editModalField({ pr_value: value })}
          disableAutoFocus
        />
        <Select
          value={content.level_id}
          className={styles.Modal__TextField}
          onChange={({ target: { value }}) => editModalField({ level_id: +value })}
          disableAutoFocus
        >
          <MenuItem value={3}>Федеральный</MenuItem>
          <MenuItem value={2}>Региональный</MenuItem>
          <MenuItem value={1}>Зарубежный</MenuItem>
        </Select>
        <div className={styles.Modal__Checkbox}>
          <StyledFormControlLabel
            control={<Checkbox checked={content.top} />}
            label='Топ СМИ'
            onChange={({ target: { checked }}) => editModalField({ top: checked })}
          />
        </div>
        <div className={styles.Modal__Actions}>
          <Button
            classes={{root: styles.Modal__Button}}
            onClick={() => setShowModal(false)}
          >
            Отменить
          </Button>
          <Button
            classes={{root: styles.Modal__Button}}
            color="access"
            style={{
              background: '#00B956',
              color: '#ffffff',
              marginLeft: '10px'
            }}
            onClick={() => handleSave()}
          >
            Сохранить
          </Button>
        </div>
      </StyledPaper>
    </Modal>
  )
}


export default SMIModal;
