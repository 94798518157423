// @flow

// Libraries
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';

// Components
import RowChart from '../RowChart';
import BarChart from '../BarChart';
import BarSplineChart from '../BarSplineChart';
import CombineRowBarChart from '../CombineRowBarChart';
import RowChartWithZoom from '../RowChartWithZoom';
import PieChart from '../PieChart';
import RowStackedChart from '../RowStackedChart';

// Styles
import styles from './styles.sass';

// Types
import { Series as TypeSeries } from '../../types';
import DynamicRowChartWithZoom from "../DynamicRowChartWithZoom";

type Types = {
  type?: string,
  title?: string,
  dataSource: Object<any>,
  series: TypeSeries,
  argumentField?: string,
  additionalRender?: () => void,
  style?: {
    container?: Object<any>,
    root?: Object<any>
  },
  classes?: {
    root?: Object<any>
  },
  row?: boolean,
  withDefaultTooltip?: boolean,
  tooltipText?: string,
  sortDirection?: string,
  range?: {
    startValue?: string,
    endValue?: string
  },
  compact?: boolean,
  Controls?: any,
  onChangeRange?: () => void,
  dividers?: Object<any>,
  variant?: string,
}

// eslint-disable-next-line max-params
const Chart = ({
  type,
  title,
  dataSource,
  series,
  argumentField,
  additionalRender,
  style,
  classes,
  row,
  withDefaultTooltip,
  tooltipText,
  sortDirection,
  range,
  onChangeRange,
  compact,
  dividers,
  variant,
  Controls
}: Types) => {
  // очень нуждается в рефакторинге...

  const ChartComponent = React.useCallback(() => {
    const props = {
      dataSource,
      series,
      argumentField,
      row,
      animation: false,
      withDefaultTooltip,
      sortDirection,
      tooltipText,
      range,
      onChangeRange,
      compact,
      dividers,
      variant
    }

    switch(type) {
      case 'RowChart':
        return <RowChart {...props} />

      case 'BarChart':
        return <BarChart {...props} />

      // для того, чтобы выводить графики на подобии рейтинга инфоповодов.
      case 'StackedBarChart':
        return <BarChart {...props} type='stackedBar' />

      case 'RowStackedChart':
        return <RowStackedChart {...props} />

      case 'BarSplineChart':
        return <BarSplineChart {...props} />

      case 'PieChart':
        return <PieChart {...props} />

      case 'CombineRowBarChart':
        return <CombineRowBarChart {...props} />

      case 'RowChartWithZoom':
        return <RowChartWithZoom {...props} />

      case 'DynamicRowChartWithZoom':
        return <DynamicRowChartWithZoom {...props} />

      default:
        return <RowChart {...props} />
    }
  }, [dataSource, title]);

  const Content = React.useCallback(() => dataSource
    ? ((dataSource.length > 0 && Object.keys(dataSource[0]).length > 1) || Object.keys(dataSource).length > 0)
      ? (
        <>
          <ChartComponent />
          {additionalRender && additionalRender()}
        </>
      )
      : (
        <h2 style={{textAlign: 'center', marginTop: 20}}>Данных не найдено</h2>
      )
    : (
      <div className={styles.Progress}>
        <CircularProgress color="secondary" />
      </div>
    )
  , [dataSource, title]);

  return (
    <div className={styles.Chart} style={{...style?.container}}>
      {title && (
        <div className={styles.Title}>{title}</div>
      )}
      {Controls && Controls}
      <div className={styles.Content}>
        <div style={{width: '100%', ...style?.root }} className={classes?.root}>
          <Content />
        </div>
      </div>
    </div>
  )
}

const MemorizedChart = React.memo(
  Chart,
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps.dataSource, nextProps.dataSource)) {
      return true;
    }
    return false;
  }
);

export default Chart;
