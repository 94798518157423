// @flow

// Libraries

// Constants
import { server } from '@constants/servers';
import QueryWrapper from './QueryWrapper';
import store from '../store/store';
import { getToken } from './articleApi';

// Токен пользователя
const {
  user: {
    token
  },
} = store.getState();

// Получение операторов
export const fetchOperators = async (resolveHandler, rejectHandler) => {
  await QueryWrapper(
    fetch(`${server}/api/v1/monitoring/positions`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

// Обновление (изменение и удаление) менеджеров
export const updateManagers = (updateOperatorsData, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/positions`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({collection: 'positions', positions: updateOperatorsData })
    }),
    resolveHandler,
    rejectHandler
  )
}

// Создание менеджеров
export const createManagers = (createOperatorsData, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/positions`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({collection: 'positions', positions: createOperatorsData })
    }),
    resolveHandler,
    rejectHandler
  )
}
