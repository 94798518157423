// Types
import { CHANGE_SETTINGS } from './types';


// настройки, которые у пользователя в шапке (ПОРЯДОК И ВКЛЮЧЕННЫЕ ОПЕРАТОРЫ)
const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_SETTINGS: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};

export default settingsReducer;
