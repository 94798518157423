// @flow

import * as React from 'react';
import {Button, Input} from '@mui/material';
import UploadFileIcon from '@material-ui/icons/InsertDriveFile';

const buttonStyles = {
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: '1rem',
  letterSpacing: '0.078rem',
  textTransform: 'uppercase',
  padding: '1rem 2.188rem',
  color: '#00B956',
  maxWidth: '15.563rem',
  background: 'rgba(0,185,86,0.1)',
  justifyContent: 'flex-start',
  overflowX: 'hidden'
};

const inputStyles = {
  position: 'absolute',
  width: '15.563rem',
  left: '0',
  pointerEvents: 'all',
  opacity: 0,
  '& input': {
    padding: '0.781rem 0',
  },
  '& div.MuiInputBase-root': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '& .MuiInputAdornment-root': {
    display: 'none',
  },
};

interface UploadButtonProps {
  title: string;
  onChange: (file: any) => void;
}

const UploadButton = ({ title, onChange }: UploadButtonProps) => {
  const [filename, setFilename] = React.useState('');

  const handleFileInputChange = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setFilename(files[0].name);
      onChange(event);
    }
  };

  return (
    <Button startIcon={<UploadFileIcon />} sx={buttonStyles}>
      <span style={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>{filename ? filename : title}</span>
      <Input type='file' hidden sx={inputStyles} onChange={handleFileInputChange} />
    </Button>
)
};

export default UploadButton;