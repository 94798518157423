// @flow

// Libraries
import 'moment/locale/ru';

// Constants
import { server } from '@constants/servers';

// Components
import QueryWrapper from './QueryWrapper';
import store from '../store/store';
import { joinedGetParameters } from '../methods/anyMethods';
import { getToken } from './articleApi';

// Токен пользователя
const {
  user: {
    token
  },
} = store.getState();

// Получение СМИ
export const fetchSMI = ({ page, foundName, branches }, resolveHandler, rejectHandler) => {
  const joinedParams = joinedGetParameters({
    'search[name]': foundName,
    'search[branch_names][]': branches.map(branch => branch.replaceAll('Северо-Западный макрорегион&', '')),
    page,
  });

  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/sources?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

// Редактирование СМИ
export const editSMI = async (smi, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/sources`, {
      method: 'PATCH',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(smi)
    }),
    resolveHandler,
    rejectHandler
  )
};

// Создание СМИ
export const createSMI = async (smi, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/sources`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(smi)
    }),
    resolveHandler,
    rejectHandler
  )
};

// добавление сми из файла
export const uploadSourceRanks = (file, resolveHandler, rejectHandler) => {
  const formData = new FormData();
  formData.append("file", file);

  QueryWrapper(
    fetch(`${server}/api/v1/pr_values/upload_source_rank`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getToken()}`,
      },
      body: formData
    }),
    resolveHandler,
    rejectHandler
  )
};


// скачать файл со СМИ
export const downloadSourceRanks = (resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/pr_values/download_sources`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getToken()}`,
      },
    }),
    resolveHandler,
    rejectHandler
  )
};
