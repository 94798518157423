// @flow

// Libraries

// Constants
import { server } from '@constants/servers';
import QueryWrapper from './QueryWrapper';
import store from '../store/store';
import { joinedGetParameters } from '../methods/anyMethods';
import { getToken } from './articleApi';

const {
  user: {
    token
  },
} = store.getState();

export const getUsers = (resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/users`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export const createUser = (newUserData, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/users`, {
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(newUserData)
    }),
    resolveHandler,
    rejectHandler
  )
};

export const editUser = (editedUserData, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/users/${editedUserData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(editedUserData)
    }),
    resolveHandler,
    rejectHandler
  )
};

export const deleteUser = async (userID, resolveHandler, rejectHandler) => {
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/users/${userID}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};

export const searchUser = async (userQuery, resolveHandler, rejectHandler) => {
  const joinedParams = joinedGetParameters({
    limit: 10000,
    page: 1,
    user_query: userQuery
  });

  // title у артикла
  QueryWrapper(
    fetch(`${server}/api/v1/monitoring/users?${joinedParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${getToken()}`
      }
    }),
    resolveHandler,
    rejectHandler
  )
};
