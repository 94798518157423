// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Button, IconButton, Input, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Page from '@components/Page';

// Styles
import styles from './styles.sass';

const Request = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [setFormData] = React.useState({
    login: '',
    password: '',
    position: ''
  });
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = () => {
    setSuccess(true);
  };

  return (
    <Page withoutFooter withoutLogin>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          {!success ? (
            <>
              <div className={styles.Title}>Запрос на создание аккаунта</div>
              <Input
                type='text'
                className={styles.Input}
                placeholder='Логин или Email'
                onChange={(e) => setFormData(prev => ({ ...prev, login: e.target.value }))}
                fullWidth
              />
              <Input
                type={showPassword ? 'text' : 'password'}
                className={`${styles.Input} ${styles.Input_password}`}
                placeholder='Пароль'
                onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                style={{marginBottom: 40}}
                fullWidth
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(prev => !prev)}
                      style={{padding: 0}}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
              <Input
                type='text'
                className={styles.Input}
                placeholder='Должность'
                onChange={(e) => setFormData(prev => ({ ...prev, position: e.target.value }))}
                fullWidth
              />
              <Button className={styles.Button} onClick={handleSubmit}>Отправить</Button>
            </>
          ) : (
            <div style={{height: '100%'}}>
              <Link to='/login'>
                <div className={styles.Back} onClick={() => setSuccess(false)}>Назад</div>
              </Link>
              <div className={styles.Successful}>Запрос успешно отправлен!</div>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
};

export default Request;
