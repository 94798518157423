// @flow

// Libraries

// Constants
import { server } from '@constants/servers';

// QueryWrapper не нужен, так как логика QueryWrapper не совместима с этим запросом
export const logIn = async (data, resolveHandler, rejectHandler) => {
  await fetch(`${server}/api/v1/account/login`, {
    method: 'POST',
    headers: {
      'Content-Type': `application/json`
    },
    body: JSON.stringify(
      {
        username: data.login,
        password: data.password
      }
    ),
  })
    .then(response => response.json())
    .then(user => {
      if (!user.error) {
        resolveHandler(user);
      } else {
        rejectHandler(user.error);
      }
    })
};

export default { logIn }
