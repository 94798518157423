// @flow

// Libraries
import * as React from 'react';

// Components
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const H1 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '96px',
    lineHeight: '144px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '-1.5px'
  }
})((props) => <Typography variant="h1" {...props} />);


export const H2 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '60px',
    lineHeight: '90px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '-0.5px'
  }
})((props) => <Typography variant="h2" {...props} />);

export const H3 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '48px',
    lineHeight: '72px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0'
  }
})((props) => <Typography variant="h3" {...props} />);

export const H4 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '34px',
    lineHeight: '51px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.25px'
  }
})((props) => <Typography variant="h4" {...props} />);

export const H5 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '36px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0'
  }
})((props) => <Typography variant="h5" {...props} />);

export const H6 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px'
  }
})((props) => <Typography variant="h6" {...props} />);

export const SUBTITLE1 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px'
  }
})((props) => <Typography variant="subtitle1" {...props} />);

export const SUBTITLE2 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.1px'
  }
})((props) => <Typography variant="subtitle2" {...props} />);

export const BODY1 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.5px'
  }
})((props) => <Typography variant="p" {...props} />);

export const BODY2 = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.25px',
    textAlign: 'left'
  }
})((props) => <Typography variant="p" {...props} />);

export const BUTTON = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '1.25px'
  }
})((props) => <Typography variant="p" {...props} />);

export const CAPTION = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.4px'
  }
})((props) => <Typography variant="p" {...props} style={{ color: props.color, ...props.style }} />);

export const OVERLINE = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '1.5px'
  }
})((props) => <Typography variant="p" {...props} />);

export default {
  H1
}
