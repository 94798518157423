// @flow

// Libraries
import * as React from 'react';

// Components
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import QueryWrapper from '../../../../api/QueryWrapper';

// Constants
import { server } from '@constants/servers';

// Styles
import styles from './styles.sass';
import useAuth from '../../../../hooks/useAuth';
import { getToken } from '../../../../api/articleApi';

// Types
type SearchBlockTypes = {
  onSubmitHandler: () => void;
  renderSearchButton?: any;
};

const SearchBlock = ({
  onSubmitHandler,
  renderSearchButton
}: SearchBlockTypes) => {
  const {
    user: {
      token
    }
  } = useAuth();
  const [searchValue, setSearchValue] = React.useState('');
  const [history, setHistory] = React.useState({
    fullHistory: [],
    filteredHistory: []
  });
  const [showHistory, setShowHistory] = React.useState(false);
  const [selectedHistory, setSelectedHistory] = React.useState(null);

  const fetchHistory = async () => {
    await QueryWrapper(
      fetch(`${server}/api/v1/monitoring/posts/search_history`, {
        method: 'GET',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${getToken()}`
        }
      }),
      (res) => setHistory(prev => ({
        ...prev,
        fullHistory: res,
        filteredHistory: res
      }))
    )
  };

  const filterHistory = () => {
    setHistory(prev => ({
      ...prev,
      filteredHistory: prev.fullHistory.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()))
    }));
  }

  React.useEffect(() => {
    fetchHistory();
  }, []);

  React.useEffect(() => {
    filterHistory();
    setSelectedHistory(null);
  }, [searchValue]);

  React.useEffect(() => {
    filterHistory();
  }, [history.fullHistory])

  const handleSearch = value => {
    setSearchValue(() => {
      onSubmitHandler(value);
      // fetchHistory();
      // setShowHistory(!hideHistory);
      setSearchValue('');
      return value;
    });
  };

  const handleSearchHotkeys = (e) => {
    if (e.key === 'Enter') {
      handleSearch(selectedHistory === null ? searchValue : history.filteredHistory[selectedHistory]);
    }

    const [topLimit, bottomLimit] = [0, history.filteredHistory.length - 1]

    if (e.key === 'ArrowUp') {
      setSelectedHistory(prev => prev === null ? 0 : prev === topLimit ? null : prev - 1)
    }

    if (e.key === 'ArrowDown') {
      setSelectedHistory(prev => prev === null ? 0 : prev === bottomLimit ? topLimit : prev + 1)
    }
  }

  return (
    <Grid
      lg={12}
      xl={12}
      container
      direction="row"
      className={styles.SearchBlock}
    >
      <div className={styles.FixedSearchWrapper}>
        <div
          className={styles.Container}
          onFocus={() => setShowHistory(true)}
          onBlur={({ currentTarget }) => {
            // js хак (нужно для того, чтобы событие onClick у детей отрабатывало)
            setTimeout(() => {
              if (!currentTarget.contains(document.activeElement)) {
                setShowHistory(false);
              }
            }, 150);
          }}
        >
          <TextField
            placeholder="Поиск"
            style={{
              height: '36px',
              flex: 'auto',
              margin: '0 8px 0 0'
            }}
            color='primary'
            variant="outlined"
            icon
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: 'rgb(0, 185, 86)' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => searchValue !== '' && handleSearch('', true)}
                  style={{cursor: 'pointer'}}
                >
                  <CloseIcon style={{ color: searchValue === '' ? 'gray' : 'rgb(0, 185, 86)' }} />
                </InputAdornment>
              )
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleSearchHotkeys}
            size='small'
          />
        </div>
      </div>
      {renderSearchButton
        ? renderSearchButton({
          disabled: searchValue === '',
          onClick: () => handleSearch(searchValue)
        })
        : (
          <Button
            variant="contained"
            color="access"
            style={{
              width: '94px',
              background: '#00B956',
              color: '#ffffff',
            }}
            onClick={() => searchValue !== '' && handleSearch(searchValue)}
          >
            Найти
          </Button>
        )
      }
    </Grid>
  )
};

// Exports
export default SearchBlock;
