// @flow

// Libraries
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

// Components
import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Legend,
  Margin,
  MinorTickInterval,
  Point,
  Series,
  Tooltip,
  ValueAxis
} from 'devextreme-react/chart';
import RangeSelector, {
  Scale,
  Size,
  SliderMarker
} from 'devextreme-react/range-selector';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { loadMessages, locale } from 'devextreme/localization';
import { Button } from '@material-ui/core';
import { divideGraphsValue } from '../../../../../utils/methods';
import Loader from '../../../../../components/Loader';
import { formatArgumentLabel } from '../../helpers';
import styles from './styles.sass';

const SHOW_MODES = {
  fromPeriod: 'fromPeriod',
  fromStartYear: 'fromStartYear',
  months: 'months',
  weeks: 'weeks',
  days: 'days'
};

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_FORMAT_DEFAULT = 'YYYY/MM/DD';

export const getRanges = ({ startValue, endValue }) => {
  // вход: { startValue: '01.01.2020', endValue: '05.03.2020' }
  // выход: ['01.01.2020 - 31.01.2021', '01.02.2020 - 28.02.2020', '01.03.2020 - 05.03.2020']

  const formattedStartValue = moment(startValue, DATE_FORMAT);
  const formattedEndValue = moment(endValue, DATE_FORMAT);

  const countOfDaysBetweenRange = Math.abs(
    formattedEndValue.diff(formattedStartValue, 'days')
  );

  if (countOfDaysBetweenRange > 31) {
    const isWeeks = countOfDaysBetweenRange <= 183;
    const diff = moment.duration(
      Math.abs(formattedEndValue.diff(formattedStartValue))
    );
    const size =
      diff.asDays() <= 183
        ? diff.asWeeks() + 0.1 // 0.1 - погрешность
        : diff.asMonths() + 0.1; // 0.1 - погрешность

    return (
      [...new Array(Math.ceil(size))]
        // eslint-disable-next-line max-params
        .reduce((reducer, _, i, arr) => {
          const startVal = moment(
            i === 0 ? formattedStartValue : reducer[i - 1].split('-')[1],
            DATE_FORMAT
          ).add(i === 0 ? 0 : 1, 'day');
          const endVal =
            arr.length !== i
              ? startVal.clone().endOf(isWeeks ? 'week' : 'month')
              : formattedEndValue.clone();

          return [
            ...reducer,
            `${startVal.format(DATE_FORMAT)} - ${endVal.format(DATE_FORMAT)}`
          ];
        }, [])
    );
  }

  return [...new Array(countOfDaysBetweenRange + 1)].map((_, i) =>
    formattedStartValue
      .clone()
      .add(i, 'day')
      .format(DATE_FORMAT)
  );
};

export const sortByDates = arr =>
  [...arr].sort((a, b) => {
    const aDate = a.date.includes('-') ? a.date.split('-')[0] : a.date;
    const bDate = b.date.includes('-') ? b.date.split('-')[0] : b.date;

    return (
      moment(aDate, DATE_FORMAT).valueOf() -
      moment(bDate, DATE_FORMAT).valueOf()
    );
  });

const DynamicRowChartWithZoom = ({
  dataSource,
  series,
  tooltipText,
  range: period,
  onChangeRange
}: any) => {
  const START_VALUE = dataSource.range.startOf;
  const END_VALUE = dataSource.range.endOf;
  const VALUES_OF_SHOW_MODES = {
    [SHOW_MODES.fromPeriod]: {
      startValue: period.startValue,
      endValue: END_VALUE
    },
    [SHOW_MODES.fromStartYear]: {
      startValue: moment(END_VALUE, DATE_FORMAT)
        .startOf('year')
        .format(DATE_FORMAT),
      endValue: END_VALUE
    },
    [SHOW_MODES.months]: {
      startValue: START_VALUE,
      endValue: END_VALUE
    },
    [SHOW_MODES.weeks]: {
      startValue: moment(END_VALUE, DATE_FORMAT)
        .subtract(3, 'month')
        .format(DATE_FORMAT),
      endValue: END_VALUE
    },
    [SHOW_MODES.days]: {
      startValue: moment(END_VALUE, DATE_FORMAT)
        .subtract(1, 'month')
        .format(DATE_FORMAT),
      endValue: END_VALUE
    }
  };

  const getLabel = date => {
    const splittedDate = date.split('-');

    if (splittedDate.length === 1) {
      const monthStr = moment(date, DATE_FORMAT)
        .format('MMM')
        .substring(0, 3);
      return `${moment(date, DATE_FORMAT).format('DD')} ${monthStr}`;
    }

    const [startValue, endValue] = splittedDate;

    if (
      moment(startValue, DATE_FORMAT)
        .startOf('month')
        .format(DATE_FORMAT) ===
        moment(startValue, DATE_FORMAT).format(DATE_FORMAT) &&
      moment(endValue, DATE_FORMAT)
        .endOf('month')
        .format(DATE_FORMAT) ===
        moment(endValue, DATE_FORMAT).format(DATE_FORMAT)
    ) {
      return moment(startValue, DATE_FORMAT).format('DD.MM.YYYY') + '_month';
    }

    return `${moment(startValue, DATE_FORMAT).format('DD.MM')} - ${moment(
      endValue,
      DATE_FORMAT
    ).format('DD.MM')}`;
  };

  const dataWrapper = data => {
    return data
      ? sortByDates(
          Object.entries(data).map(([date, values]) => {
            return {
              ...values,
              label: getLabel(date),
              date
            };
          })
        )
      : null;
  };

  const [wrappedData, setWrappedData] = React.useState(
    dataWrapper(dataSource.data)
  );
  const [range, setRange] = React.useState({
    startValue: START_VALUE,
    endValue: END_VALUE
  });
  const [showMode, setShowMode] = React.useState(SHOW_MODES.months);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setWrappedData(dataWrapper(dataSource.data));
  }, [dataSource]);

  const countOfDaysInSelectedRange = moment(range.endValue, DATE_FORMAT).diff(
    moment(range.startValue, DATE_FORMAT),
    'days'
  );

  React.useEffect(() => {
    const getShowMode = () => {
      if (
        range.startValue ===
          VALUES_OF_SHOW_MODES[SHOW_MODES.fromPeriod].startValue &&
        range.endValue === VALUES_OF_SHOW_MODES[SHOW_MODES.fromPeriod].endValue
      ) {
        return SHOW_MODES.fromPeriod;
      }

      if (
        range.startValue ===
          VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].startValue &&
        range.endValue ===
          VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].endValue
      ) {
        return SHOW_MODES.fromStartYear;
      }

      if (countOfDaysInSelectedRange <= 31) {
        return SHOW_MODES.days;
      }

      if (countOfDaysInSelectedRange <= 183) {
        return SHOW_MODES.weeks;
      }

      return SHOW_MODES.months;
    };

    setShowMode(getShowMode());
  }, [range, dataSource?.range]);

  const rangeData = React.useMemo(() => {
    return wrappedData.map(day => ({
      ...day,
      date: moment(day.date, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)
    }));
  }, []);

  loadMessages(ruMessages);
  locale(navigator.language);

  const handleChangeRange = async (startValue, endValue) => {
    if (!loading) {
      setLoading(true);
      const updatedRange = { startValue, endValue };
      setRange(updatedRange);
      const newData = await onChangeRange(updatedRange);
      setWrappedData(dataWrapper(newData.data));
    }

    setLoading(false);
  };

  const onLegendClick = e =>
    e.target.isVisible() ? e.target.hide() : e.target.show();

  return (
    <React.Fragment>
      <div className={styles.Actions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleChangeRange(
              VALUES_OF_SHOW_MODES[SHOW_MODES.fromPeriod].startValue,
              VALUES_OF_SHOW_MODES[SHOW_MODES.fromPeriod].endValue
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.fromPeriod && styles.Action_selected
            )
          }}
        >
          За период
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleChangeRange(
              VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].startValue,
              VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].endValue
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.fromStartYear && styles.Action_selected
            )
          }}
        >
          С начала года
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleChangeRange(
              VALUES_OF_SHOW_MODES[SHOW_MODES.months].startValue,
              VALUES_OF_SHOW_MODES[SHOW_MODES.months].endValue
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.months && styles.Action_selected
            )
          }}
        >
          Месяцы
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleChangeRange(
              VALUES_OF_SHOW_MODES[SHOW_MODES.weeks].startValue,
              VALUES_OF_SHOW_MODES[SHOW_MODES.weeks].endValue
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.weeks && styles.Action_selected
            )
          }}
        >
          Недели
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleChangeRange(
              VALUES_OF_SHOW_MODES[SHOW_MODES.days].startValue,
              VALUES_OF_SHOW_MODES[SHOW_MODES.days].endValue
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.days && styles.Action_selected
            )
          }}
        >
          Дни
        </Button>
      </div>
      <div className={styles.Container}>
        {loading && (
          <div className={styles.Loader}>
            <Loader />
          </div>
        )}
        <Chart
          palette={series.map(({ color }) => color)}
          dataSource={wrappedData}
          onLegendClick={onLegendClick}
        >
          {series.map(({ key, name }) => (
            <Series
              key={name}
              name={name}
              argumentField="label"
              valueField={key}
            />
          ))}
          <ArgumentAxis
            label={{ customizeText: formatArgumentLabel }}
            visualRange={range}
          />
          <ValueAxis
            label={{
              customizeText: ({ value }) => divideGraphsValue(value)
            }}
          />
          <CommonSeriesSettings>
            <Point size={7} />
          </CommonSeriesSettings>
          <Legend verticalAlignment="top" horizontalAlignment="right" />
          <Tooltip
            enabled
            shared
            customizeTooltip={pointInfo =>
              customizeTooltip({ pointInfo, tooltipText, showMode })
            }
          />
        </Chart>
        <RangeSelector
          dataSource={rangeData}
          value={{
            startValue: moment(range.startValue, DATE_FORMAT).format(
              DATE_FORMAT_DEFAULT
            ),
            endValue: moment(range.endValue, DATE_FORMAT).format(
              DATE_FORMAT_DEFAULT
            )
          }}
          onValueChanged={props => {
            const { value } = props;
            const [from, to] = value;
            if (
              moment(from).format(DATE_FORMAT_DEFAULT) !==
                moment(range.startValue, DATE_FORMAT).format(
                  DATE_FORMAT_DEFAULT
                ) ||
              moment(to).format(DATE_FORMAT_DEFAULT) !==
                moment(range.endValue, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)
            ) {
              const month1 = moment(from).format('MMM');
              const month2 = moment(to).format('MMM');
              const monthsIsNotEqual = month1 !== month2;
              const countOfDaysBetweenDates = moment(to).diff(
                moment(from),
                'days'
              );

              handleChangeRange(
                moment(from).format(DATE_FORMAT),
                moment(to)
                  .subtract(
                    Number(monthsIsNotEqual && countOfDaysBetweenDates >= 27),
                    'day'
                  )
                  .format(DATE_FORMAT)
              );
            }
          }}
        >
          <Size height={120} />
          <Margin left={40} right={80} />
          <Scale
            valueType="datetime"
            startValue={moment(START_VALUE, DATE_FORMAT).format(
              DATE_FORMAT_DEFAULT
            )}
            endValue={moment(END_VALUE, DATE_FORMAT).format(
              DATE_FORMAT_DEFAULT
            )}
            minorTickInterval="week"
            tickInterval="week"
          >
            <MinorTickInterval days={1} />
          </Scale>
          <SliderMarker format="dd MMMM" />
        </RangeSelector>
      </div>
    </React.Fragment>
  );
};

const customizeTooltip = ({
  pointInfo,
  tooltipText = { days: 'Значения за', average: 'Средние значения за' },
  showMode
}) => {
  const { seriesName, value, points } = pointInfo;
  const info = `<div>${seriesName}: ${divideGraphsValue(value)}</div>`;
  const label = points[0].point.data.label.replace('_month', '');
  const averageModes = [SHOW_MODES.months, SHOW_MODES.weeks];
  // если режим просмотра - месяцы и заголовок не содержит цифры, то переводим в формат месяца, иначе - дня
  const formattedLabel =
    averageModes.includes(showMode) && !/\d/.test(label)
      ? moment(label, 'MMM').format('MMMM')
      : label.includes('-') // если это диапазон дат
      ? label
      : moment(label, 'DD.MM.YYYY').format('MMMM');

  const { days: daysText } = tooltipText;

  const text = showMode === SHOW_MODES.months ? '' : daysText;

  return {
    html: `
      <div>
        ${text} ${
      showMode === SHOW_MODES.months
        ? formattedLabel.slice(0, 1).toUpperCase() +
          formattedLabel.slice(1, formattedLabel.length)
        : formattedLabel
    }
      </div>
      ${info}
    `
  };
};

export default DynamicRowChartWithZoom;
