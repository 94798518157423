// @flow

// Libraries
import React from 'react';

// Components
// Styles
import styles from './styles.sass';


const MobileBlockScreen = () => {
  return (
    <div className={styles.BlockScreen}>
      Эта страница доступна только в режиме полноэкранного просмотра с компьютера
    </div>
  )
}

export default MobileBlockScreen;
