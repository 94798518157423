import React from 'react';
import { HANDBOOK_TAG_TYPES } from "../../constants";
import { Grid } from "@material-ui/core";
import styles from './styles.sass';

type TagsForSelectedArticlesTypes = {
	tags: Array<Object>;
	removeSelectedTag: () => void;
	setIsOpenHandbookModal: () => void;
	setSelectedPrCampaign: () => void;
	selectedPrCampaign: Array<number>;
};

/**
 * Блок с тегами новостей выделенных чекбоксами
 * **/
const TagsForSelectedArticles = ({
	tags,
	removeSelectedTag,
	setIsOpenHandbookModal,
	selectedPrCampaign,
	setSelectedPrCampaign,
}: TagsForSelectedArticlesTypes) => {

	const clickHandler = (tag) => {
		if (tag?.tag_type === 'pr_campaign') {
			if (selectedPrCampaign.find(i => i.id === tag?.tag_id)) {
				setSelectedPrCampaign(selectedPrCampaign.filter(i => i !== tag?.tag_id))
			} else {
				setSelectedPrCampaign([...selectedPrCampaign, {id: tag?.tag_id, name: tag?.title}])
			}
		}
	};

	return (
		<Grid lg={12} xl={12}>
			<div
				className={styles.Tags}
				style={{ margin: '10px 0 0' }}
			>
				{tags.map(tag => {
					if (tag.show) {
						return (
							<div
								className={`
									${styles.Tag} 
									${tag?.tag_type === 'pr_campaign' ? styles.PR : ''}  
									${selectedPrCampaign.find(i => i === tag?.tag_id) ? styles.Active : ''}
								`}
								onClick={() => clickHandler(tag)}
							>
								<div>{tag.title}</div>
								<div
									className={styles.Tag__Remove}
									onClick={(e) => {
										e.stopPropagation();
										removeSelectedTag(tag)
									}}
								/>
							</div>
						);
					}
				})}
				<div
					className={`${styles.Tag} ${styles.Tag_add}`}
					onClick={() => {
						setIsOpenHandbookModal({
							show: true,
							type: HANDBOOK_TAG_TYPES.selected
						});
					}}
				>
					<div className={styles.Tag__Add}>+</div>
					<div>Добавить теги к выделенным новостям</div>
				</div>
			</div>
		</Grid>
	)
}

export default TagsForSelectedArticles;