// @flow

// Libraries
import * as React from 'react';

// Styles
import styles from './styles.sass';


const getDecl = number => (
  (number > 1 && number < 5) || (number > 20 && ([2, 3, 4].includes(number % 10)))
    ? 'новости'
    : number > 20 && (number % 10 === 1)
      ? 'новость'
      : 'новостей'
)

const ShowMore = ({ count, label }: any) => {
  return count ? (
    <div className={styles.Button}>
      {label || (
        <>
          Показать все
          <span className={styles.Count}>{count}</span>
          {getDecl(count)}
        </>
      )}
    </div>
  ) : <></>
}

export default ShowMore;
