// @flow

// Libraries
import * as React from 'react';
import { Editor } from '@tinymce/tinymce-react';

// Components
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { Button } from '@material-ui/core';
import StatusBar from '@components/StatusBar';

// Styles
import styles from './styles.sass';

const DocumentEditor = ({ note, onSave, onDelete, canEdit = true, viewMode = false }: any) => {
  const [updatedNote, setUpdatedNote] = React.useState(note);
  const screenIsMobile = useMediaQuery('(max-width: 568px)');

  const save = (newPartialState) => {
    setUpdatedNote(prev => ({
      ...prev,
      ...newPartialState
    }));
  };

  React.useEffect(() => {
    setUpdatedNote(note);
  }, [note]);

  return (
    <div className={styles.Editor}>
      {!viewMode && (
        <div style={{marginBottom: 15}}>
          {canEdit && (
            <Button
              variant='outlined'
              color='primary'
              style={{marginRight: 10}}
              onClick={() => onSave(updatedNote)}
            >
              Сохранить
            </Button>
          )}
          {!canEdit && (
            <Button
              variant='outlined'
              color='primary'
              style={{marginRight: 10}}
              onClick={onDelete}
            >
              Удалить
            </Button>
          )}
          <Button
            variant='outlined'
            color='primary'
            onClick={() => navigator.clipboard.writeText(`${window.location.href.split('/')[2]}/notes/${note.slug}`)}
            disabled={canEdit}
          >
            Скопировать ссылку
          </Button>
        </div>
      )}
      <StatusBar
        id={updatedNote.id}
        documentName={updatedNote.title}
        onNameChange={name => save({ title: name })}
        hideSaving={viewMode || !canEdit}
        disabled={!canEdit}
      />
      <Editor
        id={updatedNote.id}
        key={updatedNote.id}
        apiKey="95ch3gekmundn76wc19fag7we9nnov2vkv4exlfz3yqjhtfl"
        onEditorChange={content => save({ content })}
        value={updatedNote.content}
        init={{
          icons: "jam",
          skin: "fabric",
          content_css: "document",
          resize: false,
          menubar: "",
          content_style: `body {padding: ${screenIsMobile ? "10px" : "40px"}}`
        }}
        toolbar={!viewMode || canEdit ? [
          {
            name: 'history', items: [ 'undo', 'redo' ]
          },
          {
            name: 'styles', items: [ 'styleselect' ]
          },
          {
            name: 'formatting', items: [ 'bold', 'italic']
          },
          {
            name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ]
          },
          {
            name: 'indentation', items: [ 'outdent', 'indent' ]
          }
        ]: []}
        disabled={!canEdit}
      />
    </div>
  );
}

export default DocumentEditor;
