// @flow

// Libraries
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Methods
import { sortByPriority } from '../../methods/articlesMethods';

// Assets
import { ExpandLess } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

// Styles
import styles from './styles.sass';


const GRID = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  position: "relative",
  display: "flex",
  width: '100%',
  userSelect: "none",
  padding: GRID * 2,
  margin: `0 0 ${GRID}px 0`,
  background: isDragging ? "rgb(0, 168, 78)" : "#fff",
  color: isDragging ? "#fff" : "black",
  borderRadius: '7px',
  border: '1px solid #E5E5E5',
  ...draggableStyle
});

const listStyle = {
  width: '100%',
  background: 'transparent',
  padding: GRID,
};


const DragCol = ({ items = [], onChange, loading = false, setLoading, firstElIsBlocked }: any) => {
  // подготавливаем массив новостей к работе с компонентом
  const changePriorityAndSort = (items) => {
    const sortedItems = sortByPriority(items, firstElIsBlocked);
    return sortedItems.map((item, i) => ({
      ...item,
      custom_priority: item.custom_priority === 0
        ? i + 1
        : item.custom_priority
    }))
  }

  // нужно для того, чтобы сделать изменяющийся номер элемента при перемещении
  const [currentItems, setCurrentItems] = React.useState(changePriorityAndSort(items));
  const [newIndex, setNewIndex] = React.useState(null);

  React.useEffect(() => setCurrentItems(changePriorityAndSort(items)), [items]);

  const handleDrag = ({ source }) => {
    setLoading(true);
    const updatedItems = [...currentItems];
    const sourcePriority = updatedItems[source.index].custom_priority;

    // Меняем приоритетность местами
    updatedItems[source.index] = {
      ...updatedItems[source.index],
      custom_priority: updatedItems[newIndex].custom_priority
    }
    updatedItems[newIndex] = {
      ...updatedItems[newIndex],
      custom_priority: sourcePriority
    }

    handleChange(updatedItems);
  };

  const handleChange = updatedItems => onChange(updatedItems);

  // переместить наверх
  const handleSetTop = (itemToTop, index) => {
    setLoading(true);
    const updatedItems = [...currentItems].map((item, curIndex) => ({
      ...item,
      custom_priority: curIndex === index
        ? 1
        : item.custom_priority + 1
    }));

    handleChange(updatedItems);
  };

  const Loading = () => (
    <div className={styles.Loader}>
      <CircularProgress color="primary" />
    </div>
  );

  const Content = () => (
    currentItems.map((item, index) => (
      <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              {index + 1}
            </div>
            <div style={{width: '1px', background: '#00B956', marginLeft: '15px'}} />
            <div style={{display: 'flex', alignItems: 'center', padding: '0 15px'}}>
              {item.title}
            </div>
            <div
              style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: 'auto'}}
              onClick={() => handleSetTop(item, index)}
            >
              {index > (firstElIsBlocked ? 1 : 0) ? <ExpandLess color="primary" /> : <></>}
            </div>
          </div>
        )}
      </Draggable>
    ))
  );

  return (
    <div className={styles.DragCol}>
      <DragDropContext
        onDragEnd={handleDrag}
        onDragUpdate={({ destination }) => setNewIndex(destination.index)}
      >
        <Droppable droppableId="droppable1">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={listStyle}
            >
              {loading ? <Loading /> : <Content />}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default DragCol;
