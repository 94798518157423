// Libraries
import React, {useMemo} from 'react';
import moment from 'moment';
import * as classnames from 'classnames';

// Components
import Highlighter from 'react-highlight-words';
import { Grid } from '@material-ui/core';
import { BODY1, BODY2, CAPTION } from '@components/Typography';

// Constants
import { OPERATORS, OPERATORS_IDS } from '../../../../constants/general';
import { dictionary, symbols } from '../../../../constants/dictionary';

// Styles
import styles from './styles.sass';

// Assets
import stub_smi from '@assets/stub_smi.svg';
import stub_article from '@assets/stub_article.png';
import Highlighted from '../../../../components/Highlighted';

const ArticleContent = ({ article, settings = {} }: any) => {
  const {
    date,
    audition,
    source,
    source_url,
    icon_url,
    title,
    image,
    preview,
    text,
    monitoring_section,
    video_url,
    video_file_url
  } = article;

  const { hideImages } = settings;

  const operator_from_section = OPERATORS.find(
    ({ key }) => monitoring_section === key
  );

  const classesForHighlight = classnames(
    styles.Highlight,
    operator_from_section?.id === OPERATORS_IDS.megafon &&
      styles.Highlight_megafon,
    operator_from_section?.id === OPERATORS_IDS.mts && styles.Highlight_mts,
    operator_from_section?.id === OPERATORS_IDS.tele2 && styles.Highlight_tele2,
    operator_from_section?.id === OPERATORS_IDS.beeline &&
      styles.Highlight_beeline,
    operator_from_section?.id === OPERATORS_IDS.yota &&
      styles.Highlight_yota
  );

  const customChunkFounder = ({ searchWords, textToHighlight }) => {
    const chunks = [];
    const textLow = textToHighlight.toLowerCase();

    searchWords.forEach((sw) => {
      const swLow = sw.toLowerCase();

      const indexOfSW = textLow.indexOf(swLow);
      if (indexOfSW > -1) {
        const start = indexOfSW;
        // Highlight full word
        const end = indexOfSW + swLow.length;
        chunks.push({
          start,
          end
        });
      }
    });

    return chunks;
  };

  const wordsNeedsOfHighlight = React.useMemo(() => {
    const words = dictionary[operator_from_section?.id];

    return words
      ? words
        .split('\n')
        .map((word) => {
          const trimmedWord = word.trim();
          if (trimmedWord) {
            return [...symbols, [[], []]].map(
              ([startSymbol, endSymbol]) =>
                `${startSymbol}${trimmedWord}${endSymbol}`
            );
          }

          return null;
        })
        .filter((word) => word)
        .flat()
      : [];
  }, [operator_from_section]);

  const OperatorHighlighter = ({ text }: string) => {
    if (operator_from_section) {
      const words = dictionary[operator_from_section.id];
      const wordsWithSymbols = words
        ? words
            .split('\n')
            .map((word) => {
              const trimmedWord = word.trim();
              if (trimmedWord) {
                return [...symbols, [[], []]].map(
                  ([startSymbol, endSymbol]) =>
                    `${startSymbol}${trimmedWord}${endSymbol}`
                );
              }

              return null;
            })
            .filter((word) => word)
            .flat()
        : [];

      return (
        <Highlighter
          highlightClassName={classesForHighlight}
          searchWords={wordsWithSymbols}
          autoEscape
          textToHighlight={text}
          findChunks={customChunkFounder}
        />
      );
    }

    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const PreviewTitle = () => (
    <div
      style={{
        fontSize: 28,
        lineHeight: 1.5,
        fontWeight: '500',
        margin: '0 0 24px 0'
      }}
    >
      <Highlighted text={title} highlight={wordsNeedsOfHighlight} />
    </div>
  );

  const PreviewImage = () =>
    !hideImages &&
    (image ? (
      <img src={image} alt="Превью новости" className={styles.Preview} />
    ) : (
      <div
        className={styles.Preview}
        style={{
          background: `url(${stub_article}), #ebebeb`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '400px'
        }}
      />
    ));

  const PreviewText = () => (
    <BODY2
      style={{
        fontWeight: '300',
        fontSize: '20px',
        lineHeight: '30px'
      }}
    >
      <Highlighted text={preview} highlight={wordsNeedsOfHighlight} />
    </BODY2>
  );

  const Text = () => (
    <Grid className={styles.Text}>
      <BODY1>
        <Highlighted text={text} highlight={wordsNeedsOfHighlight} />
      </BODY1>
    </Grid>
  );

  const TopPanel = () => (
    <>
      <Grid
        lg={12}
        xl={12}
        classes={{ root: styles.TopPanel }}
        container
        alignItems="center"
      >
        <Grid lg={4} xl={4}>
          <CAPTION style={{ marginTop: 10 }}>
            <span>
              {moment(date, 'YYYY-MM-DDTHH:mm:ss')
                .locale('ru')
                .format('DD.MM.YYYY, HH:mm')}
            </span>
            {/* eslint-disable-next-line */}
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              (
              {moment(
                moment(date, 'YYYY-MM-DDTHH:mm:ss')
                  .utc()
                  .locale('ru')
                  .format('DD.MM.YYYY, HH:mm'),
                'DD.MM.YYYY, HH:mm'
              ).fromNow()}
              )
            </span>
          </CAPTION>
        </Grid>
        <Grid lg={6} xl={6}>
          {source_url ? (
            <a
              href={source_url}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.Source}
            >
              <CAPTION style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt=""
                  src={icon_url || stub_smi}
                  style={{ marginRight: '10px', width: '25px', height: '25px' }}
                />
                <div>{source}</div>
              </CAPTION>
            </a>
          ) : (
            <CAPTION style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt=""
                src={icon_url || stub_smi}
                style={{ marginRight: '10px', width: '25px', height: '25px' }}
              />
              <div>{source}</div>
            </CAPTION>
          )}
        </Grid>
      </Grid>
      <div className={styles.Statistics}>
        <div className={styles.Row}>
          Аудитория:{' '}
          {audition && audition > 0
            ? `${audition.toLocaleString()} человек(а)`
            : 'неизвестно'}
        </div>
        <div className={styles.Row}>
          Оператор:&nbsp;
          <mark>{operator_from_section?.name || 'неизвестен'}</mark>
        </div>
      </div>
    </>
  );

  const renderVideo = useMemo((): JSX.Element | null => {
    if (!video_url && !video_file_url) return null;
    return (
      <div className={styles.ContainerIframe}>
        <iframe 
          className={styles.Video} 
          allowFullScreen 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          title='news video' 
          src={video_url || video_file_url}
        /> 
      </div>
    )
  }, [video_url, video_file_url]);

  const Content = () => (
    <Grid lg={12} xl={12}>
      <PreviewTitle />
      {!video_url && !video_file_url && <PreviewImage />}
      <PreviewText />
      {renderVideo}
      <Text />
    </Grid>
  );

  return (
    <Grid lg={12} xl={12}>
      <TopPanel />
      <Content />
    </Grid>
  );
};

export default ArticleContent;
