// @flow

// Libraries
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SortableTree from 'react-sortable-tree';

// Api
import QueryWrapper from '../../api/QueryWrapper';

// Components
import { Button, Popover } from '@material-ui/core';
import Loader from '../Loader';

// Constants
import { server } from '../../constants/servers';

// Styles
import styles from './styles.sass';
import FileExplorerTheme from '../../styles/tree-theme';
import useAuth from '../../hooks/useAuth';
import { CHANGE_SETTINGS } from '../../store/settings/types';
import { getToken } from '../../api/articleApi';


export const DEFAULT_SECTIONS_ORDER = [25, 27, 28, 29, 26, 30];

const Settings = ({ anchorSettings, setAnchorSettings }: any) => {
  const { user: { token } } = useAuth();
  const dispatch = useDispatch();
  const { settings } = useSelector(store => store);

  // потом выпилю это поле после рефакторинга
  const [buffer, setBuffer] = React.useState(null);
  const [settingsData, setSettingsData] = React.useState(settings);
  const [newSettingsData, setNewSettingsData] = React.useState(settingsData);
  const [loading, setLoading] = React.useState(true);

  const isTouchDevice = () => (
    ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)
  );

  const handleClose = () => {
    setAnchorSettings(null);

    // сброс будет на настройки, которые нам придут с бэка
    setNewSettingsData(settingsData);
  };

  const isOpenedSettings = Boolean(anchorSettings);

  const handleAccept = () => {
    setLoading(true);

    const data = {
      subscribe: {
        ...buffer.subscribe,
        monitoring_sections: newSettingsData.map(({ id, label, value }, i) => ({ id, label, value, priority: i }))
      }
    }

    QueryWrapper(
      fetch(`${server}/api/v1/account/monitoring_settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
      }),
      () => {
        handleChangeSettings(data.subscribe.monitoring_sections);
        setBuffer(data);
        setLoading(false);
      },
    )
  };

  React.useEffect(() => {
    setLoading(true);

    QueryWrapper(
      fetch(`${server}/api/v1/account/monitoring_settings`, {
        method: 'GET',
        headers: {
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${getToken()}`
        },
      }),
      res => {
        setBuffer(res);
        const data = res?.subscribe?.monitoring_sections;

        if (data) {
          const sortedDataByPriority = data.every(({ priority }) => priority === 0)
            ? DEFAULT_SECTIONS_ORDER.map((orderId, i) => ({
              ...data.find(({ id }) => orderId === id),
              priority: i
            }))
            : data.sort((a, b) => a.priority - b.priority)

          handleChangeSettings(sortedDataByPriority);
        }

        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [anchorSettings]);

  const handleChangeSettings = data => {
    if (data) {
      dispatch({
        type: CHANGE_SETTINGS,
        payload: { monitoring_sections: data }
      });
      setSettingsData(data);
      setNewSettingsData(data);
    }
  };

  return (
    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
      <Popover
        id={isOpenedSettings ? 'simple-popover' : undefined}
        open={isOpenedSettings}
        anchorEl={anchorSettings}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.Popover}>
          <div className={styles.Popover__Top}>
            <div className={styles.Popover__Title}>
              Настройки
            </div>
            <div
              className={styles.Popover__SelectAll}
              onClick={() => setNewSettingsData(prev => prev.map(settings => ({ ...settings, value: true })))}
            >
              Выделить все
            </div>
          </div>
          <div className={styles.Popover__Help}>
            Перетащите элементы, чтобы поменять порядок отображаемых новостей.
          </div>
          <div className={styles.Popover__Content} style={{height: '400px'}}>
            {loading
              ? <Loader text='' />
              : (
                <SortableTree
                  treeData={newSettingsData}
                  onChange={treeData => setNewSettingsData(treeData)}
                  theme={FileExplorerTheme}
                  maxDepth={1}
                  generateNodeProps={({ node }) => ({
                    style: {
                      alignItems: 'center',
                      borderBottom: '1px solid #E9E9E9',
                    },
                    title: (
                      <div className={styles.SortableTree__Title}>
                        {node.label}
                      </div>
                    ),
                    customCheckBoxHandler: (_1, _2, checked) => setNewSettingsData(prev => {
                      const copiedData = [...prev];
                      const foundIndex = copiedData.findIndex(settings => settings.id === node.id);
                      copiedData[foundIndex] = { ...copiedData[foundIndex], value: !checked };
                      return copiedData;
                    }),
                    checked: node.value
                  })}
                />
            )}
          </div>
          <div className={styles.Popover__Bottom}>
            <Button onClick={handleClose}>Отменить</Button>
            <Button onClick={handleAccept}>Применить</Button>
          </div>
        </div>
      </Popover>
    </DndProvider>
  )
}

export default Settings;
