
// Libraries
import React from 'react';

// Components
import { Redirect, Route } from 'react-router-dom';
import LoadingScreen from '../LoadingScreen';

// Hooks
import useAuth from '../../hooks/useAuth';


const PrivateRoute = (props: any) => {
  const { hasAuth } = useAuth();
  const { component: Component, ...rest } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');

  return (
    <Route
      {...rest}
      render={props => {
        if (!hasAuth && tokenFromUrl) {
          return <LoadingScreen />;
        }

        return hasAuth
          ? <Component {...props} />
          : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
      }}
    />
  );
};

export default PrivateRoute;
