
// Components
import { Paper, withStyles } from '@material-ui/core';


const StyledPaper = withStyles(() => {
  return {
    root: {
      width: props => props?.width || '748px',
      padding: '24px',
      margin: '0 auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      left: '50%'
    }
  }
})(Paper);

export default StyledPaper;