// @flow

// Libraries
import * as React from 'react';

// Components
import { Button, Checkbox, FormControlLabel, Icon, IconButton, Radio, withStyles } from '@material-ui/core';

export const StyledIconButton = withStyles((theme) => ({
  root: {
    background: '#843591',
    width: '40px',
    height: '40px',
    margin: '0 6px',
    position: 'relative'
  }
}))(IconButton);

export const StyledIcon = withStyles((theme) => ({
  root: {
    color: '#843591',
    margin: '0 5px 0 0',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .2s',
    '&:hover': {
      background: 'rgb(132, 53, 145, 0.12)'
    }
  }
}))(Icon);

export const MenuBytton = withStyles((theme) => ({
  root: {
    borderRadius: '100px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    marginLeft: '8px',
    height: '27px',
    border: '1px solid #E0E0E0'
  }
}))((props) => (
  <Button
    {...props}
  />
));

export const StyledCheckbox = withStyles({
  root: {
    width: '30px',
    color: '#731982',
    '&$checked': {
      color: '#731982',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const StyledCheckboxGreen = withStyles({
  root: {
    width: '30px',
    color: '#E5E5E5',
    '&$checked': {
      color: '#00B956',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const StyledRadio = withStyles({
  root: {
    margin: '0 10px 0 0',
    display: 'flex',
    alignItems: 'center',
    color: '#00A84E',
    '&$checked': {
      color: '#00A84E',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const StyledFormControlLabel = withStyles({
  root: {
    margin: '0 0 4px 0 !important',
    '&:hover': {
      color: '#00A84E'
    }
  }
})((props) => <FormControlLabel style={{color: props.active ? '#00A84E' : '#000'}} {...props} />);
