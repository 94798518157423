// @flow

// Libraries
import * as React from 'react';

// Styles
import styles from './styles.sass';

const StatusBar = ({ id, documentName, onNameChange, disabled, hideSaving = false }: any) => {
  const handleBlur = (e) => {
    if (e.target.value !== documentName) {
      onNameChange(e.target.value);
    }
  }

  return (
    <header key={id} className={styles.Header}>
      {hideSaving ? (
        <div className={`${styles.Input} ${hideSaving && styles.Input_Only}`}>
          {documentName}
        </div>
      ) : (
        <input
          className={styles.Input}
          defaultValue={documentName}
          onBlur={handleBlur}
          disabled={disabled}
        />
      )}
      {!hideSaving && (
        <span className={styles.Saved}>
          &nbsp;-&nbsp;
          Изменения не сохранены
        </span>
      )}
    </header>
  );
};

export default StatusBar;
