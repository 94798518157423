import _ from 'lodash';
import React from 'react';


const Highlighted = ({ text = '', highlight = '', editable, onChange }: any) => {
  const inputEl = React.useRef(null);

  React.useEffect(() => {
    if (editable && inputEl?.current) {
      inputEl.current.addEventListener("input", e => {
        onChange(e)
      }, false);
    }
  }, [editable, inputEl]);

  if (highlight.length === 0) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const highlightedString = highlight.reduce((htmlString, highlightWord) => {
    const regex = new RegExp(`(${_.escapeRegExp(highlightWord)})`, 'gi');
    if (htmlString) {
      const parts = htmlString.split(regex);
      return parts
      .filter((part) => part)
      .map((part) =>
        // eslint-disable-next-line react/no-array-index-key
        regex.test(part) ? `<mark>${part}</mark>` : `${part}`
      )
      .join('');
    }
  }, text);

  return (
    <span
      ref={inputEl}
      dangerouslySetInnerHTML={{ __html: highlightedString }}
    />
  );
};

export default Highlighted;