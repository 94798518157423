// Types
import { CHANGE_MONITORING_CONSTANTS } from './types';


const monitoringConstantsReducer = (
  state = {
    monitoring_types: [],
    monitoring_regions: [],
    monitoring_sections: []
  },
  action
) => {
  switch (action.type) {
    case CHANGE_MONITORING_CONSTANTS: {
      return {
        ...state,
        ...action.payload
      }
    }

    default: {
      return state;
    }
  }
};

export default monitoringConstantsReducer;
