
// Libraries
import React from 'react';

// Components
import Loader from '../Loader';

// Styles
import styles from './styles.sass';


const LoadingScreen = () => (
  <div className={styles.LoadingScreen}>
    <Loader text='Авторизация...' />
  </div>
);

export default LoadingScreen;
