import moment from 'moment';

const DATE_FORMAT = 'DD.MM.YYYY';

export function formatArgumentLabel({ value }) {
  if (typeof value === 'string' && value.includes('_month')) {
    return moment(value, DATE_FORMAT).format('MMM');
  }

  return value;
}
