// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Assets
import arrowLeft from '@assets/arrowLeft.svg';

// Styles
import styles from './styles.sass';


const BackButton = ({ to }: any) => {
  return (
    <Link
      className={styles.BackButton}
      to={to}
    >
      <img src={arrowLeft} alt='' />
      <span>Назад</span>
    </Link>
  )
};

export default BackButton;
