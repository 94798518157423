import React from 'react';
import { Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_EVENT } from '../../store/event/types';
import MuiAlert from '@material-ui/lab/Alert';


const EventSnackbar = () => {
  const dispatch = useDispatch();
  const event = useSelector(store => store.event);

  const handleClose = () => {
    dispatch({ type: HIDE_EVENT });
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={event.open}
      autoHideDuration={1000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={event.status}
        onClose={handleClose}
      >
        {event.message}
      </MuiAlert>
    </Snackbar>
  )
};

export default EventSnackbar;