// Libraries
import Cookie from 'js-cookie';
import _ from 'lodash';


export const clearAllData = (withoutRedirect) => {
  Cookie.remove('token');
  localStorage.clear();
  if (!withoutRedirect) {
    window.location.pathname = '/login';
    window.location.search = '';
  }
};

export const round = (value, precision) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const divideGraphsValue = (value, onlyMillions) => {
  if (value === 0) {
    return value;
  }

  if (value >= 1_000_000 || onlyMillions) {
    const roundedValue = _.round((value / 1_000_000), 2);

    return `${roundedValue === 0 ? '< 0,01' : roundedValue.toString().replaceAll('.', ',')} млн`;
  }

  return _.round(value, 2).toLocaleString();
}
