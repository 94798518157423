// @flow

// Libraries
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import moment from 'moment';

// Components
import { Container, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Page from '@components/Page';
import NewsCard from '@components/NewsCard';
import ArticlesManipulator from '@components/ArticlesManipulator';
import DragCol from '@components/DragCol';
import BackButton from '../../components/BackButton';

// Methods
import { sortByPriority } from '../../methods/articlesMethods';

// Api
import { fetchArticles, getArticles, getToken } from '../../api/articleApi';
import QueryWrapper from '../../api/QueryWrapper';

// Constants
import { VIEWS } from '@constants/views';
import { server } from '@constants/servers';

// Hooks
import { useGroupArticles } from '../../utils/hooks';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';

// Assets
import Loader from '../../components/Loader';

// Styles
import styles from './styles.sass';

const Articles = () => {
  const {
    user: { token }
  } = useAuth();
  const role = useSelector(({ user }) => user.role);
  const { search } = useLocation();
  const params = JSON.parse(
    '{"' +
      decodeURI(search)
        .replace('?', '')
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );

  const { date, operator, region, type, status } = params;
  const fullPath = window.location.pathname.split('/');
  const key = fullPath[fullPath.length - 1];

  const [articles, setArticles] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [searchedWords, setSearchedWords] = React.useState('');

  const { groups } = useGroupArticles(articles);

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const isPreparedMonitoring =
    date.split('T')[0] ===
    moment()
      .utc()
      .add(1, 'day')
      .format('YYYY-MM-DD');

  const hasGroups = !!(
    groups &&
    groups[type] &&
    Object.values(groups[type]).length > 0
  );

  const view =
    useSelector(store => store?.global_monitoring_settings?.view) ||
    VIEWS.cells;
  const rowView = view === VIEWS.rows || !isDesktop;

  const Content = () => (
    <Grid container xs={12} spacing={rowView ? 0 : 2}>
      {hasGroups &&
        sortByPriority(groups[type])
          .filter(({ monitoring_date }) => monitoring_date)
          .map((item, index) => {
            const hasChild = item?.children;
            const thisGroup = hasChild
              ? [...item?.children, item].filter(
                  ({ monitoring_date }) => monitoring_date
                )
              : null;
            const article = thisGroup
              ? thisGroup.find(({ post_id }) => post_id === null)
              : item;

            const Card = () => (
              <NewsCard
                setAllArticles={setArticles}
                newsId={article && article.id}
                preview={article && article.image}
                title={article && article.title}
                withVideo={Boolean(
                  article?.video_file_url || article?.video_url
                )}
                childrenCount={thisGroup ? thisGroup.length - 1 : 0}
                isFavoritePage={article && article.is_favorite}
                edition="ria"
                rowView={rowView}
                bigSize
                source={{
                  name: article?.source || '',
                  logo: null,
                  url: article?.source_url || ''
                }}
                additionalEventsOnClick={() =>
                  localStorage.setItem('fromArticles', true)
                }
              />
            );

            if (index === 0) {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={rowView ? 12 : 8}
                  spacing={1}
                  classes={{ root: styles.NewsCard }}
                >
                  <Card />
                </Grid>
              );
            }

            return (
              <Grid
                item
                xs={12}
                sm={rowView ? 12 : 6}
                md={rowView ? 12 : 4}
                spacing={0}
                classes={{ root: styles.NewsCard }}
              >
                <Card />
              </Grid>
            );
          })}
    </Grid>
  );

  const resolveHandler = res => {
    const articlesList = res && Object.entries(res);
    setArticles({
      [type]: articlesList[0][1]
    });
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    const usedRegions =
      region !== 'WITHOUT_REGIONS' ? { regions: [{ id: region }] } : {};
    fetchArticles(
      {
        date,
        type,
        ...usedRegions,
        sections: [operator],
        status,
        words: searchedWords
      },
      resolveHandler
    );
  }, [searchedWords]);

  const handleDragChange = async reorderedArticles => {
    await QueryWrapper(
      fetch(`${server}/api/v1/monitoring/posts/custom_priority`, {
        method: 'PATCH',
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          posts: reorderedArticles.map(({ id, custom_priority }) => ({
            id,
            custom_priority
          }))
        })
      })
    );

    await getArticles(
      {
        key,
        date,
        customDate: true,
        type,
        limit: 10000,
        operator
      },
      () => {
        setArticles({ [type]: reorderedArticles });
        setLoading(false);
      }
    );
  };

  const title = useSelector(
    store => store.monitoring_constants.monitoring_sections
  ).find(item => `${item.id}` === `${key}`)?.name;

  const Empty = () => (
    <div className={styles.Empty}>
      <span>Новости не найдены</span>
    </div>
  );

  const List = () => (
    <Grid xs={12} container className={styles.EditorBlock}>
      {loading ? (
        <Loader />
      ) : articles &&
        typeof articles === 'object' &&
        Object.values(articles).flat().length > 0 ? (
        <Content />
      ) : (
        <Empty />
      )}
    </Grid>
  );

  const WrapperDragCol = () => {
    const condition = hasGroups && role === 'editor' && isPreparedMonitoring;

    if (condition) {
      return (
        <DragCol
          items={groups[type].filter(({ monitoring_date }) => monitoring_date)}
          onChange={handleDragChange}
          loading={loading}
          setLoading={setLoading}
        />
      );
    }

    return <></>;
  };

  const getBackButtonRoute = () => {
    if (articles && type && articles[type]) {
      const {
        monitoring_date,
        monitoring_type,
        monitoring_region,
        monitoring_section,
        status,
        id
      } = articles[type][0];

      const formattedMonitoringDate = moment(
        monitoring_date,
        'YYYY-MM-DDTHH:mm:ss'
      ).format('YYYY-MM-DD');

      const fromBookmarks = JSON.parse(localStorage.getItem('fromBookmarks'));
      const fromArticles = JSON.parse(localStorage.getItem('fromArticles'));
      const fromGroup = JSON.parse(localStorage.getItem('fromGroup'));

      const operator =
        fromBookmarks || fromArticles || fromGroup
          ? `&operator=${monitoring_section}`
          : '';

      const isPrepared = moment(monitoring_date, 'YYYY-MM-DDTHH:mm:ss').isAfter(
        moment()
          .locale('ru')
          .format('YYYY-MM-DD')
      );

      const region = isPrepared ? '' : `&region=${monitoring_region}`;
      const params = `?date=${formattedMonitoringDate}&type=${monitoring_type}${region}${operator}&status=${status}`;

      if (fromGroup) {
        return `/articles/group/${id}`;
      }

      if (fromArticles) {
        return `/articles/section${params}`;
      }

      if (fromBookmarks) return '/bookmarks';

      if (role === 'user') return `/${params}`;

      return `/monitoring/${isPrepared ? 'prepared' : 'released'}/${
        monitoring_type === 3 ? 'federal' : 'regional'
      }${params}`;
    }

    return '/';
  };

  return (
    <Page>
      <div style={{ display: 'flex', width: '100%', maxWidth: '1920px' }}>
        <WrapperDragCol />
        <Container className={styles.Container}>
          <ArticlesManipulator
            dateFilterSettings={{
              show: true,
              readonly: true
            }}
            searchHandler={setSearchedWords}
            day={date}
          />
          <BackButton to={getBackButtonRoute()} />
          <h1 style={{ margin: '0 0 20px 0' }}>{title}</h1>
          <List />
        </Container>
      </div>
    </Page>
  );
};

// Exports
export default Articles;
