import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

const DEFAULT_MERGE_DATA = {
	showModal: false,
	succeed: false,
	error: false,
	progress: 0,
	title: 'Перенос статей',
	from: [],
	to: null,
	select: {
		needSelectParent: false,
		selected: null,
		allItemsForSelect: []
	}
};

const HANDBOOK_TAG_TYPES = {
	group: 'group',
	selected: 'selected',
	item: 'item'
};

const MOODS = [
	{
		type: 'positive',
		label: 'Позитивная',
		icon: SentimentVerySatisfiedIcon
	},
	{
		type: 'neutral',
		label: 'Нейтральная',
		icon: SentimentSatisfiedIcon
	},
	{
		type: 'negative',
		label: 'Негативная',
		icon: SentimentVeryDissatisfiedIcon
	}
]

export {
	DEFAULT_MERGE_DATA,
	HANDBOOK_TAG_TYPES,
	MOODS,
}