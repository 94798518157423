// @flow
import React from 'react';
import styles from './node-content-renderer.scss';
import folder from './folder.svg';
import { Checkbox, withStyles } from '@material-ui/core';

type MinimalThemeNodeContentRendererPropTypes = {
  buttons: Array<Node>;
  canDrag?: boolean;
  className?: string;
  icons?: Array<Node>;
  isSearchFocus?: boolean;
  isSearchMatch?: boolean;
  node: any;
  path: Array<string | number>;
  scaffoldBlockPxWidth: number;
  style: any;
  source: any;
  swapDepth?: number;
  swapFrom?: number;
  swapLength?: number;
  title: string;
  toggleChildrenVisibility: () => void;
  treeIndex: number;
  treeId: string;
  checked: boolean;
  indicators: Array<string>;

  // Drag and drop API functions
  // Drag source
  connectDragPreview: () => void;
  connectDragSource: () => void;
  checkBoxChangeHandler: () => void;
  didDrop: boolean;
  draggedNode: any;
  isDragging: boolean;
  parentNode: any;
  // Drop target
  canDrop?: boolean;
  isOver: boolean;
  rowDirection: string;
  selectedCheckboxes: Array<string>;
};

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      child => child === younger || isDescendant(child, younger)
    )
  );
}

export const StyledCheckbox = withStyles({
  root: {
    width: '30px',
    margin: '0 8px 0 0',
    color: '#731982',
    '&$checked': {
      color: '#731982',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" size='medium' style={{transform: "scale(1.3)"}} {...props} />);

// eslint-disable-next-line complexity
const MinimalThemeNodeContentRenderer = (props: MinimalThemeNodeContentRendererPropTypes) => {
  const {
    buttons = [],
    canDrag = false,
    canDrop = false,
    className = '',
    draggedNode = null,
    isSearchFocus = false,
    isSearchMatch = false,
    style = {},
    source = null,
    title = null,
    toggleChildrenVisibility = null,
    scaffoldBlockPxWidth,
    connectDragPreview,
    connectDragSource,
    isDragging,
    node,
    path,
    treeIndex,
    didDrop,
    treeId,
    isOver,
    selectedCheckboxes,
    checked,
    checkBoxChangeHandler,
    indicators = [],
    ...otherProps
  } = props;
  const nodeTitle = title || node.title;
  const nodeSource = source || node.source;
  const postsCount = node.posts_count;
  const nodeId = node.id;
  const isSelected = (nodeId) => selectedCheckboxes.indexOf(nodeId) !== -1;

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  const nodeContent = connectDragPreview(
    <div
      id={node.id}
      style={{...style}}
      className={
        styles.rowContents +
        (isSearchMatch ? ` ${styles.rowSearchMatch}` : '') +
        (isSearchFocus ? ` ${styles.rowSearchFocus}` : '') +
        (!canDrag ? ` ${styles.rowContentsDragDisabled}` : '')
      }
      onClick={() => !node?.loader &&
        toggleChildrenVisibility({
          node,
          path,
          treeIndex,
        })
      }
    >
      {node?.loader ? (
        <div className={styles.LoadMore}>Загрузить ещё</div>
      ) : (
        <>
          <div className={styles.Indicators}>
            {indicators.map(indicator => (
              <div className={styles.Indicators__Item} style={{backgroundColor: indicator}} />
            ))}
          </div>
          <div
            className={styles.rowLabel}
            style={
              checkBoxChangeHandler
                ? {}
                : {padding: '0 0 0 10px'}
            }
          >
            {checkBoxChangeHandler && (
              <StyledCheckbox
                checked={checked || isSelected(nodeId)}
                onChange={e => checkBoxChangeHandler(e, nodeId, checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
            {(postsCount > 0 || typeof node.children === 'function') && (
              <div className={styles.buttonWrapper}>
                <button
                  type="button"
                  className={styles.collapseButton}
                >
                  <img src={folder} alt="Папка" />
                  <div>{postsCount}</div>
                </button>

                {node.expanded && !isDragging && (
                  <div
                    style={{ width: scaffoldBlockPxWidth }}
                    className={styles.lineChildren}
                  />
                )}
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '97%'}}>
              <span className={styles.rowTitle}>
                {
                  typeof nodeTitle === 'function'
                    ? nodeTitle({
                      node,
                      path,
                      treeIndex,
                    })
                    : nodeTitle
                }
              </span>
              {nodeSource && (
                <span className={styles.rowSubtitle}>
                  {typeof nodeSource === 'function'
                    ? nodeSource({
                      node,
                      path,
                      treeIndex,
                    })
                    : nodeSource}
                </span>
              )}
            </div>
          </div>
          <div className={styles.rowToolbar}>
            {buttons.map(({ content, handleClick }, i) => (
              <div
                key={i} // eslint-disable-line react/no-array-index-key
                className={styles.toolbarButton}
                onClick={handleClick}
              >
                {content}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="ttt" style={{ height: '100%' }} {...otherProps}>
      <div
        className={
          styles.rowWrapper +
          (!canDrag ? ` ${styles.rowWrapperDragDisabled}` : '')
        }
      >
        <div
          className={
            styles.row +
            (isLandingPadActive ? ` ${styles.rowLandingPad}` : '') +
            (isLandingPadActive && !canDrop ? ` ${styles.rowCancelPad}` : '') +
            (className ? ` ${className}` : '')
          }
          style={{
            opacity: isDraggedDescendant ? 0.5 : 1,
            ...style,
          }}
        >
          {canDrag
            ? connectDragSource(nodeContent, { dropEffect: 'copy' })
            : nodeContent}
        </div>
      </div>
    </div>
  );
};

export default MinimalThemeNodeContentRenderer;
