// @flow

// Libraries
import * as React from 'react';
import { NavLink } from 'react-router-dom';

// Components
import { ClickAwayListener } from '@material-ui/core';

// Styles
import styles from './styles.sass';

type TabMenuPropTypes = {
  tabs: Array<{
    label: string,
    to: string
  }>
};

const TabMenu = ({ tabs = [] }: TabMenuPropTypes) => {
  const [isShow, setIsShow] = React.useState(false);

  const handleShow = (tab) => {
    setIsShow(prev => ({
      ...Object.fromEntries(Object.entries(prev).map(item => [item[0], false])),
      [tab.to]: !prev[tab.to]
    }));
  };

  return (
    <div className={styles.Root}>
      <div className={styles.Content}>
        {tabs.map((tab) => {
          if (['/monitoring/prepared', '/monitoring/released'].includes(tab.to)) {
            return (
              <div
                key={tab.to}
                className={styles.FakeLink}
                style={
                  window.location.pathname.includes(tab.to)
                    ? ({
                      borderBottom: "2px solid #00A84E",
                      color: "#00A84E"
                    })
                    : ({})
                }
              >
                <ClickAwayListener
                  onClickAway={() => isShow[tab.to] ? handleShow(tab) : false}
                >
                  <div onClick={() => handleShow(tab)}>
                    <div className={styles.FakeLinkLabel}>{tab.label}</div>
                    <div>
                      {isShow[tab.to] && (
                        <div className={styles.FakeLinkSubItems}>
                          <NavLink
                            to={`${tab.to}/federal`}
                            style={{ color: window.location.pathname.includes(tab.to) && window.location.pathname.includes('federal') ? '#00A84E' : '#000'}}
                            className={styles.FakeLinkSubItemsRef}
                          >
                            Федеральный
                          </NavLink>
                          <NavLink
                            to={`${tab.to}/regional`}
                            style={{ color: window.location.pathname.includes(tab.to) && window.location.pathname.includes('regional') ? '#00A84E' : '#000'}}
                            className={styles.FakeLinkSubItemsRef}
                          >
                            Региональный
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
            )
          } else {
            return (
              <NavLink
                key={tab.to}
                className={styles.Link}
                exact={tab.exact || false}
                to={tab.to}
                activeStyle={{
                  borderBottom: "2px solid #00A84E",
                  color: "#00A84E"
                }}
              >
                {tab.label}
              </NavLink>
            )
          }
        })}
      </div>
    </div>
  )
}

// Exports
export default TabMenu;
