// @flow

// Libraries
import React from 'react';
import Chart, { CommonSeriesSettings, Format, Grid, Label, Legend, Series, ValueAxis } from 'devextreme-react/chart';
// Methods
import { divider } from '../../../../../methods/chartsMethods';

// Types
import { BarSplineChart as Types } from '../../types';
import { divideGraphsValue } from '../../../../../utils/methods';


const BarSplineChart = ({ dataSource, animation }: Types) => {
  const {
    values,
    title,
    argumentField,
    fields
  } = dataSource;

  return (
    <Chart
      id="chart"
      palette="Vintage"
      dataSource={values}
      title={title}
      animation={animation}
    >
      <CommonSeriesSettings
        argumentField={argumentField}
        type="bar"
      >
        <Label visible>
          <Format
            type="fixedPoint"
            precision={0}
            formatter={text => `${Number(text).toLocaleString('ru')}`.replaceAll('.', ',')}
          />
        </Label>
      </CommonSeriesSettings>
      <ValueAxis
        label={{
          customizeText: ({ value }) => divideGraphsValue(value)
        }}
      />
      <Series
        valueField={fields.bar.value}
        name={fields.bar.name}
        color={fields.bar.color}
      />
      <Series
        axis={fields.spline.value}
        type="spline"
        valueField={fields.spline.value}
        name={fields.spline.name}
        color={fields.spline.color}
      />
      <ValueAxis maxValueMargin={0.1}>
        <Grid visible />
      </ValueAxis>
      <ValueAxis
        name={fields.spline.value}
        position="right"
        maxValueMargin={0.5}
      >
        <Grid visible />
      </ValueAxis>

      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
      />
    </Chart>
  );
}

export default BarSplineChart;
