// @flow

// Libraries
import * as React from 'react';

// Components
import Header from '../Header';
import { Container, Grid } from '@material-ui/core';
import ControlPanel from '../ControlPanel';
import TabMenu from '../TabMenu';
import MobileBlockScreen from '../MobileBlockScreen';
import Loader from '../Loader';
import Swal from 'sweetalert2';

// Hooks
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useLocation } from 'react-router-dom';

// Constants
import { analystTabMenu, editorTabMenu, userTabMenu, pr_managerTabMenu } from '@constants/menus';

// Styles
import styles from './styles.sass';
import useAuth from '../../hooks/useAuth';


type PagePropType = {
  children: Node;
  isDefault?: boolean;
  title?: string;
  withoutFooter?: boolean;
  withoutLogin?: boolean;
  changeTypeNewsHandler: () => void;
  loading?: boolean;
  event?: any;
}

const Page = ({
  children,
  title,
  isDefault = false,
  withoutFooter = false,
  withoutLogin = false,
  changeTypeNewsHandler,
  loading = false,
  event,
}: PagePropType) => {
  const { hasAuth, user: { role } } = useAuth();

  React.useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  // страницы на которые можно заходить с телефона
  const ALLOWED_PAGES = {
    '/': ['user'],
    '/monitoring': ['editor'],
    '/articles': ['user', 'editor'],
    '/login': ['all'],
    '/notes': ['all'],
    '/article': ['all']
  };

  const { pathname } = useLocation();
  const isMobile = useMediaQuery('(max-width: 1200px)');

  const pageIsAllowed = Object.entries(ALLOWED_PAGES).find(([url, roles]) => (
    (pathname !== '/' && pathname.includes(url) && (roles.includes(role) || roles.includes('all'))) || (url.length === 1 && roles.includes(role))
  ));

  const pageIsBlockedForMobileUsage = isMobile && !pageIsAllowed;

  React.useEffect(() => {
    if (event?.open) {
      Swal.fire({
        position: 'top',
        icon: event?.status || 'success',
        title: event.message,
        showConfirmButton: false,
        timer: 3000,
        toast: true
      });
    }
  }, [event]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={styles.Root}
    >
      <Header />
      {pageIsBlockedForMobileUsage
        ? <MobileBlockScreen />
        : (
          <>
            {hasAuth && role === 'user' && (
              <ControlPanel
                items={userTabMenu}
                changeTypeNewsHandler={changeTypeNewsHandler}
              />
            )}
            {hasAuth && role === 'editor' && <TabMenu tabs={editorTabMenu} />}
            {hasAuth && role === 'analyst' && <TabMenu tabs={analystTabMenu} />}
            {hasAuth && role === 'pr_manager' && <TabMenu tabs={pr_managerTabMenu} />}
            {isDefault ? (
              <Grid
                container
                direction="column"
              >
                <Container style={{position: 'relative'}}>
                  {title && <div className={styles.Title}>{title}</div>}
                  {children}
                </Container>
              </Grid>
            ) : (
              <>
                {loading ? <Loader /> : children}
              </>
            )}
            {!withoutFooter && (
              <footer className={styles.Footer}>
                <Container>
                  <Grid
                    container
                    justify="space-between"
                  >
                    <Grid item lg={2}>
                      <span className={styles.Company}>
                        2022 ПАО “МегаФон”
                      </span>
                    </Grid>
                    <Grid item lg={1}>
                      <a href='mailto:megasmi@megafon.ru' className={styles.Support}>
                        Техподдержка
                      </a>
                    </Grid>
                  </Grid>
                </Container>
              </footer>
            )}
          </>
        )
      }
    </Grid>
  )
};

export default Page;
