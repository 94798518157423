// @flow

import * as React from 'react';

import UseHandbookTagsModal from './UseHandbookTagsModal';

import { Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Modal,
  TextField
} from '@material-ui/core';
import { H6 } from '@components/Typography';
import Tabs from './Tabs';

// Assets
import SearchIcon from '@material-ui/icons/Search';

// Styles
import styles from './styles.sass';
import Loader from "../Loader";

// Types
type HandbookTagsModalTypes = {
  type: string;
  postId: string;
  isOpenModal: boolean;
  onCloseHandler: () => void;
  addTagsHandler: () => void;
  activeTags: Array<any>;
};

const HandbookTagsModal = ({
  type,
  postId,
  isOpenModal,
  onCloseHandler,
  addTagsHandler,
  activeTags
}: HandbookTagsModalTypes) => {
  const {
    loading,
    selectedTags,
    setSelectedTags,
    tagType,
    setTagType,
    TAG_TYPES,
    tags,
    handleCheckboxChange,
    searchTags,
    onClose,
    singleArticlesubmitHandler,
    multiplyArticlesSubmitHandler,
    fetchAllTagsByType
  } = UseHandbookTagsModal({
    postId,
    onCloseHandler,
    activeTags,
    addTagsHandler
  });

  React.useEffect(() => {
    if(isOpenModal) {
      fetchAllTagsByType(tagType);
    }
  }, [isOpenModal, tagType]);

  return (
    <Modal
      open={isOpenModal}
      onClose={onClose}
    >
      <div className={styles.Content}>
        <div className={styles.Header}>
          <H6>Добавить теги из справочника</H6>
          <TextField
            placeholder="Поиск"
            style={{
              width: '445px',
            }}
            variant="outlined"
            onChange={(e) => searchTags(e.target.value)}
            icon
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#00B956'}} />
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </div>
        <div className={styles.Menu}>
          <Tabs
            onChangeHandler={(type) => {
              setSelectedTags([]);
              setTagType(type);
            }}
            whiteList={TAG_TYPES}
          />
        </div>
        <div className={styles.Body}>
          {loading ? <Loader /> : (
            <FormGroup className={styles.Checkboxes}>
              { tags && tags[tagType] && tags[tagType].map(tag => (
                <FormControlLabel
                  className={styles.LabelWrapper}
                  key={`${tag.tag_id} ${tag.tag_type}`}
                  control={(
                    <Checkbox
                      className={styles.Checkbox}
                      checked={
                        tag.checked
                        ||
                        activeTags?.find(({ tag_id, tag_type }) => tag_id === tag.tag_id && tag_type === tag.tag_type)}
                      onChange={({ target: { checked } }) => handleCheckboxChange(tag, checked)}
                      disabled={
                        (tagType === 'custom' || tagType === 'pr_campaign')
                          ? activeTags?.find(({ tag_id, tag_type }) => tag_id === tag.tag_id && tag_type === tag.tag_type)
                          : activeTags.find(({ tag_type }) => tag_type === 'official')
                      }
                    />
                  )}
                  label={tag.title}
                />
              ))}
            </FormGroup>
          )}
        </div>
        <div className={styles.Footer}>
          <Button
            className={styles.CancelButton}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            className={styles.AddButton}
            onClick={() => {
              if (type === 'item') {
                singleArticlesubmitHandler();
              } else {
                multiplyArticlesSubmitHandler();
              }
            }}
            disabled={selectedTags.length < 1}
          >
            Добавить
          </Button>
        </div>
      </div>
    </Modal>
  )
};

export default HandbookTagsModal;
