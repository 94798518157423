// @flow

// Libraries
import * as React from 'react';

// Components
import { MenuBytton } from '../index';
import { BODY2 } from '@components/Typography';

// Styles
import styles from './styles.sass';

// Types
type ProccesMenuTypes = {
  onChangeTypeHandler: () => void;
  type: string;
};


const ProccesedMenu = ({ type, onChangeTypeHandler }: ProccesMenuTypes) => {
  const onClickHandler = (type) => {
    if(onChangeTypeHandler) {
      onChangeTypeHandler(type)
    }
  };

  return (
    <div className={styles.RightCol}>
      <BODY2>Новости:</BODY2>
      <MenuBytton
        style={{
          background: type === 'all' ? 'rgba(0, 152, 95, 0.1)' : 'transparent',
          color:type === 'all' ? "#00A84E" : 'rgba(0, 0, 0, 0.87)'
        }}
        className={styles.Button}
        onClick={() => onClickHandler('all')}
      >
        Все
      </MenuBytton>
      <MenuBytton
        style={{
          background: type === 'no-proccesed' ? 'rgba(0, 152, 95, 0.1)' : 'transparent',
          color: type === 'no-proccesed' ? "#00A84E" : 'rgba(0, 0, 0, 0.87)'
        }}
        className={styles.Button}
        onClick={() => onClickHandler('no-proccesed')}
      >
        Необработанные
      </MenuBytton>
      <MenuBytton
        style={{
          background: type === 'proccesed' ? 'rgba(0, 152, 95, 0.1)' : 'transparent',
          color: type === 'proccesed' ? "#00A84E" : 'rgba(0, 0, 0, 0.87)'
        }}
        className={styles.Button}
        onClick={() => onClickHandler('proccesed')}
      >
        Обработанные
      </MenuBytton>
    </div>
  )
};

// Exports
export default ProccesedMenu;
