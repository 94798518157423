// Libraries
import React from 'react';

// Components
import { CircularProgress } from '@material-ui/core';

// Styles
import styles from './styles.sass';


const Loader = ({
  text = 'Загрузка данных...'
}: any) => (
  <div className={styles.Loader}>
    <CircularProgress color='primary' />
    {text && (
      <div className={styles.Loader__Text}>
        {text}
      </div>
    )}
  </div>
)

export default Loader;
